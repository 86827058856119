import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../../Components/Drawer";
import BaseColor from "../../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../../Apis/apiHelper";
import BaseSetting from "../../../Apis/setting";
import { isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import { Delete, Edit, Send } from "@mui/icons-material";
import CModal from "../../../Components/CModal";
import theme, { FontFamily } from "../../../Config/theme";
import CInput from "../../../Components/CInput";
import { Editor } from "@tinymce/tinymce-react";
import CButton from "../../../Components/CButton";
import _ from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { downloadOptions, shortcutsItems } from "../../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CSelect from "../../../Components/CSelect";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";

const SendNewsLetterList = () => {
  const searchRef = useRef();

  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [BulkTemplateData, setBulkTemplateData] = useState([]);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [showData, setShowData] = useState("");

  const [editorData, setEditorData] = useState("");

  const [searchVal, setSearchVal] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [rowChange, setRowChange] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateModal, setDateModal] = useState(false);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  document.addEventListener("focusin", (e) => {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };
  //create update state
  const [subject, setSubject] = useState("");
  const [editData, setEditData] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  //validation
  const [subjectErrorText, setSubjectErrorText] = useState("");
  const [contentErrorText, setContentErrorText] = useState("");

  const editorRefApp = useRef(null);

  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Email",
    "Title",
    "Description",
    "Status",
    "Created at",
  ]);

  const [selectFileType, setSelectFileType] = useState("");

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setPageLoad(true);
    getAllBulkTemplateApiCall(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, []);

  //all Bulk template data
  const getAllBulkTemplateApiCall = (
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date
  ) => {
    const data = {
      page: page,
      type: "admin",
      api_type: "news_letter",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }

    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }

    getApiData(BaseSetting.endpoint.GetSendEmailList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setBulkTemplateData(response?.emailData);
            setPagination(response?.pagination);
          }
          //   toast(result?.message, { type: "success" });
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const allErrorFalse = () => {
    setSubjectErrorText("");
    setContentErrorText("");
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
    setEditData("");
    setEditorData("");
    allErrorFalse();
    setSubject("");
  };

  const validation = () => {
    const content = editorRefApp.current.getContent();
    if (subject.trim() === "" || subject === "undefined") {
      allErrorFalse();
      setSubjectErrorText("Subject is required");
    } else if (content.trim() === "" || content === "undefined") {
      allErrorFalse();
      setContentErrorText("Content is required");
    } else {
      allErrorFalse();
      updateMailTemplate();
    }
  };

  const updateMailTemplate = () => {
    const content = editorRefApp.current.getContent();
    setBtnLoad(true);
    setDisableBtn(true);

    const endpoint = BaseSetting.endpoint.UpdateEmail;

    let data = {
      title: subject,
      description: content,
    };

    data.id = editData?.id;

    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setDisableBtn(false);
          handleClose();
          getAllBulkTemplateApiCall(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };
  //send mail
  const sendMailAgain = (val) => {
    const endpoint = BaseSetting.endpoint.SentEmail;
    getApiData(endpoint, "post", { id: val.id })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };
  //delete plan
  const deleteTemplate = () => {
    setYesBtnLoading(true);
    const endpoint = BaseSetting.endpoint.DeleteSentEmail;
    getApiData(endpoint, "post", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDeleteId("");
          setDelIds([]);
          getAllBulkTemplateApiCall(pageNo, true);
          const newIndex = BulkTemplateData.filter(
            (data) => data.id !== delIds
          );
          setBulkTemplateData(newIndex);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const openTheModal = (data) => {
    setIsEdit(true);
    setEditData(data);
    setEditorData(data?.description);
    setOpenModal(true);
    setSubject(data?.title);
  };

  const BulkTemplateColumn = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        BulkTemplateData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"space-around"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  sx={{ padding: "0px 5px" }}
                  onClick={() => {
                    openTheModal(params?.row);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {params?.row?.status === "send" && (
              <Grid item>
                <Tooltip title="Send" placement="top" arrow>
                  <Button
                    sx={{ padding: "0px 5px" }}
                    onClick={() => {
                      sendMailAgain(params?.row);
                    }}
                  >
                    <Send style={{ fontSize: 20, color: "green" }} />
                  </Button>
                </Tooltip>
              </Grid>
            )}

            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    sx={{ padding: "0px 5px" }}
                    onClick={() => {
                      setConfirm(true);
                      setDeleteId(params?.row?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "email",
      headerName: "EMAIL ADDRESS",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      minWidth: 200,
      headerName: "TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "CONTENT",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.description.slice(0, 30)}
            <span
              style={{
                color: "#000",
                fontWeight: 700,
                cursor: "pointer",
                marginLeft: "3px",
              }}
              onClick={() => {
                setShowData(params?.row?.description);
                setViewMoreModal(true);
              }}
            >
              {params?.row?.description.length <= 30 ? "" : "...Read More"}
            </span>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  //Download data
  function getDownloadData(type) {
    let data = {
      // attributes: visibleColumns,
      download: true,
      type: "admin",
      api_type: "news_letter",
      download_id: delIds,
    };
    getApiData(BaseSetting.endpoint.GetSendEmailList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.emailData;
          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              Email: val.email,
              Title: val.title,
              Content: val.description,
              Status: val.status,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
          toast(result?.message, { type: "success" });
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Bulk-template.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Bulk-template.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Bulk-template.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          // overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Send News Letter"
        children={
          <>
            <Grid justifyContent={"flex-end"} container>
              <div style={{ display: "flex" }}>
                {" "}
                <Button
                  variant="contained"
                  onClick={() => setDateModal(true)}
                  style={{ marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "", []);
                      getAllBulkTemplateApiCall(page);
                      setSearchVal("");
                      setDelIds([]);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                  style={{ marginLeft: "15px" }}
                />
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllBulkTemplateApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal
                      );
                    } else {
                      setHeaderParams(
                        isPage,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllBulkTemplateApiCall(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={BulkTemplateData}
                  columns={BulkTemplateColumn}
                  disableColumnMenu
                  disableDensitySelector
                  hideFooter={isEmpty(delIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  checkboxSelection
                  rowSelectionModel={delIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setDelIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadIdString
                      );
                    }

                    getAllBulkTemplateApiCall(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString
                    );
                  }}
                  showCellVerticalBorder={true}
                  disableRowSelectionOnClick
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAllBulkTemplateApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDateVal,
                            endDateVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(BulkTemplateData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getAllBulkTemplateApiCall(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={openModal}
        onClose={() => {
          handleClose();
          allErrorFalse();
        }}
        title={"Update News Letter"}
        style={{ width: "50%" }}
        children={
          <>
            <Grid container item xs={8} padding={"30px"} gap={2}>
              <Grid container gap={2} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={12}>
                  <CInput
                    placeholder="Subject"
                    value={subject}
                    initialValue={editData?.title}
                    onChange={(val) => {
                      setSubject(val);
                      setSubjectErrorText("");
                    }}
                    errorMsg={subjectErrorText}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    style={{
                      marginBottom: 15,
                      fontFamily: FontFamily.SemiBold,
                      fontSize: 15,
                    }}
                  >
                    Content
                  </Typography>
                  <Editor
                    apiKey={BaseSetting?.tinymceEditorApiKey}
                    onInit={(evt, editor) => (editorRefApp.current = editor)}
                    value={editorData}
                    plugins={"image code"}
                    init={{
                      height: 500,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount image code",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent image | " +
                        "removeformat | image | code",
                      images_upload_url: `${
                        BaseSetting?.serverUrl +
                        BaseSetting?.endpoint?.imageUpload
                      }`,
                    }}
                    onEditorChange={(content, editor) => {
                      setEditorData(content);
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                      marginTop: "10px",
                    }}
                  >
                    {contentErrorText}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-evenly"}
                  gap={2}
                  wrap="nowrap"
                >
                  <Grid item xs={6}>
                    <CButton
                      onClick={() =>
                        isEdit ? validation("update") : validation("create")
                      }
                      fullWidth
                      variant="contained"
                      disabled={disableBtn}
                      loading={btnLoad}
                    >
                      {!isEdit ? "Create" : "Update"}
                    </CButton>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        handleClose();
                        allErrorFalse();
                      }}
                      disabled={disableBtn}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setConfirm(false);
          setDeleteId("");
        }}
        title={"Delete News letter"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to delete this template ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => deleteTemplate()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={viewMoreModal}
        onClose={() => {
          setViewMoreModal(false);
        }}
        title={"View more"}
        header={false}
        children={
          <div style={{ padding: "20px" }}>
            <div dangerouslySetInnerHTML={{ __html: showData }} />
          </div>
        }
      />
      <CModal
        visible={dateModal}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModal(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  getAllBulkTemplateApiCall(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModal(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default SendNewsLetterList;
