import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/Color";
import CInput from "../../../../Components/CInput";
import CButton from "../../../../Components/CButton";
import CImageUpload from "../../../../Components/CImageUpload";
import { getApiData, getAxiosApi } from "../../../../Apis/apiHelper";
import BaseSetting from "../../../../Apis/setting";
import { toast } from "react-toastify";
import CSelect from "../../../../Components/CSelect/index";
import _ from "lodash";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MiniDrawer from "../../../../Components/Drawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TagsInput from "../../../../Components/TagsInput";

const BuyPost = () => {
  // const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();

  // const isUpdate = location?.state?.update;
  // const expire = location?.state?.expire;

  //media queries
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [post_id, setPost_id] = useState("");

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [country, setCountry] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [imageName, setImageName] = useState("");
  const [tag, setTag] = useState([]);

  const [postBtnLoader, setPostBtnLoader] = useState(false);

  const [pageLoad, setPageLoad] = useState(true);
  const [allSubCategoryList, setAllSubCategoryList] = useState([]);

  //form validation
  const [categoryErrorText, setCategoryErrorText] = useState("");
  const [subCategoryErrorText, setSubCategoryErrorText] = useState("");
  const [conditionErrorText, setConditionErrorText] = useState("");
  const [productNameErrorText, setProductNameErrorText] = useState("");
  const [productDescErrorText, setProductDescErrorText] = useState("");
  const [countryErrorText, setCountryErrorText] = useState("");
  const [metaTitleErrorText, setMetaTitleErrorText] = useState("");
  const [metaDescriptionErrorText, setMetaDescriptionErrorText] = useState("");
  const [metaKeywordsErrorText, setMetaKeywordsErrorText] = useState("");
  const [imageNameErrorText, setImageNameErrorText] = useState("");

  const [thumbnail, setThumbnail] = useState({});

  //image upload
  // const [mainImage, setMainImage] = useState(Files.images.profile);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [removeVideo, setRemoveVideo] = useState([0]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    GetCategoryListApi();
    GetConditionListApi();
    GetCountryListApi();
    GetSubCategoryListApi();
  }, []);

  useEffect(() => {
    if (removeVideo !== 0) {
      setVideo([]);
    }
  }, [removeVideo]);

  useEffect(() => {
    const isSuccess =
      !_.isEmpty(countryList) &&
      _.isArray(countryList) &&
      !_.isEmpty(categoryList) &&
      _.isArray(categoryList) &&
      !_.isEmpty(conditionList) &&
      _.isArray(conditionList) &&
      !_.isEmpty(allSubCategoryList) &&
      _.isArray(allSubCategoryList);
    if (isSuccess) {
      getOnePostData(true, postId);
    }
  }, [countryList, categoryList, conditionList, allSubCategoryList]);

  useEffect(() => {
    setHeaderParams(
      postId,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      downloadId,
      expire,
      createdPost
    );
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const expire = searchParams.has("expire")
    ? searchParams.get("expire")
    : false;

  const postStatus = searchParams.has("status")
    ? searchParams.get("status")
    : "";
  const postId = searchParams.has("id") ? searchParams.get("id") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const createdPost = searchParams.has("created_by")
    ? searchParams.get("created_by")
    : "";

  const setHeaderParams = (
    id,
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    post_status = "",
    download_id = [],
    expire,
    createdPost
  ) => {
    const params = {
      page: page,
    };
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(id)) {
      params.id = id;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(post_status)) {
      params.status = post_status;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(createdPost)) {
      params.created_by = createdPost;
    }
    params.expire = expire;
    setSearchParams(createSearchParams(params));
  };

  const GetCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.categoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetSubCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setAllSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetSubCategoryListByCategoryApi = (parentId) => {
    const endpoint = BaseSetting.endpoint.subCategoryByParentIdList;
    const data = {
      parent_id: parentId,
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetConditionListApi = () => {
    const endpoint = BaseSetting.endpoint.conditionList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setConditionList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const allErrorFalse = () => {
    setCategoryErrorText("");
    setSubCategoryErrorText("");
    setConditionErrorText("");
    setCountryErrorText("");
    setProductNameErrorText("");
    setProductDescErrorText("");
  };
  const createPostValidation = (type) => {
    if (_.isEmpty(category)) {
      allErrorFalse();
      setCategoryErrorText("Please select a category.");
    } else if (_.isEmpty(subCategory)) {
      allErrorFalse();
      setSubCategoryErrorText("Please select a sub category.");
    } else if (_.isEmpty(condition)) {
      allErrorFalse();
      setConditionErrorText("Please select a condition.");
    } else if (_.isEmpty(country)) {
      allErrorFalse();
      setCountryErrorText("Please select a country.");
    } else if (productName.trim() === "") {
      allErrorFalse();
      setProductNameErrorText("Please enter a product");
    } else if (productDesc.trim() === "") {
      allErrorFalse();
      setProductDescErrorText("Please enter a product description");
    } else {
      allErrorFalse();
      UpdatePostApi();
    }
  };

  const UpdatePostApi = () => {
    const endpoint = BaseSetting.endpoint.updatePost;
    const data = {
      post_id: postId,
      post_type: "Buy",
      api_type: "admin",
      category_id: category?.id,
      sub_category_id: subCategory?.id,
      condition_id: condition?.id,
      name: productName,
      description: productDesc,
      country: country?.name,
      country_code: country.country_code,
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_keyword: tag,
      image_name: imageName,
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          toast(result.message, { type: "success" });
          if (expire === "true") {
            navigate(
              `/expired-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
            );
          } else {
            navigate(
              `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
            );
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const appendLiveImageInArray = (liveImages, localImages, id) => {
    let imageArr = localImages;
    const liveImageLength = liveImages?.length;

    if (liveImageLength > 0) {
      imageArr?.splice(0, 1, liveImages[liveImageLength - 1]);
      const findLocalImage = imageArr?.filter(
        (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
      );
      const localImagesLength = findLocalImage.length;
      if (localImagesLength > 0) {
        SingleImageUploadApi(findLocalImage, id);
      }
      setImages([...liveImages]);
    }
  };

  const SingleImageUploadApi = (image, id) => {
    const form = new FormData();
    form.append("post_image", image[0]);
    const type = !_.isUndefined(image[0]?.type)
      ? image[0]?.type?.split("/")
      : [];
    const fileType = !_.isEmpty(type) && _.isArray(type) ? type[0] : "";

    form.append("file_type", fileType);
    form.append("type", "Buy");
    if (id && !_.isUndefined(id) && !_.isNull(id)) {
      form.append("post_id", id?.toString());
    }

    const endpoint = BaseSetting.endpoint.singlePostImageUpload;
    getAxiosApi(endpoint, "post", form)
      .then((result) => {
        if (result?.status) {
          const post_id = result?.data?.id;
          setPost_id(post_id);
          getOnePostData(false, post_id, image, fileType);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const mainImage =
    !_.isEmpty(images) && _.isArray(images)
      ? images.filter((v) => v?.main_image == 1)
      : null;

  // // get one post data
  const getOnePostData = (bool = false, id, images, fileType) => {
    const endpoint = BaseSetting.endpoint.getOnePostData;
    const data = { id: postId, type: "Buy" };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (
            !_.isEmpty(images) &&
            !_.isUndefined(images) &&
            _.isArray(images) &&
            fileType != "video"
          ) {
            appendLiveImageInArray(data?.postImages?.images, images, id);
          } else {
            setData(data);
          }
          if (bool === true) {
            setData(data);
          }
        } else {
          toast(result.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const ChangeMainImageApi = (image_id, post_id) => {
    const endpoint = BaseSetting.endpoint.changeMainImage;
    const data = { image_id: image_id, post_id: post_id };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          getOnePostData(false, post_id);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const setData = (data) => {
    const postData =
      !_.isEmpty(data.postData) &&
      !_.isNull(data.postData) &&
      !_.isUndefined(data.postData)
        ? data.postData
        : {};

    const postImages =
      !_.isEmpty(data.postImages) &&
      !_.isNull(data.postImages) &&
      !_.isUndefined(data.postImages)
        ? data.postImages
        : {};

    const imagesData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.images) &&
      !_.isEmpty(postImages?.images) &&
      _.isArray(postImages?.images)
        ? postImages?.images?.map((item) => {
            if (item.main_image === 1) {
              item.isMainImage = true;
              item.isLiveMainImage = true;
            }
            return item;
          })
        : [];

    setImages([...imagesData]);
    const videoData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "video")
        : [];
    setVideo({ ...videoData });

    const thumbnailData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "thumbnail")
        : [];
    setThumbnail({ ...thumbnailData });

    const categoryValue =
      !_.isNull(postData.category_id) && !_.isUndefined(postData.category_id)
        ? categoryList.find((v) => v.id === postData.category_id)
        : "";

    GetSubCategoryListByCategoryApi(postData?.category_id);

    const subCategoryValue =
      !_.isNull(postData.sub_category_id) &&
      !_.isUndefined(postData.sub_category_id)
        ? allSubCategoryList.find((v) => v.id === postData.sub_category_id)
        : "";

    const conditionValue =
      !_.isNull(postData.condition_id) && !_.isUndefined(postData.condition_id)
        ? conditionList.find((v) => v.id === postData.condition_id)
        : "";

    const productNameValue =
      !_.isEmpty(postData.name) &&
      !_.isNull(postData.name) &&
      !_.isUndefined(postData.name)
        ? postData.name
        : "";

    const productDescValue =
      !_.isEmpty(postData.description) &&
      !_.isNull(postData.description) &&
      !_.isUndefined(postData.description)
        ? postData.description
        : "";

    const countryValue =
      !_.isNull(postData.country_code) && !_.isUndefined(postData.country_code)
        ? countryList.find((v) => v.country_code === postData.country_code)
        : "";

    const meta_title =
      !_.isEmpty(postData?.meta_title) &&
      !_.isUndefined(postData?.meta_title) &&
      !_.isNull(postData?.meta_title)
        ? postData?.meta_title
        : "";
    const meta_description =
      !_.isEmpty(postData?.meta_description) &&
      !_.isUndefined(postData?.meta_description) &&
      !_.isNull(postData?.meta_description)
        ? postData?.meta_description
        : "";

    const meta_keywords =
      !_.isEmpty(postData?.meta_keyword) &&
      !_.isUndefined(postData?.meta_keyword) &&
      !_.isNull(postData?.meta_keyword)
        ? postData?.meta_keyword?.split(",")
        : [];

    const image_name =
      !_.isEmpty(postData?.image_name) &&
      !_.isUndefined(postData?.image_name) &&
      !_.isNull(postData?.image_name)
        ? postData?.image_name
        : "";

    setCategory(categoryValue);
    setSubCategory(subCategoryValue);
    setCondition(conditionValue);
    setProductName(productNameValue);
    setProductDesc(productDescValue);
    setCountry(countryValue);
    setMetaDescription(meta_description);
    setMetaTitle(meta_title);
    setTag(meta_keywords);
    setImageName(image_name);
    setPageLoad(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && metaKeywords.trim() !== "") {
      setTag([...tag, metaKeywords.trim()]);
      setMetaKeywords("");
    }
  };

  const removeTag = (index) => {
    setTag(tag.filter((el, i) => i !== index));
  };

  return (
    <Grid container>
      <MiniDrawer
        header="Buy Post"
        children={
          <div style={{ background: BaseColor.whiteColor }}>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <>
                <Grid container justifyContent={"center"} marginBottom={10}>
                  <Grid container>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (expire === "true") {
                          navigate(
                            `/expired-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
                          );
                        } else {
                          navigate(
                            `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
                          );
                        }
                      }}
                    >
                      <ArrowBackIcon /> Back
                    </Button>
                  </Grid>
                  <Grid item xs={11} lg={10} marginTop={2}>
                    <Grid container>
                      <Grid item xs={12} lg={7}>
                        <div>
                          <Typography
                            mb={2}
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: sm ? 18 : 20,
                              color: BaseColor.primary,
                            }}
                          >
                            Product details
                          </Typography>
                          <Grid
                            container
                            sx={{
                              backgroundColor: BaseColor.offWhite,
                              padding: "15px",
                              borderRadius: "5px",
                              justifyContent: "space-between",
                              marginTop: 3,
                            }}
                            gap={2}
                          >
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CSelect
                                data={categoryList}
                                fullObj
                                placeholder="Category"
                                value={category}
                                onSelect={(val) => {
                                  setCategory(val);
                                  GetSubCategoryListByCategoryApi(val.id);
                                  setSubCategory({});
                                  setCategoryErrorText("");
                                }}
                                errorMsg={categoryErrorText}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CSelect
                                data={subCategoryList}
                                fullObj
                                placeholder="Sub-category"
                                value={subCategory}
                                onSelect={(val) => {
                                  setSubCategory(val);
                                  setSubCategoryErrorText("");
                                }}
                                errorMsg={subCategoryErrorText}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CSelect
                                data={conditionList}
                                fullObj
                                placeholder="Condition"
                                value={condition}
                                onSelect={(val) => {
                                  setCondition(val);
                                  setConditionErrorText("");
                                }}
                                errorMsg={conditionErrorText}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CSelect
                                country
                                fullObj
                                data={countryList}
                                placeholder="Country"
                                value={country}
                                onSelect={(val) => {
                                  setCountry(val);
                                  setCountryErrorText("");
                                }}
                                errorMsg={countryErrorText}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CInput
                                placeholder="Product name"
                                value={productName}
                                onChange={(val) => {
                                  setProductName(val);
                                  setProductNameErrorText("");
                                }}
                                errorMsg={productNameErrorText}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CInput
                                placeholder="Product Description"
                                value={productDesc}
                                onChange={(val) => {
                                  setProductDesc(val);
                                  setProductDescErrorText("");
                                }}
                                multiline
                                rows={6}
                                errorMsg={productDescErrorText}
                              />
                            </Grid>
                          </Grid>
                          <Typography
                            mb={2}
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: sm ? 18 : 20,
                              color: BaseColor.primary,
                              marginTop: "20px",
                            }}
                          >
                            Meta details
                          </Typography>
                          <Grid
                            container
                            sx={{
                              backgroundColor: BaseColor.offWhite,
                              padding: "15px",
                              borderRadius: "5px",
                              justifyContent: "space-between",
                              marginTop: 3,
                            }}
                            gap={2}
                          >
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CInput
                                placeholder="Enter title here"
                                onChange={(val) => {
                                  setMetaTitle(val);
                                  allErrorFalse();
                                }}
                                value={metaTitle}
                                sx={{ width: "100%" }}
                                errorMsg={metaTitleErrorText}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.79} md={5.8}>
                              <CInput
                                placeholder="Enter image name here"
                                onChange={(val) => {
                                  setImageName(val);
                                  allErrorFalse();
                                }}
                                value={imageName}
                                sx={{ width: "100%" }}
                                errorMsg={imageNameErrorText}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TagsInput
                                onKeyDown={handleInputKeyPress}
                                value={metaKeywords}
                                onChange={(val) => {
                                  setMetaKeywords(val);
                                  allErrorFalse();
                                }}
                                tagList={tag}
                                removeTag={(index) => {
                                  removeTag(index);
                                }}
                                errorMsg={metaKeywordsErrorText}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CInput
                                placeholder="Enter description here"
                                onChange={(val) => {
                                  setMetaDescription(val);
                                  allErrorFalse();
                                }}
                                multiline
                                rows={6}
                                maxRows={6}
                                value={metaDescription}
                                sx={{ width: "100%" }}
                                errorMsg={metaDescriptionErrorText}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {!lg && (
                          <Grid container>
                            <Grid item xs={5.75} style={{ marginTop: "20px" }}>
                              <CButton
                                variant="contained"
                                onClick={() => {
                                  createPostValidation("post");
                                }}
                                loading={postBtnLoader}
                              >
                                Save
                              </CButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {/* Images and videos part */}
                      <Grid
                        item
                        xs={12}
                        lg={5}
                        paddingLeft={lg ? 0 : 10}
                        marginTop={lg ? 10 : 0}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontFamily: FontFamily.SemiBold,
                                fontSize: sm ? 18 : 20,
                                color: BaseColor.primary,
                              }}
                            >
                              Images/videos{" "}
                              <span
                                style={{
                                  fontFamily: FontFamily.SemiBold,
                                  marginLeft: 10,
                                  fontSize: 12,
                                  color: BaseColor.textGray,
                                }}
                              >
                                {"(Optional)"}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{
                              backgroundColor: BaseColor.offWhite,
                              borderRadius: "5px",
                              padding: "20px",
                            }}
                            marginTop={3}
                            rowGap={2}
                          >
                            <Grid item xs={12}>
                              <span
                                style={{
                                  fontFamily: FontFamily.SemiBold,
                                  fontSize: "12px",
                                  color: BaseColor.red,
                                }}
                              >
                                NOTE: Maximum 10 images and 1 video can be post
                                on ScrapC
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              {!_.isEmpty(mainImage) && (
                                <div
                                  style={{
                                    marginBottom: 30,
                                  }}
                                >
                                  <CImageUpload
                                    file={mainImage}
                                    hideRemoveBtn
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    color: BaseColor.textColor,
                                    fontFamily: FontFamily.SemiBold,
                                  }}
                                >
                                  More images
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    marginLeft: 8,
                                    color: BaseColor.textGray,
                                    fontFamily: FontFamily.SemiBold,
                                  }}
                                >
                                  {"(.jpg, .jpeg , .png, supported)"}
                                </Typography>
                              </div>
                              <CImageUpload
                                multiple
                                file={images}
                                onChange={(val) => {
                                  setImages([...val]);
                                }}
                                onChangeMainImage={(id) => {
                                  ChangeMainImageApi(id, postId);
                                }}
                                onUploadImage={(val) => {
                                  const findLocalImage = val?.filter(
                                    (v) =>
                                      _.isUndefined(v.id) ||
                                      _.isUndefined(v.url)
                                  );
                                  SingleImageUploadApi(findLocalImage, postId);
                                }}
                                onRemoveImageSuccess={(bool) => {
                                  if (bool) {
                                    getOnePostData(postId);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  style={{
                                    color: BaseColor.textColor,
                                    fontFamily: FontFamily.SemiBold,
                                  }}
                                >
                                  Video
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    marginLeft: 8,
                                    color: BaseColor.textGray,
                                    fontFamily: FontFamily.SemiBold,
                                  }}
                                >
                                  {"(.mov and .mp4 supported)"}
                                </Typography>
                              </div>

                              <CImageUpload
                                video
                                thumbnail={thumbnail}
                                file={video}
                                onChange={(val) => {
                                  setVideo([...val]);
                                }}
                                onUploadImage={(val) => {
                                  const findLocalImage = val?.filter(
                                    (v) =>
                                      _.isUndefined(v.id) ||
                                      _.isUndefined(v.url)
                                  );
                                  SingleImageUploadApi(findLocalImage, postId);
                                }}
                                onRemove={() => {
                                  setRemoveVideo([]);
                                }}
                                onRemoveImageSuccess={(bool) => {
                                  if (bool) {
                                    getOnePostData(bool);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {lg && (
                  <Grid
                    container
                    style={{
                      margin: "10px 0px",
                    }}
                  >
                    <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} />
                    <Grid item xs={11} sm={11} md={11} lg={5.8}>
                      <Grid container>
                        <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                          <CButton
                            variant="contained"
                            btnStyle={{ marginTop: "-50px" }}
                            onClick={() => {
                              createPostValidation("post");
                            }}
                            loading={postBtnLoader}
                          >
                            Save
                          </CButton>
                        </Grid>
                        <Grid item xs={0} sm={0.4} md={0.4} lg={0.4} />
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} />
                  </Grid>
                )}
              </>
            )}
          </div>
        }
      />
    </Grid>
  );
};

export default BuyPost;
