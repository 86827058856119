import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CButton from "../../Components/CButton";
import CModal from "../../Components/CModal/index";
import { FontFamily } from "../../Config/theme";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { createSearchParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

const CMS = () => {
  const navigate = useNavigate();
  const searchRef = useRef();

  //cms data
  const [CMSData, setCMSData] = useState([]);

  //pagination and loader
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  //CRUD
  const [confirm, setConfirm] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState("");
  const [deleteId, setDeleteId] = useState("");

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  const [readMoreModal, setReadMoreModal] = useState(false);
  const [cmsShow, setCmsShow] = useState("");

  const openModal = (data) => {
    setReadMoreModal(true);
    setCmsShow(data);
  };

  useEffect(() => {
    setPageLoad(true);
    getCMSData(page, search, sortVal, sortByVal);
  }, []);

  const getCMSData = (page, search, sort, sortBy) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    getApiData(BaseSetting.endpoint.CMSList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setCMSData(response?.cmsData);
            setPagination(response?.pagination);
          }
          //   toast(result?.message, { type: "success" });
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  //delete plan
  const deleteCMS = () => {
    setYesBtnLoading(true);
    const endpoint = BaseSetting.endpoint.deleteCMS;
    getApiData(endpoint, "post", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDelIds([]);
          getCMSData(pageNo);
          setDeleteId("");
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const CMSColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.3,
      headerAlign: "center",
      minWidth: 70,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        CMSData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    // openUpdateModal(params?.row);
                    // setIsEdit(true);
                    navigate("/create-update-cms", {
                      state: { isEdit: true, editData: params?.row },
                    });
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirm(true);
                      setDeleteId(params?.row?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "app_body",
      headerName: "APP BODY",
      minWidth: 450,
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: params?.row?.app_body.slice(0, 150),
                }}
              />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                  // display:"inline-block"
                }}
                onClick={() => {
                  openModal(params?.row?.app_body);
                }}
              >
                {params?.row?.app_body.length <= 150 ? "" : "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "html_body",
      headerName: "HTML BODY",
      minWidth: 450,
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: params?.row?.html_body.slice(0, 150),
                }}
              />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                  // display:"inline-block"
                }}
                onClick={() => {
                  openModal(params?.row?.html_body);
                }}
              >
                {params?.row?.html_body.length <= 150 ? "" : "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "slug",
      headerName: "SLUG",
      minWidth: 200,
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_description",
      minWidth: 200,
      headerName: "META DESCRIPTION",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_keyword",
      minWidth: 200,
      headerName: "META KEYWORD",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_title",
      headerName: "META TITLE",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="CMS"
        children={
          <div>
            <Grid justifyContent={"space-between"} container>
              <Button
                variant="contained"
                onClick={() => navigate("/create-update-cms")}
              >
                Create CMS
              </Button>
              <div>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);
                      setSearchVal("");
                      getCMSData(page);
                      setDelIds([]);
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{
                      color: "red",
                      borderColor: "red",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      getCMSData(page, search, model[0]?.field, model[0].sort);
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      getCMSData(page, search);
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={CMSData}
                  columns={CMSColumns}
                  disableColumnMenu
                  hideFooter={isEmpty(delIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  disableDensitySelector
                  checkboxSelection
                  disableRowSelectionOnClick
                  rowSelectionModel={delIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setDelIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                    getCMSData(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      downloadIdString
                    );
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getCMSData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(CMSData) && isArray(CMSData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    getCMSData(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </div>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setConfirm(false);
          setDeleteId("");
        }}
        title={"Delete CMS"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to delete this data ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                      setDeleteId("");
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => deleteCMS()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={readMoreModal}
        onClose={() => {
          setReadMoreModal(false);
        }}
        title={"Read more"}
        style={{ width: "50%" }}
        children={
          <div style={{ padding: "20px" }}>
            <div dangerouslySetInnerHTML={{ __html: cmsShow }} />
          </div>
        }
      />
    </Grid>
  );
};

export default CMS;
