import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import _, { isArray, isEmpty } from "lodash";
import CSelect from "../../Components/CSelect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { downloadOptions } from "../../Config/staticData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { FontFamily } from "../../Config/theme";

export default function FeedBack() {
  const searchRef = useRef();

  //feed
  const [feedbackData, setFeedBackdata] = useState([]);

  // pagination and loader
  const [pagination, setPagination] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sortingMode, setSortingMode] = useState(false);

  // sort and search and download
  const [rowChange, setRowChange] = useState(false);
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "First name",
    "Last name",
    "Company name",
    "Email",
    "Message",
  ]);
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];
  const [selIds, setselIds] = useState(downloadIdArr);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    setPageLoad(true);
    getFeedBackData(page, search, sortVal, sortByVal, downloadId);
  }, []);

  // get feedback data api call
  function getFeedBackData(page, search, sort, sortBy) {
    const data = {
      page: page,
      type: "admin",
      download: false,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    getApiData(BaseSetting.endpoint.getFeedBack, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!_.isEmpty(response)) {
            setFeedBackdata(response?.feedbacks);
            setPagination(response?.pagination);
          }
          setTimeout(() => {
            setPageLoad(false);
            setSortingMode(false);
          }, 100);
        } else {
          setPageLoad(false);
          setSortingMode(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
        setSortingMode(false);
      });
  }

  // download data in different formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Feedback-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Feedback-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Feedback-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data api
  function getDownloadData(type) {
    let data = {
      download: true,
      download_id: selIds,
    };
    getApiData(BaseSetting.endpoint.getFeedBack, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.feedbacks;
          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              "First name": val.first_name,
              "Last name": val.last_name,
              "Company name": val.company_name,
              Email: val?.email,
              Message: val?.message,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const categoryColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        feedbackData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "company_name",
      headerName: "COMPANY NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "MESSAGE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="Feedback"
        children={
          <>
            <Grid container justifyContent={"flex-end"}>
              <div style={{ display: "flex" }}>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(selIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      getFeedBackData(page);
                      setselIds([]);
                      setSearchVal("");
                      setSortingMode(true);
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
                <CSelect
                  style={{ marginLeft: "20px" }}
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  disableColumnFilter
                  disableColumnSelector
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  // ref={gridRef}
                  onSortModelChange={(model) => {
                    if (!_.isEmpty(model)) {
                      setSortingMode(true);
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      getFeedBackData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      getFeedBackData(page, search);
                    }
                  }}
                  rows={feedbackData}
                  columns={categoryColumns}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  apiRef={getDataGridApi}
                  sortingMode="server"
                  disableDensitySelector
                  loading={sortingMode}
                  checkboxSelection
                  rowSelectionModel={selIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    const downloadIdString = id.join(",");
                    setselIds(id);
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                  }}
                  hideFooter={isEmpty(selIds)}
                  hideFooterPagination
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  style={{ width: "100%" }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    columnsPanel: {
                      onChange: (e) => {
                        if (e.target.checked == true) {
                          visibleColumns.push(e.target.name);
                        } else {
                          const index = visibleColumns.indexOf(e.target.name);
                          if (index > -1) {
                            visibleColumns.splice(index, 1);
                          }
                        }
                      },
                    },
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setSortingMode(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getFeedBackData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(feedbackData) && isArray(feedbackData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    getFeedBackData(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
    </Grid>
  );
}
