import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import theme, { FontFamily } from "../../Config/theme";
import CSelect from "../../Components/CSelect";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";
import _, { isArray, isEmpty, isNull } from "lodash";
import CModal from "../../Components/CModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";

const logisticsTypeData = [
  {
    id: "from_user",
    name: "From user",
  },
  {
    id: "from_product",
    name: "From product",
  },
];

const Logistics = () => {
  const searchRef = useRef();

  //data state
  const [logisticsData, setLogisticsData] = useState([]);

  //pagination and loader
  const [pageLoad, setPageLoad] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  //download data
  const [selectFileType, setSelectFileType] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleColumns, setVisibleColumns] = useState(
    searchParams.get("logistic_type") === "from_user"
      ? [
          "No",
          "Name",
          "Phone",
          "Email",
          "Company Name",
          "Country",
          "Globally",
          "Product Name",
          "Category",
          "Sub Category",
          "Condition",
          "Quantity",
          "Location",
          "From deastination",
          "To destination",
          "Created At",
        ]
      : [
          "No",
          "Name",
          "Phone",
          "Email",
          "Company Name",
          "Country",
          "Globally",
          "Product Name",
          "Product id",
          "Category",
          "Sub Category",
          "Condition",
          "Quantity",
          "Unit",
          "Location",
          "From deastination",
          "To destination",
          "Created At",
        ]
  );
  const [searchVal, setSearchVal] = useState("");
  const [rowChange, setRowChange] = useState(false);

  //date filter
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectLogisticsType, setSelectLogisticsType] = useState(
    searchParams.get("logistic_type") === "from_user"
      ? {
          id: "from_user",
          name: "From user",
        }
      : {
          id: "from_product",
          name: "From product",
        }
  );

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const logisticType = searchParams.has("logistic_type")
    ? searchParams.get("logistic_type")
    : selectLogisticsType?.id;

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";

  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];
  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [selIds, setselIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId,
      logisticType
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  //set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = [],
    logistic_type
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(logistic_type)) {
      params.logistic_type = logistic_type;
    }
    setSearchParams(createSearchParams(params));
  };
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    getLogisticsData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      logisticType
    );
  }, []);

  //get logistics data
  const getLogisticsData = (
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date,
    logistic_type
  ) => {
    const data = {
      page: page,
      type: logisticType,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }

    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }
    if (!_.isEmpty(logistic_type)) {
      data.type = logistic_type;
    }
    const endpoint = `${BaseSetting.endpoint.logisticsList}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.logisticsData;
          setLogisticsData(response?.getList);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
      orientation: "landscape",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(25);
    const pageWidth = pdf.internal.pageSize.getWidth() - 1;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 5;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Logistics-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Logistics-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Logistics-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      type: selectLogisticsType?.id,
      page: 1,
      download_id: selIds,
    };
    getApiData(BaseSetting.endpoint.logisticsList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.logisticsData?.getList;
          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              Name: val.first_name + " " + val.last_name,
              Phone: val.phone,
              Email: val.email,
              "Company name": val.business_name,
              Country: val.country,
              Globally: val.globally,
              "Product name": val.product_name,
              Category: val.category,
              "Sub-category": val.sub_category,
              Condition: val.condition,
              Quantity: val.quantity,
              Location: val.location,
              "From destination": val?.from,
              "To destination": val?.to,
              "Created at": formattedLoginAt,
            };

            let productObj = {
              No: index + 1,
              Name: val.first_name + " " + val.last_name,
              Phone: val.phone,
              Email: val.email,
              "Company name": val.business_name,
              Country: val.country,
              Globally: val.globally,
              "Product name": val.product_name,
              "Prouct id": val?.product_id,
              Category: val.category,
              "Sub-category": val.sub_category,
              Condition: val.condition,
              Quantity: val.quantity,
              Unit: val?.unit,
              Location: val.location,
              "From destination": val?.from,
              "To destination": val?.to,
              "Created at": formattedLoginAt,
            };

            if (logisticType === "from_user") {
              dataSort.push(newObj);
            } else {
              dataSort.push(productObj);
            }
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const tableColumns = [
    {
      field: "id",
      headerName: "NO.",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index =
          params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1;
        const mainIndex =
          pageNo === 1
            ? index
            : (pageNo - 1) * pagination?.defaultPageSize + index;
        logisticsData.map((data) => {
          data.serialNumber = mainIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: "PHONE",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "business_name",
      headerName: "COMPANY NAME",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "COUNTRY",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "globally",
      headerName: "GLOBALLY",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "product_name",
      headerName: "PRODUCT NAME",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "product_id",
      headerName: "PRODUCT ID",
      headerAlign: "center",
      align: "center",
      width: 200,
      hidden: true,
    },
    {
      field: "category",
      headerName: "CATEGORY",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "sub_category",
      headerName: "SUB CATEGORY",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "condition",
      headerName: "CONDITION",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "unit",
      headerName: "UNIT",
      headerAlign: "center",
      align: "center",
      width: 180,
      hidden: true,
    },
    {
      field: "location",
      headerName: "LOCATION",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "from",
      headerName: "FROM DESTINATION",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "to",
      headerName: "TO DESTINATION",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const filterColumns = tableColumns.filter((data) => data.hidden !== true);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  // date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Logistics"
        children={
          <>
            <Grid container justifyContent={"space-between"} mb={"30px"}>
              <Grid item xs={3}>
                <CSelect
                  fullObj
                  data={logisticsTypeData}
                  placeholder="Select logistic type"
                  value={logisticsTypeData?.find(
                    (data) => data?.id === logisticType
                  )}
                  onSelect={(val) => {
                    setSelectLogisticsType(val);
                    setHeaderParams(1, "", "", "", "", "", [], val?.id);
                    getLogisticsData(page, "", "", "", "", "", val?.id);
                    setSearchVal("");
                    setselIds([]);
                    setStartDate("");
                    setEndDate("");
                  }}
                />
              </Grid>

              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDateModel(true);
                  }}
                  style={{ marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(selIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(
                        page,
                        "",
                        "",
                        "",
                        "",
                        "",
                        [],
                        logisticType
                      );
                      setSearchVal("");
                      getLogisticsData(page);
                      setselIds([]);
                      setSearchVal("");
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                <CSelect
                  style={{ marginLeft: "20px" }}
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId,
                      logisticType
                    );
                    getLogisticsData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      logisticType
                    );
                  } else {
                    setHeaderParams(
                      isPage,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      [],
                      logisticType
                    );
                    getLogisticsData(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      logisticType
                    );
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={logisticsData}
                columns={
                  logisticType === "from_product" ? tableColumns : filterColumns
                }
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter={isEmpty(selIds)}
                hideFooterPagination
                loading={dataLoader}
                checkboxSelection
                rowSelectionModel={selIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  const downloadIdString = id.join(",");
                  setselIds(id);
                  if (rowChange === true) {
                    setHeaderParams(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString,
                      logisticType
                    );
                  }
                }}
                disableDensitySelector
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableSelectionOnClick
                sortingMode="server"
                apiRef={getDataGridApi}
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId,
                        logisticType
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getLogisticsData(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          logisticType
                        );
                      }, 800);
                    },
                  },
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
              />
            )}
            {!isEmpty(logisticsData) && isArray(logisticsData) && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId,
                      logisticType
                    );
                    getLogisticsData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      logisticType
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId,
                    logisticType
                  );
                  getLogisticsData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    logisticType
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default Logistics;
