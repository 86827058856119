import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import _, { isArray, isEmpty } from "lodash";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import BaseColor from "../../Config/Color";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import CSelect from "../../Components/CSelect";
import { downloadOptions } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";

export default function Conditions() {
  const searchRef = useRef();

  const [visible, setVisible] = useState({ state: false, data: [] });
  const [confirm, setConfirm] = useState(false);
  const [conditionList, setConditionList] = useState([]);
  const [conditionName, setConditionName] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);

  const [pageNo, setPageNo] = useState(1);

  const [pagination, setPagination] = useState({});
  const [sortingMode, setSortingMode] = useState(false);

  const [selectFileType, setSelectFileType] = useState("");

  const [visibleColumns, setVisibleColumns] = useState(["No", "Name"]);

  const [searchVal, setSearchVal] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [ConditionIds, setConditionIds] = useState(downloadIdArr);
  const [rowChange, setRowChange] = useState(false);
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };
  // Error Text
  const [conditionNameErrorText, setConditionNameErrorText] = useState("");
  const conditionColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        conditionList.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Grid item>
                <Tooltip title="Edit" placement="top" arrow>
                  <Button
                    onClick={() => {
                      openEditModal(params?.row);
                      // setEdit(true);
                    }}
                  >
                    <Edit style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
              {_.isEmpty(ConditionIds) ? (
                <Grid item>
                  <Tooltip title="Delete" placement="top" arrow>
                    <Button
                      onClick={() => {
                        setConfirm(true);
                        setConditionIds(params?.row?.id);
                      }}
                    >
                      <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                    </Button>
                  </Tooltip>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 250,
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    setPageLoad(true);
    allConditionApiCall(page, search, sortVal, sortByVal, downloadId);
  }, []);

  const openEditModal = (data) => {
    setVisible({ state: true, data: data });
    setConditionName(data?.name);
  };

  // useEffect(() => {
  //   setConditionName(visible?.data?.name);
  // }, [visible]);

  //get consition Data
  async function allConditionApiCall(page, search, sort, sortBy) {
    try {
      const data = {
        page: page,
        type: "admin",
      };

      if (!_.isEmpty(search)) {
        data.searchVal = search?.trim();
      }

      if (!_.isEmpty(sort)) {
        data.sort = sort;
      }

      if (!_.isEmpty(sortBy)) {
        data.sort_by = sortBy;
      }

      const response = await getApiData(
        BaseSetting.endpoint.findcondition,
        "post",
        data
      );
      if (response?.status) {
        if (!_.isEmpty(response?.data)) {
          setConditionList(response?.data?.allCondition);
          setPagination(response?.data?.pagination);
          // toast(response?.message, { type: "success" });
        }
      } else {
        toast(response?.message, { type: "error" });
      }
      setTimeout(() => {
        setPageLoad(false);
        setSortingMode(false);
      }, 100);
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setPageLoad(false);
      setSortingMode(false);
    }
  }

  const conditionValidation = (type, data) => {
    if (conditionName.trim() === "") {
      setConditionNameErrorText("Please enter a condition name");
    } else {
      setConditionNameErrorText("");
      createConditionApiCall(type === "update", data);
    }
  };

  // create condition
  function createConditionApiCall(isUpdate, id) {
    setBtnLoad(true);
    setBtnDisable(true);
    const endpoint = isUpdate
      ? BaseSetting.endpoint.updatecondition
      : BaseSetting.endpoint.createcondition;
    let data = {
      name: conditionName,
    };
    if (isUpdate) {
      data.id = id;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setVisible({ state: false, data: [] });
          setBtnLoad(false);
          allConditionApiCall(pageNo);
          setBtnDisable(false);
          setConditionName("");
        } else {
          toast(result?.message, { type: "error" });
          setBtnDisable(false);
          setBtnLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setBtnDisable(false);
      });
  }

  // Delete condition
  async function deleteConditionApiCall() {
    setYesBtnLoading(true);
    try {
      const endpoint = `${BaseSetting.endpoint.deletecondition}`;
      getApiData(endpoint, "POST", { id: ConditionIds })
        .then((result) => {
          if (result?.status) {
            toast(result?.message, { type: "success" });
            setYesBtnLoading(false);
            setConfirm(false);
            allConditionApiCall(pageNo);
            setConditionIds([]);
            setHeaderParams(page, search, sortVal, sortByVal, []);
          } else {
            toast(result?.message, { type: "error" });
            setYesBtnLoading(false);
          }
        })
        .catch((err) => {
          toast(err?.message, { type: "error" });
          setYesBtnLoading(false);
        });
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setYesBtnLoading(false);
    }
  }

  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Condition-data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Condition-data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Condition-data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      // attributes: visibleColumns,
      download: true,
      type: "admin",
      page: 1,
      download_id: ConditionIds,
    };
    getApiData(BaseSetting.endpoint.findcondition, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;

          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              Name: val.name,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Conditions"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Button
                variant="contained"
                onClick={() => setVisible({ ...visible, state: true })}
              >
                Create condition
              </Button>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {!_.isEmpty(sortVal) ||
                !_.isEmpty(search) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(ConditionIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);

                      allConditionApiCall(page);
                      setConditionIds([]);
                      setSearchVal("");
                      setSortingMode(true);
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
                {!_.isEmpty(ConditionIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  onSortModelChange={(model) => {
                    setSortingMode(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      allConditionApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      allConditionApiCall(page, search);
                    }
                  }}
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  disableColumnFilter
                  disableColumnSelector
                  rows={conditionList}
                  columns={conditionColumns}
                  disableColumnMenu
                  loading={sortingMode}
                  disableDensitySelector
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  hideFooter={isEmpty(ConditionIds)}
                  hideFooterPagination
                  checkboxSelection
                  disableRowSelectionOnClick
                  rowSelectionModel={ConditionIds}
                  onRowSelectionModelChange={(id) => {
                    const downloadIdString = id.join(",");
                    setConditionIds(id);
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setSortingMode(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          allConditionApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(conditionList) && isArray(conditionList) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageNo(value);
                    setPageLoad(true);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    allConditionApiCall(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
            <CModal
              visible={visible.state}
              title={
                _.isEmpty(visible.data)
                  ? "Create Condition"
                  : "Update Condition"
              }
              onClose={() => {
                setVisible({ state: false, data: [] });
                setConditionName("");
                setConditionNameErrorText("");
              }}
              children={
                <>
                  <Grid container padding={"30px"}>
                    <Grid item xs={12}>
                      <CInput
                        value={conditionName}
                        placeholder="Enter condition name here"
                        onChange={(val) => {
                          setConditionName(val);
                          setConditionNameErrorText("");
                        }}
                        errorMsg={conditionNameErrorText}
                      />
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-evenly"}
                      gap={2}
                      wrap="nowrap"
                      mt={"15px"}
                    >
                      <Grid item xs={6}>
                        <CButton
                          onClick={() =>
                            _.isEmpty(visible.data)
                              ? conditionValidation("create")
                              : conditionValidation("update", visible?.data?.id)
                          }
                          fullWidth
                          variant="contained"
                          disabled={btnDisable}
                          loading={btnLoad}
                        >
                          {_.isEmpty(visible.data) ? "Create" : "Update"}
                        </CButton>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setVisible({ state: false, data: [] });
                            setConditionNameErrorText("");
                            setConditionName("");
                          }}
                          disabled={btnDisable}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            />

            <CModal
              visible={confirm}
              title={"Delete condition"}
              onClose={() => {
                setConfirm(false);
                setConditionIds([]);
              }}
              children={
                <>
                  <Grid container padding={"30px 0px"}>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          marginBottom: 15,
                          fontFamily: FontFamily.Medium,
                          fontSize: 20,
                          textAlign: "center",
                        }}
                      >
                        Are you sure, you want to delete this condition ?
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"center"}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setConfirm(false);
                            setConditionIds([]);
                          }}
                        >
                          No
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <CButton
                          onClick={() => deleteConditionApiCall()}
                          fullWidth
                          variant="contained"
                          loading={yesBtnLoading}
                        >
                          Yes
                        </CButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </>
        }
      />
    </Grid>
  );
}
