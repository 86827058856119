const BaseColor = {
  primary: "#674188",
  disablePrimary: "#C3ACD0",
  offWhite: "#F7EFE5",
  whiteColor: "#FFFBF5",
  red: "#FF1010",
  errorRed: "#FF0000",
  redBorder: "#FF0404",
  textGray: "#9B9B9B",
  textColor: "#2D2D2D",
  transparent: "#ffffff00",
  white10: "#ffffff10",
  white20: "#ffffff20",
  white30: "#ffffff30",
  white40: "#ffffff40",
  white50: "#ffffff50",
  white60: "#ffffff60",
  white70: "#ffffff70",
  white80: "#ffffff80",
  white90: "#ffffff90",
  white: "#ffffff",
  black10: "#00000010",
  black20: "#00000020",
  black30: "#00000030",
  black40: "#00000040",
  black50: "#00000050",
  black60: "#00000060",
  black70: "#00000070",
  black80: "#00000080",
  black90: "#00000090",
  blackColor: "#000000",
};

export default BaseColor;
