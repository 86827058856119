import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./styles";
import { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/Color";
import CInput from "../../../../Components/CInput";
import CCheckBox from "../../../../Components/CCheckBox";
import CButton from "../../../../Components/CButton";
import CImageUpload from "../../../../Components/CImageUpload";
import { toast } from "react-toastify";
import _, { isEmpty } from "lodash";
import BaseSetting from "../../../../Apis/setting";
import { getApiData, getAxiosApi } from "../../../../Apis/apiHelper";
import CSelect from "../../../../Components/CSelect";
import {
  pricingTermsList,
  supplyList,
  unitList,
} from "../../../../Config/staticData";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import MiniDrawer from "../../../../Components/Drawer";
import CAutoComplete from "../../../../Components/CAutoComplete/CAutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TagsInput from "../../../../Components/TagsInput";

const SellPost = () => {
  const { userData } = useSelector((state) => state.auth);
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();

  const isUpdate = location?.state?.update;
  // const postId = location?.state?.postId;
  // const expire = location?.state?.expire;

  useEffect(() => {
    setHeaderParams(
      postId,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      downloadId,
      expire,
      createdPost
    );
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const expire = searchParams.has("expire")
    ? searchParams.get("expire")
    : false;

  const postStatus = searchParams.has("status")
    ? searchParams.get("status")
    : "";
  const postId = searchParams.has("id") ? searchParams.get("id") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const createdPost = searchParams.has("created_by")
    ? searchParams.get("created_by")
    : "";

  const setHeaderParams = (
    id,
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    post_status = "",
    download_id = [],
    expire,
    createdPost
  ) => {
    const params = {
      page: page,
    };
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(id)) {
      params.id = id;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(post_status)) {
      params.status = post_status;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(createdPost)) {
      params.created_by = createdPost;
    }
    params.expire = expire;
    setSearchParams(createSearchParams(params));
  };

  //media queries
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [pageLoad, setPageLoad] = useState(true);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [supply, setSupply] = useState("");
  const [perUnitPrice, setPerUnitPrice] = useState("");
  const [pricingTerms, setPricingTerms] = useState("");
  const [request, setRequest] = useState(false);
  const [zipPostalCode, setZipPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetName, setStreetName] = useState("");
  const [targetCountry, setTargetCountry] = useState("");
  const [removeVideo, setRemoveVideo] = useState([0]);
  const [check, setCheck] = useState(false);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [imageName, setImageName] = useState("");
  const [tag, setTag] = useState([]);

  // Loader
  const [postBtnLoader, setPostBtnLoader] = useState(false);
  const [draftBtnLoader, setDraftBtnLoader] = useState(false);

  //form validation
  const [categoryErrorText, setCategoryErrorText] = useState("");
  const [subCategoryErrorText, setSubCategoryErrorText] = useState("");
  const [conditionErrorText, setConditionErrorText] = useState("");
  const [productNameErrorText, setProductNameErrorText] = useState("");
  const [productDescErrorText, setProductDescErrorText] = useState("");
  const [productQuantityErrorText, setProductQuantityErrorText] = useState("");
  const [unitErrorText, setUnitErrorText] = useState("");
  const [supplyErrorText, setSupplyErrorText] = useState("");
  const [perUnitPriceErrorText, setPerUnitPriceErrorText] = useState("");
  const [pricingTermsErrorText, setPricingTermsErrorText] = useState("");
  const [zipPostalCodeErrorText, setZipPostalCodeErrorText] = useState("");
  const [countryErrorText, setCountryErrorText] = useState("");
  const [stateErrorText, setStateErrorText] = useState("");
  const [cityErrorText, setCityErrorText] = useState("");
  const [streetNameErrorText, setStreetNameErrorText] = useState("");
  const [targetCountryErrorText, setTargetCountryErrorText] = useState("");
  const [imagesErrorText, setImagesErrorText] = useState("");
  const [videoErrorText, setVideoErrorText] = useState("");
  const [metaTitleErrorText, setMetaTitleErrorText] = useState("");
  const [metaDescriptionErrorText, setMetaDescriptionErrorText] = useState("");
  const [metaKeywordsErrorText, setMetaKeywordsErrorText] = useState("");
  const [imageNameErrorText, setImageNameErrorText] = useState("");

  //image upload
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [thumbnail, setThumbnail] = useState({});

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [allSubCategoryList, setAllSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [allTargetedCountry, setAllTargetedCountry] = useState(false);

  // google pincode api
  const [pinCodeData, setPinCodeData] = useState();

  // get currency list
  const [currencyList, setCurrencyList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");

  const [post_id, setPost_id] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    GetCategoryListApi();
    GetConditionListApi();
    GetCountryListApi();
    GetCurrencyListApi();
    GetSubCategoryListApi();
  }, []);
  useEffect(() => {
    if (removeVideo !== 0) {
      setVideo([]);
    }
  }, [removeVideo]);

  useEffect(() => {
    const isSuccess =
      !_.isEmpty(countryList) &&
      _.isArray(countryList) &&
      !_.isEmpty(categoryList) &&
      _.isArray(categoryList) &&
      !_.isEmpty(conditionList) &&
      _.isArray(conditionList) &&
      !_.isEmpty(allSubCategoryList) &&
      _.isArray(allSubCategoryList);
    if (isSuccess) {
      getOnePostData(true, postId);
    }
  }, [countryList, categoryList, conditionList, allSubCategoryList]);

  const GetCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.categoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetSubCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setAllSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetSubCategoryListByCategoryApi = (parentId) => {
    const endpoint = BaseSetting.endpoint.subCategoryByParentIdList;
    const data = {
      parent_id: parentId,
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetConditionListApi = () => {
    const endpoint = BaseSetting.endpoint.conditionList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setConditionList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // get currency list
  const GetCurrencyListApi = () => {
    const endpoint = BaseSetting.endpoint.currencyList;
    getApiData(endpoint, "post", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCurrencyList(data);
          const currency = data.find(
            (v) => v.country_code === userData?.business_info?.country_code
          );
          setCurrencySymbol(currency?.currency_symbol);
          setCurrencyCode(currency?.code);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const appendLiveImageInArray = (liveImages, localImages, id) => {
    let imageArr = localImages;
    const liveImageLength = liveImages?.length;

    if (liveImageLength > 0) {
      imageArr?.splice(0, 1, liveImages[liveImageLength - 1]);
      const findLocalImage = imageArr?.filter(
        (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
      );
      const localImagesLength = findLocalImage.length;
      if (localImagesLength > 0) {
        SingleImageUploadApi(findLocalImage, id);
      }
      setImages([...liveImages]);
    }
  };

  // // get one post data
  const getOnePostData = (bool = false, id, images, fileType) => {
    const endpoint = BaseSetting.endpoint.getOnePostData;
    const data = { id: postId, type: "Sell" };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (
            !_.isEmpty(images) &&
            !_.isUndefined(images) &&
            _.isArray(images) &&
            fileType != "video"
          ) {
            appendLiveImageInArray(data?.postImages?.images, images, id);
          } else {
            // setImages([...data?.postImages?.images]);
            // setVideo(data?.postImages?.video);
            setData(data);
          }
          // if (bool === true) {
          // }
        } else {
          toast(result.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const setData = (data) => {
    const postData =
      !_.isEmpty(data.postData) &&
      !_.isNull(data.postData) &&
      !_.isUndefined(data.postData)
        ? data.postData
        : {};

    const postImages =
      !_.isEmpty(data.postImages) &&
      !_.isNull(data.postImages) &&
      !_.isUndefined(data.postImages)
        ? data.postImages
        : {};

    const imagesData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.images) &&
      !_.isEmpty(postImages?.images) &&
      _.isArray(postImages?.images)
        ? postImages?.images?.map((item) => {
            if (item.main_image === 1) {
              item.isMainImage = true;
              item.isLiveMainImage = true;
            }
            return item;
          })
        : [];

    setImages([...imagesData]);
    const videoData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "video")
        : [];
    setVideo({ ...videoData });

    const thumbnailData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "thumbnail")
        : [];
    setThumbnail({ ...thumbnailData });

    const categoryValue =
      !_.isNull(postData.category_id) && !_.isUndefined(postData.category_id)
        ? categoryList.find((v) => v.id === postData.category_id)
        : "";

    GetSubCategoryListByCategoryApi(postData?.category_id);

    const subCategoryValue =
      !_.isNull(postData.sub_category_id) &&
      !_.isUndefined(postData.sub_category_id)
        ? allSubCategoryList.find((v) => v.id === postData.sub_category_id)
        : "";

    const conditionValue =
      !_.isNull(postData.condition_id) && !_.isUndefined(postData.condition_id)
        ? conditionList.find((v) => v.id === postData.condition_id)
        : "";

    const productNameValue =
      !_.isEmpty(postData.name) &&
      !_.isNull(postData.name) &&
      !_.isUndefined(postData.name)
        ? postData.name
        : "";

    const productDescValue =
      !_.isEmpty(postData.description) &&
      !_.isNull(postData.description) &&
      !_.isUndefined(postData.description)
        ? postData.description
        : "";

    const productQuantityValue =
      !_.isNull(postData.quantity) && !_.isUndefined(postData.quantity)
        ? postData?.quantity
        : "";

    const unitValue =
      !_.isEmpty(postData.unit) &&
      !_.isNull(postData.unit) &&
      !_.isUndefined(postData.unit)
        ? unitList.find((v) => v.name === postData.unit)?.name
        : "";

    const supplyValue =
      !_.isEmpty(postData.supply) &&
      !_.isNull(postData.supply) &&
      !_.isUndefined(postData.supply)
        ? supplyList.find((v) => v.value === postData.supply)
        : "";

    const zipValue =
      !_.isEmpty(postData.zipcode) &&
      !_.isNull(postData.zipcode) &&
      !_.isUndefined(postData.zipcode)
        ? postData?.zipcode
        : "";

    const countryValue =
      !_.isNull(postData.country_code) && !_.isUndefined(postData.country_code)
        ? countryList.find((v) => v.country_code === postData.country_code)
        : "";

    const stateValue =
      !_.isEmpty(postData.state) &&
      !_.isNull(postData.state) &&
      !_.isUndefined(postData.state)
        ? postData?.state
        : "";
    const cityValue =
      !_.isEmpty(postData.city) &&
      !_.isNull(postData.city) &&
      !_.isUndefined(postData.city)
        ? postData?.city
        : "";
    const streetValue =
      !_.isEmpty(postData.street_name) &&
      !_.isNull(postData.street_name) &&
      !_.isUndefined(postData.street_name)
        ? postData?.street_name
        : "";

    const currencyValue =
      !_.isNull(postData.currency_code) &&
      !_.isUndefined(postData.currency_code)
        ? currencyList.find((v) => v.code === postData.currency_code)
        : "";

    const priceValue =
      postData.unit_price > 0 &&
      !_.isNull(postData.unit_price) &&
      !_.isUndefined(postData.unit_price)
        ? postData?.unit_price
        : "";
    const sameAsCompanyAddress =
      !_.isNull(postData.same_company_address) &&
      !_.isUndefined(postData.same_company_address)
        ? postData?.same_company_address == 1
        : false;

    const termsValue =
      !_.isEmpty(postData.pricing_term) &&
      !_.isNull(postData.pricing_term) &&
      !_.isUndefined(postData.pricing_term)
        ? pricingTermsList.find((v) => v.name === postData.pricing_term)?.name
        : {};

    const currencySymbol =
      !_.isEmpty(postData.currency_symbol) &&
      !_.isNull(postData.currency_symbol) &&
      !_.isUndefined(postData.currency_symbol)
        ? postData?.currency_symbol
        : "";

    const targetedCountryAllValue = postData?.target_all == "1" ? true : false;

    const selectedTargetedCountry =
      !_.isEmpty(postData?.targeted_country_code) &&
      _.isArray(postData?.targeted_country_code) &&
      !targetedCountryAllValue
        ? countryList.filter(
            (v) =>
              postData?.targeted_country_code?.find(
                (id) => v.country_code === id
              ) && v
          )
        : [];

    const isPricePerRequest =
      !_.isNull(postData.price_per_request) &&
      !_.isUndefined(postData.price_per_request)
        ? postData?.price_per_request == 1
        : false;

    const meta_title =
      !_.isEmpty(postData?.meta_title) &&
      !_.isUndefined(postData?.meta_title) &&
      !_.isNull(postData?.meta_title)
        ? postData?.meta_title
        : "";
    const meta_description =
      !_.isEmpty(postData?.meta_description) &&
      !_.isUndefined(postData?.meta_description) &&
      !_.isNull(postData?.meta_description)
        ? postData?.meta_description
        : "";

    const meta_keywords =
      !_.isEmpty(postData?.meta_keyword) &&
      !_.isUndefined(postData?.meta_keyword) &&
      !_.isNull(postData?.meta_keyword)
        ? postData?.meta_keyword?.split(",")
        : [];

    const image_name =
      !_.isEmpty(postData?.image_name) &&
      !_.isUndefined(postData?.image_name) &&
      !_.isNull(postData?.image_name)
        ? postData?.image_name
        : "";

    setCategory(categoryValue);
    setSubCategory(subCategoryValue);
    setCondition(conditionValue);
    setProductName(productNameValue);
    setProductDesc(productDescValue);
    setProductQuantity(productQuantityValue);
    setUnit(unitValue);
    setSupply(supplyValue);
    setZipPostalCode(zipValue);
    setCountry(countryValue);
    setState(stateValue);
    setCity(cityValue);
    setStreetName(streetValue);
    setPerUnitPrice(String(priceValue));
    setPricingTerms(termsValue);
    setTargetCountry(selectedTargetedCountry);
    setRequest(isPricePerRequest);
    setCurrencyCode(currencyValue?.code);
    setAllTargetedCountry(targetedCountryAllValue);
    setCurrencySymbol(currencySymbol);
    setCheck(sameAsCompanyAddress);
    setMetaDescription(meta_description);
    setMetaTitle(meta_title);
    setTag(meta_keywords);
    setImageName(image_name);
    setPageLoad(false);
  };

  const allErrorFalse = () => {
    setCategoryErrorText("");
    setSubCategoryErrorText("");
    setProductNameErrorText("");
    setProductDescErrorText("");
    setConditionErrorText("");
    setProductQuantityErrorText("");
    setUnitErrorText("");
    setSupplyErrorText("");
    setPerUnitPriceErrorText("");
    setPricingTermsErrorText("");
    setZipPostalCodeErrorText("");
    setCountryErrorText("");
    setStateErrorText("");
    setCityErrorText("");
    setStreetNameErrorText("");
    setTargetCountryErrorText("");
    setImagesErrorText("");
    setVideoErrorText("");
  };

  // Google pincode api
  const GetDataFromPincodeApi = async (code) => {
    try {
      const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key=${BaseSetting.GOOGLE_PIN_CODE_API_KEY}`;
      const result = await axios.get(endpoint);
      let pinCode;
      result?.data?.results?.map((data) => {
        pinCode = data?.address_components;
      });
      if (!isEmpty(pinCode)) {
        setPinCodeData(pinCode);
        pinCode?.map((data) => {
          data?.types?.map((item) => {
            if (item === "country") {
              const conValue = countryList.find(
                (v) => v.name == data.long_name
              );
              setCountry(conValue);
            }
            if (item === "administrative_area_level_1") {
              setState(data?.long_name);
            }
            if (item === "locality") {
              setCity(data?.long_name);
            }
          });
        });
      } else {
        toast("Invalid pincode", { type: "error" });
        setCountry({});
        setState("");
        setCity("");
      }
    } catch (err) {
      toast(err.message, { type: "error" });
    }
  };

  const pincodeValidate = (val) => {
    if (/^[0-9]*$/.test(String(val))) {
      if (val.length >= 4) {
        GetDataFromPincodeApi(val);
      } else {
        setCountry({});
        setCity("");
        setState("");
      }
    } else {
      setZipPostalCodeErrorText("Only numbers are allowed");
    }
  };

  const createPostValidation = () => {
    const descriptionRegex = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    const onlyNumberRegex = /^[0-9]*$/;
    const decimalRegex = /^\d*\.?\d*$/;

    const nameWithSpecialCharRegex = /^[\p{L}\-\s]+$/u;

    if (_.isEmpty(category)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategoryErrorText("Please select a category.");
    } else if (_.isEmpty(subCategory)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSubCategoryErrorText("Please select a sub category.");
    } else if (_.isEmpty(condition)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setConditionErrorText("Please select a condition.");
    } else if (productName.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setProductNameErrorText("Please enter a product");
    } else if (!descriptionRegex.test(String(productName))) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setProductNameErrorText("Please enter valid product");
    } else if (productDesc.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setProductDescErrorText("Please enter a product description");
    } else if (!descriptionRegex.test(String(productDesc))) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setProductDescErrorText("Please enter valid product description");
    } else if (productQuantity <= 0) {
      window.scrollTo(500, 500);
      allErrorFalse();
      setProductQuantityErrorText("Please enter a product quantity");
    } else if (!onlyNumberRegex.test(String(productQuantity))) {
      window.scrollTo(500, 500);
      allErrorFalse();
      setProductQuantityErrorText("Only number value are allowed");
    } else if (_.isEmpty(unit)) {
      window.scrollTo(500, 500);
      allErrorFalse();
      setUnitErrorText("Please select a unit");
    } else if (_.isEmpty(supply)) {
      window.scrollTo(500, 500);
      allErrorFalse();
      setSupplyErrorText("Please select a supply");
    } else if (_.isEmpty(zipPostalCode)) {
      window.scrollTo(1000, 1000);
      allErrorFalse();
      setZipPostalCodeErrorText("Please enter a ZIP/postal Code");
    } else if (_.isEmpty(country)) {
      window.scrollTo(1000, 1000);
      allErrorFalse();
      setCountryErrorText("Please select a country");
    } else if (_.isEmpty(state)) {
      window.scrollTo(1000, 1000);
      allErrorFalse();
      setStateErrorText("Please select a state");
    } else if (_.isEmpty(city)) {
      window.scrollTo(1000, 1000);
      allErrorFalse();
      setCityErrorText("Please select a city");
    }
    // else if (_.isEmpty(streetName)) {
    //   window.scrollTo(1000, 1000);
    //   allErrorFalse();
    //   setStreetNameErrorText("Please select a street name");
    // }
    // else if (!descriptionRegex.test(String(streetName))) {
    //   window.scrollTo(1000, 1000);
    //   allErrorFalse();
    //   setStreetNameErrorText("Please select valid a street name");
    // }
    else if (!request && _.isEmpty(perUnitPrice)) {
      window.scrollTo(750, 750);
      allErrorFalse();
      setPerUnitPriceErrorText("Please select a per unit price");
    } else if (!request && !decimalRegex.test(String(perUnitPrice))) {
      window.scrollTo(750, 750);
      allErrorFalse();
      setPerUnitPriceErrorText("Enter valid price");
    } else if (!request && perUnitPrice <= 0) {
      window.scrollTo(750, 750);
      allErrorFalse();
      setPerUnitPriceErrorText("Enter valid price");
    } else if (!request && _.isEmpty(pricingTerms)) {
      window.scrollTo(750, 750);
      allErrorFalse();
      setPricingTermsErrorText("Please select a Pricing Terms");
    } else if (_.isEmpty(targetCountry) && !allTargetedCountry) {
      window.scrollTo(1000, 1000);
      allErrorFalse();
      setTargetCountryErrorText("Please select a target country");
    } else if (_.isEmpty(images)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setImagesErrorText("Please select a images");
    } else {
      allErrorFalse();
      UpdatePostApi();
    }
  };

  const UpdatePostApi = () => {
    const endpoint = BaseSetting.endpoint.updatePost;
    let data = {
      post_id: postId,
      post_type: "Sell",
      api_type: "admin",
      // type: type,
      category_id: category?.id,
      sub_category_id: subCategory?.id,
      condition_id: condition?.id,
      name: productName,
      description: productDesc,
      quantity: productQuantity,
      unit: unit,
      price_per_request: request ? 1 : 0,
      same_company_address: check ? "1" : "0",
      zipcode: zipPostalCode,
      state: state,
      city: city,
      street_name: streetName,
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_keyword: tag,
      image_name: imageName,
    };
    if (supply) {
      data.supply = supply?.value;
    }
    if (!request) {
      data.unit_price = perUnitPrice;
      data.pricing_term = pricingTerms;
      data.currency_code = currencyCode;
    }
    if (!_.isEmpty(country)) {
      data.country = country?.name;
      data.country_code = country?.country_code;
    }
    if (allTargetedCountry) {
      data.target_all = allTargetedCountry && "true";
    }
    if (!_.isEmpty(targetCountry) && !allTargetedCountry) {
      const targetCountryName = targetCountry
        ?.map((data) => `'${data?.name}'`)
        .join(", ");
      const targetCountryCode = targetCountry
        ?.map((data) => `'${data?.country_code}'`)
        .join(", ");

      data.target_country = targetCountryName;
      data.targeted_country_code = targetCountryCode;
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          setPostBtnLoader(false);
          toast(result?.message, { type: "success" });
          if (expire === "true") {
            navigate(
              `/expired-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
            );
          } else {
            navigate(
              `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
            );
          }
        } else {
          toast(result?.message, { type: "warning" });
          setPostBtnLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPostBtnLoader(false);
      });
  };

  const mainImage =
    !_.isEmpty(images) && _.isArray(images)
      ? images.filter((v) => v?.isMainImage)
      : null;

  const SingleImageUploadApi = (image, id) => {
    const form = new FormData();
    const type = !_.isUndefined(image[0]?.type)
      ? image[0]?.type?.split("/")
      : [];
    const fileType = !_.isEmpty(type) && _.isArray(type) ? type[0] : "";

    form.append("file_type", fileType);
    form.append("post_image", image[0]);
    form.append("type", "Sell");
    if (id && !_.isUndefined(id) && !_.isNull(id)) {
      form.append("post_id", id);
    }

    const endpoint = BaseSetting.endpoint.singlePostImageUpload;
    getAxiosApi(endpoint, "post", form)
      .then((result) => {
        if (result?.status) {
          const post_id = result?.data?.id;
          setPost_id(post_id);
          getOnePostData(false, post_id, image, fileType);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const ChangeMainImageApi = (image_id, post_id) => {
    const endpoint = BaseSetting.endpoint.changeMainImage;
    const data = { image_id: image_id, post_id: post_id };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          getOnePostData(false, post_id, true);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && metaKeywords.trim() !== "") {
      setTag([...tag, metaKeywords.trim()]);
      setMetaKeywords("");
    }
  };

  const removeTag = (index) => {
    setTag(tag.filter((el, i) => i !== index));
  };

  return (
    <Grid container>
      <MiniDrawer
        header="Sell Post"
        children={
          <div style={{ background: BaseColor.whiteColor }}>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <>
                <Grid container justifyContent={"center"} marginBottom={10}>
                  <Grid container>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (expire === "true") {
                          navigate(
                            `/expired-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
                          );
                        } else {
                          navigate(
                            `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
                          );
                        }
                      }}
                    >
                      <ArrowBackIcon /> Back
                    </Button>
                  </Grid>
                  <Grid container xs={11} lg={10} marginTop={2}>
                    <Grid item xs={12} lg={6}>
                      <Grid container mb={2}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Product details
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          backgroundColor: BaseColor.offWhite,
                          padding: "15px",
                          borderRadius: "5px",
                          justifyContent: "space-between",
                        }}
                        gap={2}
                      >
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={categoryList}
                            fullObj
                            placeholder="Category"
                            value={category}
                            onSelect={(val) => {
                              setCategory(val);
                              GetSubCategoryListByCategoryApi(val.id);
                              setSubCategory({});
                              setCategoryErrorText("");
                            }}
                            errorMsg={categoryErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={subCategoryList}
                            fullObj
                            placeholder="Sub-category"
                            value={subCategory}
                            onSelect={(val) => {
                              setSubCategory(val);
                              setSubCategoryErrorText("");
                            }}
                            errorMsg={subCategoryErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={conditionList}
                            fullObj
                            placeholder="Condition"
                            value={condition}
                            onSelect={(e) => {
                              setCondition(e);
                              setConditionErrorText("");
                            }}
                            errorMsg={conditionErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="Product name"
                            value={productName}
                            onChange={(val) => {
                              setProductName(val);
                              setProductNameErrorText("");
                            }}
                            errorMsg={productNameErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                          <CInput
                            placeholder="Product Description"
                            value={productDesc}
                            onChange={(val) => {
                              setProductDesc(val);
                              setProductDescErrorText("");
                            }}
                            multiline
                            rows={6}
                            errorMsg={productDescErrorText}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mb={2} marginTop={5}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Product stock details
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          backgroundColor: BaseColor.offWhite,
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                        gap={2}
                      >
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="Product quantity"
                            type="number"
                            value={productQuantity}
                            onChange={(val) => {
                              setProductQuantity(val);
                              setProductQuantityErrorText("");
                            }}
                            errorMsg={productQuantityErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={unitList}
                            placeholder="Unit"
                            value={unit}
                            onSelect={(val) => {
                              setUnit(val);
                              setUnitErrorText("");
                            }}
                            errorMsg={unitErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={supplyList}
                            fullObj
                            placeholder="Supply"
                            value={supply}
                            onSelect={(val) => {
                              setSupply(val);
                              setSupplyErrorText("");
                            }}
                            errorMsg={supplyErrorText}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mb={2} marginTop={5}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Address details
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          backgroundColor: BaseColor.offWhite,
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                        gap={2}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: FontFamily.Medium,
                            marginTop: "10px",
                            marginRight: 2,
                          }}
                        >
                          <CCheckBox
                            checked={check}
                            onChange={(val) => {
                              setCheck(!check);

                              const businessInfoData =
                                !_.isEmpty(userData?.business_info) &&
                                !_.isUndefined(userData?.business_info) &&
                                !_.isNull(userData?.business_info)
                                  ? userData?.business_info
                                  : {};

                              const zipCodeFromBusiness =
                                !_.isEmpty(businessInfoData?.zipcode) &&
                                !_.isUndefined(businessInfoData?.zipcode) &&
                                !_.isNull(businessInfoData?.zipcode) &&
                                val
                                  ? businessInfoData?.zipcode
                                  : "";

                              const countryFromBusiness =
                                !_.isEmpty(businessInfoData?.country) &&
                                !_.isUndefined(businessInfoData?.country) &&
                                !_.isNull(businessInfoData?.country) &&
                                val
                                  ? countryList.find(
                                      (v) => v.name == businessInfoData?.country
                                    )
                                  : {};
                              const stateFromBusiness =
                                !_.isEmpty(businessInfoData?.state) &&
                                !_.isUndefined(businessInfoData?.state) &&
                                !_.isNull(businessInfoData?.state) &&
                                val
                                  ? businessInfoData?.state
                                  : "";
                              const cityFromBusiness =
                                !_.isEmpty(businessInfoData?.city) &&
                                !_.isUndefined(businessInfoData?.city) &&
                                !_.isNull(businessInfoData?.city) &&
                                val
                                  ? businessInfoData?.city
                                  : "";
                              const streetNameFromBusiness =
                                !_.isEmpty(businessInfoData?.street_name) &&
                                !_.isUndefined(businessInfoData?.street_name) &&
                                !_.isNull(businessInfoData?.street_name) &&
                                val
                                  ? businessInfoData?.street_name
                                  : "";

                              setZipPostalCode(zipCodeFromBusiness);
                              setCountry(countryFromBusiness);
                              setState(stateFromBusiness);
                              setCity(cityFromBusiness);
                              setStreetName(streetNameFromBusiness);
                            }}
                            style={{
                              color: BaseColor.primary,
                              padding: 0,
                              marginRight: 5,
                            }}
                          />
                          <Typography style={{ fontFamily: FontFamily.Medium }}>
                            Same as company address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="Zip/postal code"
                            value={zipPostalCode}
                            onChange={(val) => {
                              setZipPostalCode(val);
                              setZipPostalCodeErrorText("");
                            }}
                            onBlur={() => pincodeValidate(zipPostalCode)}
                            errorMsg={zipPostalCodeErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            country
                            data={countryList}
                            fullObj
                            placeholder="Country"
                            value={country}
                            onSelect={(val) => {
                              setCountry(val);
                              setCountryErrorText("");
                            }}
                            errorMsg={countryErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="state"
                            value={state}
                            onChange={(val) => {
                              setState(val);
                              setStateErrorText("");
                            }}
                            errorMsg={stateErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="City"
                            value={city}
                            onChange={(val) => {
                              setCity(val);
                              setCityErrorText("");
                            }}
                            errorMsg={cityErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="Street name"
                            value={streetName}
                            onChange={(val) => {
                              setStreetName(val);
                              setStreetNameErrorText("");
                            }}
                            errorMsg={streetNameErrorText}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mb={2} marginTop={5}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Product pricing details
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          backgroundColor: BaseColor.offWhite,
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                        gap={2}
                      >
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CInput
                            placeholder="Per unit price"
                            value={perUnitPrice}
                            readOnly={request}
                            onChange={(val) => {
                              setPerUnitPrice(val);
                              setPerUnitPriceErrorText("");
                            }}
                            startIcon={
                              <CSelect
                                disabled={request}
                                data={currencyList}
                                fullObj
                                placeholder="Currency"
                                currency
                                value={currencySymbol}
                                onSelect={(val) => {
                                  setCurrencySymbol(val?.currency_symbol);
                                  setCurrencyCode(val?.code);
                                }}
                                style={{ marginLeft: "-15px", width: "80px" }}
                              />
                            }
                            errorMsg={perUnitPriceErrorText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                          <CSelect
                            data={pricingTermsList}
                            placeholder="Pricing terms"
                            disabled={request}
                            value={pricingTerms}
                            onSelect={(val) => {
                              setPricingTerms(val);
                              setPricingTermsErrorText("");
                            }}
                            errorMsg={pricingTermsErrorText}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: FontFamily.Medium,
                            marginTop: "10px",
                            marginRight: 2,
                          }}
                        >
                          <CCheckBox
                            checked={request}
                            onChange={(val) => {
                              setPerUnitPrice("");
                              setPricingTerms("");
                              setRequest(!request);
                            }}
                            style={{
                              color: BaseColor.primary,
                              padding: 0,
                              marginRight: 5,
                            }}
                          />
                          <Typography style={{ fontFamily: FontFamily.Medium }}>
                            Price as per request
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container mb={2} marginTop={5}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Product target details
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          backgroundColor: BaseColor.offWhite,
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                        gap={2}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={5.9}
                            md={5.9}
                            lg={5.9}
                            xl={5.9}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <CCheckBox
                              checked={allTargetedCountry}
                              onChange={(val) => {
                                setAllTargetedCountry(!allTargetedCountry);
                                setTargetCountry([]);
                              }}
                              style={{
                                color: BaseColor.primary,
                                padding: 0,
                                marginRight: 5,
                              }}
                            />
                            <Typography
                              style={{ fontFamily: FontFamily.Medium }}
                            >
                              All
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CAutoComplete
                            disabled={allTargetedCountry}
                            options={countryList}
                            multiple
                            label={"Targeted country"}
                            selectedValue={targetCountry}
                            onSelect={(val) => {
                              setTargetCountry(val);
                            }}
                            errorMsg={targetCountryErrorText}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mb={2} marginTop={5}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Meta details
                        </Typography>
                        <Grid
                          container
                          sx={{
                            backgroundColor: BaseColor.offWhite,
                            padding: "15px",
                            borderRadius: "5px",
                            justifyContent: "space-between",
                            marginTop: 3,
                          }}
                          gap={2}
                        >
                          <Grid item xs={12} sm={5.79} md={5.8}>
                            <CInput
                              placeholder="Enter title here"
                              onChange={(val) => {
                                setMetaTitle(val);
                                allErrorFalse();
                              }}
                              value={metaTitle}
                              sx={{ width: "100%" }}
                              errorMsg={metaTitleErrorText}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5.79} md={5.8}>
                            <CInput
                              placeholder="Enter image name here"
                              onChange={(val) => {
                                setImageName(val);
                                allErrorFalse();
                              }}
                              value={imageName}
                              sx={{ width: "100%" }}
                              errorMsg={imageNameErrorText}
                            />
                          </Grid>
                          <Grid xs={12} marginTop={"-13px"}>
                            <TagsInput
                              onKeyDown={handleInputKeyPress}
                              value={metaKeywords}
                              onChange={(val) => {
                                setMetaKeywords(val);
                                allErrorFalse();
                              }}
                              tagList={tag}
                              removeTag={(index) => {
                                removeTag(index);
                              }}
                              errorMsg={metaKeywordsErrorText}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <CInput
                              placeholder="Enter description here"
                              onChange={(val) => {
                                setMetaDescription(val);
                                allErrorFalse();
                              }}
                              multiline
                              rows={6}
                              maxRows={6}
                              value={metaDescription}
                              sx={{ width: "100%" }}
                              errorMsg={metaDescriptionErrorText}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      paddingLeft={lg ? 0 : 10}
                      marginTop={lg ? 10 : 0}
                    >
                      <Grid container>
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: sm ? 18 : 20,
                            color: BaseColor.primary,
                          }}
                        >
                          Images/videos{" "}
                        </Typography>
                        <Grid
                          item
                          container
                          sx={{
                            backgroundColor: BaseColor.offWhite,
                            borderRadius: "5px",
                            padding: "20px",
                          }}
                          marginTop={2}
                          rowGap={2}
                        >
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontFamily: FontFamily.SemiBold,
                                fontSize: "12px",
                                color: BaseColor.red,
                              }}
                            >
                              NOTE: Maximum 10 images and 1 video can be post on
                              ScrapC
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {!_.isEmpty(mainImage) && (
                              <div
                                style={{
                                  marginBottom: 30,
                                }}
                              >
                                <CImageUpload file={mainImage} hideRemoveBtn />
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 10,
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{
                                  color: BaseColor.textColor,
                                  fontFamily: FontFamily.SemiBold,
                                }}
                              >
                                More images
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  marginLeft: 8,
                                  color: BaseColor.textGray,
                                  fontFamily: FontFamily.SemiBold,
                                }}
                              >
                                {"(.jpg, .jpeg , .png, supported)"}
                              </Typography>
                            </div>
                            <CImageUpload
                              multiple
                              file={images}
                              onChange={(val) => {
                                setImages([...val]);
                              }}
                              onChangeMainImage={(id) => {
                                ChangeMainImageApi(id, postId);
                              }}
                              onUploadImage={(val) => {
                                const findLocalImage = val?.filter(
                                  (v) =>
                                    _.isUndefined(v.id) || _.isUndefined(v.url)
                                );
                                SingleImageUploadApi(findLocalImage, postId);
                              }}
                              onRemoveImageSuccess={(bool) => {
                                if (bool) {
                                  getOnePostData(false, postId);
                                }
                              }}
                              errorMsg={imagesErrorText}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 10,
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{
                                  color: BaseColor.textColor,
                                  fontFamily: FontFamily.SemiBold,
                                }}
                              >
                                Video
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  marginLeft: 8,
                                  color: BaseColor.textGray,
                                  fontFamily: FontFamily.SemiBold,
                                }}
                              >
                                {"(.mov and .mp4 supported)"}
                              </Typography>
                            </div>

                            <CImageUpload
                              video
                              thumbnail={thumbnail}
                              file={video}
                              onChange={(val) => {
                                setVideo([...val]);
                              }}
                              onUploadImage={(val) => {
                                const findLocalImage = val?.filter(
                                  (v) =>
                                    _.isUndefined(v.id) || _.isUndefined(v.url)
                                );
                                SingleImageUploadApi(findLocalImage, postId);
                              }}
                              onRemove={() => {
                                setRemoveVideo([]);
                              }}
                              errorMsg={videoErrorText}
                              onRemoveImageSuccess={(bool) => {
                                if (bool) {
                                  getOnePostData(bool);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container marginBottom={5} marginTop={-5}>
                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
                  <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        flexDirection={"row"}
                        display={"flex"}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            xl={5.8}
                            style={{ marginTop: 10 }}
                          >
                            <CButton
                              variant="contained"
                              onClick={() => {
                                createPostValidation();
                              }}
                              loading={postBtnLoader}
                              style={{ width: "100%" }}
                            >
                              Save
                            </CButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={0} sm={0} md={0} lg={6} xl={6} />
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
                </Grid>
              </>
            )}
          </div>
        }
      />
    </Grid>
  );
};

export default SellPost;
