import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Typography,
} from "@mui/material";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import _, { isArray, isEmpty } from "lodash";
import CButton from "../../Components/CButton";
import CModal from "../../Components/CModal";
import { Editor } from "@tinymce/tinymce-react";

const Countries = () => {
  const searchRef = useRef();
  const navigate = useNavigate();
  const editorRefApp = useRef();

  //page loading
  const [pageLoad, setPageLoad] = useState(false);

  //pagination
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);

  const [searchVal, setSearchVal] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  //description modal
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const [isEditModal, setIsEditModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [createBtnLoading, setCreateBtnLoading] = useState(false);

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal);
  }, []);

  const setHeaderParams = (page, search = "", sort = "", sortBy = "") => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    setSearchParams(createSearchParams(params));
  };

  //country list data
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    setPageLoad(true);
    getCountriesList(page, search, sortVal, sortByVal);
  }, []);

  //country list api
  async function getCountriesList(page, search, sort, sortBy) {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    const endpoint = `${BaseSetting.endpoint.countriesList}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setCountriesList(response?.countryData);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  }

  // change status
  const changeStatus = (countryData) => {
    const endpoint = BaseSetting.endpoint.changeCountryStatus;
    const data = {
      country_id: countryData?.id,
      status: countryData?.status === 1 ? "0" : "1",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getCountriesList(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const tableColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        countriesList.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "country_code",
      headerName: "COUNTRY CODE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            <Switch
              checked={params?.row?.status === 1 ? true : false}
              onChange={() => changeStatus(params?.row)}
            />
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              onClick={() => {
                navigate(`/states?country=${params?.row?.name}`);
              }}
            >
              View states
            </Button>
            {/* <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              onClick={() => {
                setOpenDescriptionModal(true);
              }}
            >
              Add description
            </Button> */}
          </div>
        );
      },
    },
  ];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  document.addEventListener("focusin", (e) => {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });

  return (
    <Grid container>
      <MiniDrawer
        header="Countries"
        children={
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "", []);
                      getCountriesList(page);
                      setSearchVal("");
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
              </Grid>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                      getCountriesList(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search);
                      getCountriesList(page, search);
                    }
                  }}
                  rows={countriesList}
                  columns={tableColumns}
                  disableColumnMenu
                  hideFooter
                  loading={dataLoader}
                  disableDensitySelector
                  apiRef={getDataGridApi}
                  sortingMode="server"
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  disableColumnFilter
                  disableColumnSelector
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getCountriesList(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(countriesList) && isArray(countriesList) && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    getCountriesList(value);
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(value, search, sortVal, sortByVal);
                    getCountriesList(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />

      <CModal
        visible={openDescriptionModal}
        title={isEditModal ? "Update description" : "Create description"}
        onClose={() => {
          setOpenDescriptionModal(false);
        }}
        style={{ width: "50%" }}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              {/* <Grid item xs={12}>
                <span style={{ fontFamily: FontFamily.Regular }}>
                  Description
                </span>
              </Grid> */}
              <Grid item xs={12}>
                <Editor
                  apiKey={BaseSetting?.tinymceEditorApiKey}
                  onInit={(evt, editor) => (editorRefApp.current = editor)}
                  plugins={"image code"}
                  value={description}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount image code",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent image | " +
                      "removeformat | image | code",
                    images_upload_url: `${
                      BaseSetting?.serverUrl +
                      BaseSetting?.endpoint?.imageUpload
                    }`,
                  }}
                  onEditorChange={(content, editor) => {
                    setDescription(content);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CButton
                  variant="contained"
                  // onClick={() => name.length !== 0 && createAndUpdateCategoryApi()}
                  onClick={() => {
                    // createCategoryValidation(isEditModal);
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                  loading={createBtnLoading}
                >
                  {isEditModal ? "Update" : "Create"} description
                </CButton>
              </Grid>
              <Grid item xs={6} paddingLeft={"10px"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenDescriptionModal(false);
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />
    </Grid>
  );
};

export default Countries;
