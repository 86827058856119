import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import MiniDrawer from "../../Components/Drawer";
import DashboardCard from "../../Components/CDashboardCount";
import GroupIcon from "@mui/icons-material/Group";
import BaseColor from "../../Config/Color";
import { useEffect, useState } from "react";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import _ from "lodash";
import { toast } from "react-toastify";
import { FaListAlt, FaUser, FaUserCheck, FaUserSlash } from "react-icons/fa";
import { BiSolidUserX } from "react-icons/bi";
import { MdOutlinePendingActions } from "react-icons/md";
import { ImClipboard } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { FontFamily } from "../../Config/theme";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineDrafts } from "react-icons/md";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import { MdSell } from "react-icons/md";
import { MdOutlineDoNotDisturb } from "react-icons/md";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import ApprovalIcon from "@mui/icons-material/Approval";

const DashBoard = () => {
  const navigate = useNavigate();
  const [pageLoad, setPageLoad] = useState(true);

  const [todayCount, setTodayCount] = useState({});
  const [postCount, setPostCount] = useState({});
  const [userCount, setUserCount] = useState({});

  function getCountData() {
    setPageLoad(true);
    getApiData(BaseSetting.endpoint.dashBoardApi, "get", {})
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!_.isEmpty(response)) {
            setPostCount(response?.post);
            setTodayCount(response?.today);
            setUserCount(response?.user);
          }
          setPageLoad(false);
        } else {
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }
  useEffect(() => {
    getCountData();
  }, []);

  const getTitle = (title) => {
    if (title === "active_user") {
      return "Active users";
    } else if (title === "all_post") {
      return "All post";
    } else if (title === "all_user") {
      return "All users";
    } else if (title === "in_review_post") {
      return "In review post";
    } else if (title === "inactive_user") {
      return "In-active users";
    } else if (title === "not_profile_verify_user") {
      return "Not profile verified users";
    } else if (title === "posted_post") {
      return "Posted posts";
    } else if (title === "profile_verify_user") {
      return "Profile verified users";
    } else if (title === "notification") {
      return "Sent notifications";
    } else if (title === "today_post") {
      return "Posts";
    } else if (title === "today_user") {
      return "Users";
    } else if (title === "draft_post") {
      return "Draft posts";
    } else if (title === "expired_post") {
      return "Expired posts";
    } else if (title === "sold_post") {
      return "Sold posts";
    } else if (title === "removed_by_user") {
      return "Removed by user";
    } else if (title === "not_user_verify") {
      return "Not verified users";
    } else if (title === "rejected_post") {
      return "Rejected posts";
    } else if (title === "only_created_account") {
      return "Only created account";
    } else if (title === "total_unclaimed_users") {
      return "Total unclaimed users";
    } else if (title === "unclaimed_user") {
      return "Unclaimed users";
    } else if (title === "unclaimed_post") {
      return "Unclaimed posts";
    } else if (title === "total_unclaimed_posts") {
      return "Total unclaimed posts";
    }
  };

  const getIcon = (title) => {
    if (title === "active_user") {
      return <FaUser color={BaseColor.primary} size={40} />;
    } else if (title === "all_post") {
      return (
        <FaListAlt style={{ color: BaseColor.primary, fontSize: "40px" }} />
      );
    } else if (title === "all_user") {
      return (
        <GroupIcon style={{ color: BaseColor.primary, fontSize: "50px" }} />
      );
    } else if (title === "in_review_post") {
      return (
        <MdOutlinePendingActions
          style={{ color: BaseColor.primary, fontSize: "50px" }}
        />
      );
    } else if (title === "inactive_user") {
      return <FaUserSlash color={BaseColor.primary} size={40} />;
    } else if (title === "not_profile_verify_user") {
      return <BiSolidUserX color={BaseColor.primary} size={55} />;
    } else if (title === "posted_post") {
      return <ImClipboard color={BaseColor.primary} size={40} />;
    } else if (title === "profile_verify_user") {
      return <FaUserCheck color={BaseColor.primary} size={40} />;
    } else if (title === "notification") {
      return <IoMdNotificationsOutline color={BaseColor.primary} size={50} />;
    } else if (title === "today_user") {
      return (
        <GroupIcon style={{ color: BaseColor.primary, fontSize: "50px" }} />
      );
    } else if (title === "today_post") {
      return (
        <FaListAlt style={{ color: BaseColor.primary, fontSize: "40px" }} />
      );
    } else if (title === "draft_post") {
      return (
        <MdOutlineDrafts
          style={{ color: BaseColor.primary, fontSize: "50px" }}
        />
      );
    } else if (title === "expired_post") {
      return (
        <TimerOffIcon style={{ color: BaseColor.primary, fontSize: "45px" }} />
      );
    } else if (title === "sold_post") {
      return <MdSell style={{ color: BaseColor.primary, fontSize: "45px" }} />;
    } else if (title === "removed_by_user") {
      return (
        <ImCancelCircle
          style={{ color: BaseColor.primary, fontSize: "45px" }}
        />
      );
    } else if (title === "not_user_verify") {
      return (
        <MdOutlineDoNotDisturb
          style={{ color: BaseColor.primary, fontSize: "45px" }}
        />
      );
    } else if (title === "rejected_post") {
      return (
        <MdOutlineCancelPresentation
          style={{ color: BaseColor.primary, fontSize: "45px" }}
        />
      );
    } else if (title === "only_created_account") {
      return (
        <UnpublishedIcon
          style={{ color: BaseColor.primary, fontSize: "45px" }}
        />
      );
    } else if (title === "total_unclaimed_users") {
      return (
        <NotListedLocationIcon
          style={{ color: BaseColor.primary, fontSize: "50px" }}
        />
      );
    } else if (title === "unclaimed_user") {
      return (
        <NotListedLocationIcon
          style={{ color: BaseColor.primary, fontSize: "50px" }}
        />
      );
    } else if (title === "unclaimed_post") {
      return (
        <ApprovalIcon style={{ color: BaseColor.primary, fontSize: "50px" }} />
      );
    } else if (title === "total_unclaimed_posts") {
      return (
        <ApprovalIcon style={{ color: BaseColor.primary, fontSize: "50px" }} />
      );
    }
  };

  const getRoute = (route) => {
    if (route === "active_user") {
      navigate("/users?user_type=active");
    } else if (route === "inactive_user") {
      navigate("/users?user_type=in_active");
    } else if (route === "all_user") {
      navigate("/all-users");
    } else if (route === "profile_verify_user") {
      navigate("/users?user_type=verified");
    } else if (route === "not_user_verify") {
      navigate("/not-verified-users");
    } else if (route === "all_post") {
      navigate("/all-posts");
    } else if (route === "in_review_post") {
      navigate("/all-posts?status=in_review");
    } else if (route === "posted_post") {
      navigate("/all-posts?status=posted");
    } else if (route === "expired_post") {
      navigate("/expired-posts");
    } else if (route === "sold_post") {
      navigate("/all-posts?status=sold");
    } else if (route === "rejected_post") {
      navigate("/all-posts?status=rejected");
    } else if (route === "only_created_account") {
      navigate("/not-added-profile-users");
    }
  };

  return (
    <Grid container>
      <MiniDrawer
        header="Dashboard"
        children={
          <Grid container>
            <Grid container gap={2}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "80vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <>
                  <Grid container gap={2}>
                    {!_.isEmpty(todayCount) && (
                      <Grid container>
                        <Typography
                          sx={{ fontFamily: FontFamily.Bold, fontSize: "25px" }}
                        >
                          Users
                        </Typography>
                      </Grid>
                    )}
                    {Object.keys(userCount).map((data) => {
                      return (
                        <Tooltip
                          arrow
                          title={
                            data === "all_user"
                              ? "Total number of users"
                              : data === "active_user"
                              ? "Users who loged in last 15 days"
                              : data === "inactive_user"
                              ? "Users who did not log in last 15 days"
                              : data === "profile_verify_user"
                              ? "Users whose profile is verified"
                              : data === "not_profile_verify_user"
                              ? "Users whose profile is not verified"
                              : data === "not_user_verify"
                              ? "Users who are not verified by their email or mobile number"
                              : data === "only_created_account"
                              ? "Users who only register"
                              : "Total unclaimed users"
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: 17,
                                backgroundColor: BaseColor.primary,
                                color: BaseColor.white,
                                fontFamily: FontFamily.Medium,
                              },
                            },
                          }}
                        >
                          <Grid
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.02)",
                              },
                            }}
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={2.85}
                            xl={2.85}
                          >
                            <DashboardCard
                              arrow={data !== "not_profile_verify_user"}
                              title={getTitle(data)}
                              count={userCount[data]}
                              icon={getIcon(data)}
                              onClick={() => getRoute(data)}
                            />
                          </Grid>
                        </Tooltip>
                      );
                    })}
                  </Grid>
                  <Grid container gap={2}>
                    {!_.isEmpty(postCount) && (
                      <Grid container style={{ marginTop: "30px" }}>
                        <Typography
                          sx={{ fontFamily: FontFamily.Bold, fontSize: "25px" }}
                        >
                          Posts
                        </Typography>
                      </Grid>
                    )}
                    {Object.keys(postCount).map((data) => {
                      return (
                        <Grid
                          sx={{
                            transition: "transform 0.3s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                          item
                          xs={12}
                          sm={5.8}
                          md={5.8}
                          lg={2.85}
                          xl={2.85}
                        >
                          <DashboardCard
                            arrow={
                              data !== "draft_post" &&
                              data !== "removed_by_user"
                            }
                            title={getTitle(data)}
                            count={postCount[data]}
                            icon={getIcon(data)}
                            onClick={() => getRoute(data)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {!_.isEmpty(todayCount) && (
                    <Grid container style={{ marginTop: "30px" }}>
                      <Typography
                        sx={{ fontFamily: FontFamily.Bold, fontSize: "25px" }}
                      >
                        Today's count
                      </Typography>
                    </Grid>
                  )}
                  <Grid container gap={2} marginTop={"20px"}>
                    {!_.isEmpty(todayCount) &&
                      Object.keys(todayCount).map((data) => {
                        return (
                          <Grid
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(0.97)",
                              },
                            }}
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={2.85}
                            xl={2.85}
                          >
                            <DashboardCard
                              arrow={false}
                              title={getTitle(data)}
                              count={todayCount[data]}
                              icon={getIcon(data)}
                              onClick={() => getRoute(data)}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default DashBoard;
