import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";

const styles = makeStyles((theme) => ({
  scrollBar: {
    display: "flex",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 2,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },

}));

export default styles;
