import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { isArray, isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import CModal from "../../Components/CModal";
import theme, { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import CSelect from "../../Components/CSelect";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _ from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";

const ContactUS = () => {
  //ref
  const searchRef = useRef();

  //contact us data
  const [contactUsData, setContactUsData] = useState([]);

  //loader and pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  // update data
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  //filter date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateModel, setDateModel] = useState(false);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  //set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  // update contact us stats
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [inquiryTitle, setInquiryTitle] = useState("");
  const [message, setMessage] = useState("");
  const [editId, setEditId] = useState("");

  //validation state
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [inquiryErrorText, setInquiryErrorText] = useState("");
  const [messageErrorText, setMessageErrorText] = useState("");

  //download data state
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Name",
    "Email",
    "Phone code",
    "Phone",
    "Message",
    "Inquiry",
  ]);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    getAllContactUsData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, []);

  //all contact us data
  const getAllContactUsData = (
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date
  ) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }

    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }

    getApiData(BaseSetting.endpoint.contactUsList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setContactUsData(response?.contactUsData);
            setPagination(response?.pagination);
          }
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  const validation = () => {
    if (message.trim() === "") {
      allErrorFalse();
      setMessageErrorText("Message is required");
    } else {
      allErrorFalse();
      updateContactUsData();
    }
  };

  const allErrorFalse = () => {
    setFirstNameErrorText("");
    setLastNameErrorText("");
    setEmailErrorText("");
    setMessageErrorText("");
    setInquiryErrorText("");
  };

  //update contact us data
  const updateContactUsData = () => {
    setDisableBtn(true);
    setSaveBtnLoading(true);
    const endpoint = BaseSetting.endpoint.updateContactUs;
    getApiData(endpoint, "POST", {
      message: message,
      id: editId,
      type: "admin",
    })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getAllContactUsData(page);
          setSaveBtnLoading(false);
          setUpdateModalOpen(false);
          setDisableBtn(false);
        } else {
          toast(result?.message, { type: "error" });
          setSaveBtnLoading(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setSaveBtnLoading(false);
        setDisableBtn(false);
      });
  };

  //download data in different formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Contact-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Contact-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Contact-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: delIds,
    };
    getApiData(BaseSetting.endpoint.contactUsList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.contactUsData;
          let dataSort = [];
          setDelIds([]);
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              Name: val.first_name + " " + val.last_name,
              Email: val.email,
              "Phone code": val?.phone_code_id,
              Phone: val?.phone,
              Message: val.message,
              Inquiry: val.inquiry_title,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const contactUsColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        contactUsData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },

    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      minWidth: 150,
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    openModal(params?.row);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirm(true);
                      setDeleteId(params?.row?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "first_name",
      headerName: "NAME",
      flex: 2,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {params?.row?.first_name} {params?.row?.last_name}
            </span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 200,
      flex: 2.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone_code_id",
      headerName: "PHONE CODE",
      minWidth: 200,
      flex: 2.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: "PHONE",
      minWidth: 200,
      flex: 2.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "MESSAGE",
      minWidth: 200,
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "inquiry_title",
      headerName: "INQUIRY TITLE",
      flex: 2,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const handleClose = () => {
    setUpdateModalOpen(false);
  };

  const openModal = (data) => {
    setEditId(data?.id);
    setUpdateModalOpen(true);
    setFirstName(data?.first_name);
    setLastName(data?.last_name);
    setEmail(data?.email);
    setInquiryTitle(data?.inquiry_title);
    setMessage(data?.message);
  };

  //delete contact us data
  const deleteContactUsData = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deleteContactUs}`;
    getApiData(endpoint, "POST", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDeleteId("");
          setDelIds([]);
          getAllContactUsData(pageNo);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  //date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Contact Us"
        children={
          <div>
            <Grid
              alignItems={"center"}
              sx={{ gap: 1 }}
              container
              justifyContent={"end"}
              mb={"30px"}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setDateModel(true);
                }}
              >
                Filter date
              </Button>
              {!_.isEmpty(search) ||
              !_.isEmpty(sortVal) ||
              !_.isEmpty(sortByVal) ||
              !_.isEmpty(downloadId) ||
              !_.isEmpty(delIds) ||
              !_.isEmpty(startDateVal) ||
              !_.isEmpty(endDateVal) ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    setHeaderParams(page, "", "", "", "", "", []);
                    getAllContactUsData(page);
                    setDelIds([]);
                    setSearchVal("");
                    setDataLoader(true);
                    if (dataGridRef.current) {
                      dataGridRef.current.setFilterModel({
                        items: [],
                        quickFilter: "",
                      });
                    }
                  }}
                >
                  Clear filters
                </Button>
              ) : null}
              {!_.isEmpty(delIds) ? (
                <Button
                  variant="outlined"
                  style={{ color: "red", borderColor: "red" }}
                  onClick={() => {
                    setConfirm(true);
                  }}
                >
                  Delete
                </Button>
              ) : (
                ""
              )}
              <CSelect
                placeholder="Choose file format for download"
                value={selectFileType}
                data={downloadOptions}
                onSelect={(val) => {
                  setSelectFileType(val);
                  getDownloadData(val);
                }}
              />
            </Grid>

            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getAllContactUsData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal
                    );
                  } else {
                    setHeaderParams(
                      isPage,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getAllContactUsData(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal
                    );
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={contactUsData}
                columns={contactUsColumns}
                disableColumnMenu
                disableDensitySelector
                hideFooter={isEmpty(delIds)}
                hideFooterPagination
                loading={dataLoader}
                sortingMode="server"
                apiRef={getDataGridApi}
                checkboxSelection
                rowSelectionModel={delIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  setDelIds(id);
                  const downloadIdString = id.join(",");
                  if (rowChange === true) {
                    setHeaderParams(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString
                    );
                  }
                  getAllContactUsData(
                    page,
                    search,
                    sortVal,
                    sortByVal,
                    startDateVal,
                    endDateVal
                  );
                }}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableRowSelectionOnClick
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall": {
                    display: "none",
                    backgroundColor: "red",
                  },
                  "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                    display: "none",
                  },
                  "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "#674188",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                    {
                      width: "1px",
                      height: "4px",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                    {
                      background: "#F7EFE5",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "rgba(103, 65, 136, 0.5)",
                      borderRadius: "10px",
                    },
                }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getAllContactUsData(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      }, 800);
                    },
                  },
                }}
              />
            )}
            {!isEmpty(contactUsData) && isArray(contactUsData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    getAllContactUsData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </div>
        }
      />
      <CModal
        visible={updateModalOpen}
        onClose={() => handleClose()}
        style={{ width: "60%" }}
        title={"Update contact us details"}
        children={
          <>
            <Grid container item xs={8} padding={"30px"} gap={2}>
              <Grid container gap={2} justifyContent={"space-between"}>
                <Grid item xs={12} md={5.8} lg={5.85}>
                  <CInput
                    placeholder="First name"
                    value={firstName}
                    onChange={(val) => {
                      setFirstName(val);
                      setFirstNameErrorText("");
                    }}
                    errorMsg={firstNameErrorText}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} lg={5.85}>
                  <CInput
                    placeholder="Last name"
                    value={lastName}
                    onChange={(val) => {
                      setLastName(val);
                      setLastNameErrorText("");
                    }}
                    errorMsg={lastNameErrorText}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} lg={5.85}>
                  <CInput
                    placeholder="Email"
                    value={email}
                    onChange={(val) => {
                      setEmail(val);
                      setEmailErrorText("");
                    }}
                    errorMsg={emailErrorText}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} lg={5.85}>
                  <CInput
                    placeholder="Inquiry title"
                    value={inquiryTitle}
                    onChange={(val) => {
                      setInquiryTitle(val);
                      setInquiryErrorText("");
                    }}
                    errorMsg={inquiryErrorText}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CInput
                    placeholder="Message"
                    value={message}
                    onChange={(val) => {
                      setMessage(val);
                      setMessageErrorText("");
                    }}
                    errorMsg={messageErrorText}
                    rows={6}
                    maxRows={6}
                    multiline
                  />
                </Grid>
                <Grid item xs={5.8}>
                  <CButton
                    onClick={() => validation()}
                    fullWidth
                    variant="contained"
                    loading={saveBtnLoading}
                    disabled={disableBtn}
                  >
                    Save
                  </CButton>
                </Grid>
                <Grid item xs={5.8}>
                  <CButton
                    onClick={() => handleClose()}
                    fullWidth
                    variant="outlined"
                    disabled={disableBtn}
                  >
                    Cancel
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setConfirm(false);
          setDeleteId("");
        }}
        title={"Delete contact us"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to delete this data ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                      setDeleteId("");
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => deleteContactUsData()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  getAllContactUsData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default ContactUS;
