import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import _, { isArray, isEmpty } from "lodash";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import BaseColor from "../../Config/Color";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import { createSearchParams, useSearchParams } from "react-router-dom";

export default function Groups() {
  const searchRef = useRef();

  const [visible, setVisible] = useState({ state: false, data: [] });
  const [confirm, setConfirm] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);

  const [pageNo, setPageNo] = useState(1);

  const [pagination, setPagination] = useState({});

  const [visibleColumns, setVisibleColumns] = useState(["Id", "name"]);

  const [searchVal, setSearchVal] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [rowChange, setRowChange] = useState(false);

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [groupIds, setGroupIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };
  // Error Text
  const [groupNameErrorText, setGroupNameErrorText] = useState("");
  const groupColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        groupList.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Grid item>
                <Tooltip title="Edit" placement="top" arrow>
                  <Button
                    onClick={() => {
                      openEditModal(params?.row);
                      // setEdit(true);
                    }}
                  >
                    <Edit style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
              {_.isEmpty(groupIds) ? (
                <Grid item>
                  <Tooltip title="Delete" placement="top" arrow>
                    <Button
                      onClick={() => {
                        setConfirm(true);
                        setGroupIds(params?.row?.id);
                      }}
                    >
                      <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                    </Button>
                  </Tooltip>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 250,
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    setPageLoad(true);
    allGroupApiCall(page, search, sortVal, sortByVal, downloadId);
  }, []);

  const openEditModal = (data) => {
    setVisible({ state: true, data: data });
    setGroupName(data?.name);
  };

  async function allGroupApiCall(page, search, sort, sortBy) {
    try {
      const data = {
        page: page,
        type: "admin",
      };

      if (!_.isEmpty(search)) {
        data.searchVal = search?.trim();
      }

      if (!_.isEmpty(sort)) {
        data.sort = sort;
      }

      if (!_.isEmpty(sortBy)) {
        data.sort_by = sortBy;
      }

      const response = await getApiData(
        BaseSetting.endpoint.groupList,
        "post",
        data
      );
      if (response?.status) {
        setGroupList(response?.data?.group);
        setPagination(response?.data?.pagination);
      } else {
        // toast(response?.message, { type: "error" });
        setGroupList([]);
      }
      setTimeout(() => {
        setPageLoad(false);
        setDataLoader(false);
      }, 100);
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setPageLoad(false);
      setDataLoader(false);
    }
  }

  const groupValidation = (type, data) => {
    if (groupName.trim() === "") {
      setGroupNameErrorText("Please enter a group name");
    } else {
      setGroupNameErrorText("");
      createGroupApiCall(type === "update", data);
    }
  };
  function createGroupApiCall(isUpdate, id) {
    setBtnLoad(true);
    setBtnDisable(true);
    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateGroup
      : BaseSetting.endpoint.createGroup;
    let data = {
      name: groupName,
    };
    if (isUpdate) {
      data.id = id;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setVisible({ state: false, data: [] });
          setBtnLoad(false);
          allGroupApiCall(pageNo);
          setBtnDisable(false);
          setGroupName("");
        } else {
          toast(result?.message, { type: "error" });
          setBtnDisable(false);
          setBtnLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setBtnDisable(false);
      });
  }

  async function deleteGroupApiCall() {
    setYesBtnLoading(true);
    try {
      const endpoint = `${BaseSetting.endpoint.deleteGroup}`;
      getApiData(endpoint, "POST", { id: groupIds })
        .then((result) => {
          if (result?.status) {
            toast(result?.message, { type: "success" });
            setYesBtnLoading(false);
            setConfirm(false);
            allGroupApiCall(pageNo);
            setGroupIds([]);
          } else {
            toast(result?.message, { type: "error" });
            setYesBtnLoading(false);
          }
        })
        .catch((err) => {
          toast(err?.message, { type: "error" });
          setYesBtnLoading(false);
        });
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setYesBtnLoading(false);
    }
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Groups"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Button
                variant="contained"
                onClick={() => setVisible({ ...visible, state: true })}
              >
                Create group
              </Button>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(groupIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);
                      setSearchVal("");
                      allGroupApiCall(page);
                      setGroupIds([]);
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(groupIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      allGroupApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      allGroupApiCall(page, search);
                    }
                  }}
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  disableColumnFilter
                  disableColumnSelector
                  rows={groupList}
                  columns={groupColumns}
                  disableColumnMenu
                  disableDensitySelector
                  hideFooter={isEmpty(groupIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rowSelectionModel={groupIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setGroupIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                    allGroupApiCall(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      downloadIdString
                    );
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    columnsPanel: {
                      onChange: (e) => {
                        if (e.target.checked == true) {
                          visibleColumns.push(e.target.name);
                        } else {
                          const index = visibleColumns.indexOf(e.target.name);
                          if (index > -1) {
                            visibleColumns.splice(index, 1);
                          }
                        }
                      },
                    },
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          allGroupApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(groupList) && isArray(groupList) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageNo(value);
                    setPageLoad(true);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    allGroupApiCall(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
            <CModal
              visible={visible.state}
              title={_.isEmpty(visible.data) ? "Create Group" : "Update Group"}
              onClose={() => {
                setVisible({ state: false, data: [] });
                setGroupName("");
                setGroupNameErrorText("");
              }}
              children={
                <>
                  <Grid container padding={"30px"}>
                    <Grid item xs={12}>
                      <CInput
                        value={groupName}
                        placeholder="Enter group name here"
                        onChange={(val) => {
                          setGroupName(val);
                          setGroupNameErrorText("");
                        }}
                        errorMsg={groupNameErrorText}
                      />
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-evenly"}
                      gap={2}
                      wrap="nowrap"
                      mt={"15px"}
                    >
                      <Grid item xs={6}>
                        <CButton
                          onClick={() =>
                            _.isEmpty(visible.data)
                              ? groupValidation("create")
                              : groupValidation("update", visible?.data?.id)
                          }
                          fullWidth
                          variant="contained"
                          disabled={btnDisable}
                          loading={btnLoad}
                        >
                          {_.isEmpty(visible.data) ? "Create" : "Update"}
                        </CButton>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setVisible({ state: false, data: [] });
                            setGroupNameErrorText("");
                            setGroupName("");
                          }}
                          disabled={btnDisable}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            />

            <CModal
              visible={confirm}
              title={"Delete Group"}
              onClose={() => setConfirm(false)}
              children={
                <>
                  <Grid container padding={"30px 0px"}>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          marginBottom: 15,
                          fontFamily: FontFamily.Medium,
                          fontSize: 20,
                          textAlign: "center",
                        }}
                      >
                        Are you sure, you want to delete this group ?
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"center"}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setConfirm(false);
                            setGroupIds([]);
                          }}
                        >
                          No
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <CButton
                          onClick={() => deleteGroupApiCall()}
                          fullWidth
                          variant="contained"
                          loading={yesBtnLoading}
                        >
                          Yes
                        </CButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </>
        }
      />
    </Grid>
  );
}
