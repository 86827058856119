import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import CButton from "../../Components/CButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import styles from "./styles";
import CInput from "../../Components/CInput";
import { BiUser } from "react-icons/bi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import CModal from "../../Components/CModal";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import _ from "lodash";

const MyProfile = () => {
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const classes = styles();

  //form state
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneCodeId, setPhoneCodeId] = useState("");
  const [phone, setPhone] = useState("");

  const [twoFAPhoneCodeId, setTwoFAPhoneCodeId] = useState("");
  const [twoFAPhone, setTwoFAPhone] = useState("");

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const [twoFAModel, setTwoFAModel] = useState(false);
  const [generateAndVerifyOTPModel, setGenerateAndVerifyOTPModel] =
    useState(false);

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [twoFADisable, setTwoFADisable] = useState(false);
  const [twoFALoading, setTwoFALoading] = useState(false);
  const [disableGenOTP, setDisableGenOTP] = useState(false);
  const [genOTPLoading, setGenOTPLoading] = useState(false);
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyDisable, setVerifyDisable] = useState(false);

  //Error messages
  const [twoFAPhoneCodeIdErrortext, setTwoFAPhoneCodeIdErrorText] =
    useState("");
  const [twoFAPhoneErrorText, setTwoFAPhoneErrorText] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneCodeIdError, setPhoneCodeIdError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [rejectedReasonTextError, setRejectedReasonTextError] = useState("");

  const getUserData = () => {
    const endpoint = BaseSetting.endpoint.getUserData;

    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result?.status) {
          const data = result?.data;

          setId(data?.id);
          setFirstName(data?.first_name);
          setLastName(data?.last_name);
          setEmail(data?.email);
          setPhoneCodeId(data?.phone_code_id);
          setPhone(data?.phone);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
      });
  };

  const validation = () => {
    const isNumber = (value) => {
      return typeof value === "number" && !Number.isNaN(value);
    };

    if (twoFAPhoneCodeId.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setTwoFAPhoneCodeIdErrorText("Number Code Is Required...");
    } else if (twoFAPhoneCodeId.length > 3 || twoFAPhoneCodeId.length < 2) {
      setTwoFAPhoneCodeIdErrorText("Number Code must be 2 to 3 digits");
    } else if (!isNumber(Number(twoFAPhoneCodeId))) {
      setTwoFAPhoneCodeIdErrorText("Enter Only Digits");
    } else if (twoFAPhone.trim() === "") {
    } else if (twoFAPhone.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setTwoFAPhoneErrorText("Number  Is Required...");
    } else if (twoFAPhone.length > 10 || twoFAPhone.length < 10) {
      setTwoFAPhoneErrorText("Number  must be 10 digits");
    } else if (!isNumber(Number(twoFAPhone))) {
      setTwoFAPhoneErrorText("Enter Only Digits");
    } else {
      allErrorFalse();
      submitTwoFA();
    }
  };

  const allErrorFalse = () => {
    setTwoFAPhoneCodeIdErrorText("");
    setTwoFAPhoneErrorText("");
  };

  const validateForm = () => {
    let isValid = true;

    if (!firstName) {
      setFirstNameError("First Name is required");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Last Name is required");
      isValid = false;
    } else {
      setLastNameError("");
    }

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is not valid");
      isValid = false;
    } else {
      setEmailError("");
    }
    const isNumber = (value) => {
      return typeof value === "number" && !Number.isNaN(value);
    };

    if (!phoneCodeId) {
      setPhoneCodeIdError("Phone Code ID is required");
      isValid = false;
    } else if (phoneCodeId.length > 3 || phoneCodeId.length < 2) {
      setPhoneCodeIdError("Number Code must be 2 to 3 digits");
      isValid = false;
    } else if (!isNumber(Number(phoneCodeId))) {
      setPhoneCodeIdError("Enter Only Digits");
      isValid = false;
    } else {
      setPhoneCodeIdError("");
    }

    if (!phone) {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (phone.length > 10 || phone.length < 10) {
      isValid = false;
      setPhoneError("Number  must be 10 digits");
    } else if (!isNumber(Number(phone))) {
      isValid = false;
      setPhoneError("Enter Only Digits");
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  const updateSubmit = () => {
    if (validateForm()) {
      setSubmitLoading(true);
      setDisableSubmit(true);
      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_code_id: phoneCodeId,
        phone: phone,
      };

      const endpoint = BaseSetting.endpoint.updateProfile;

      getApiData(endpoint, "POST", data)
        .then((result) => {
          if (result?.status) {
            toast(result.message, { type: "success" });
            setDisableSubmit(false);
            setSubmitLoading(false);
          } else {
            toast(result.message, { type: "error" });
          }
        })
        .catch((error) => {
          toast(error.message, { type: "error" });
        });
    }
  };

  const submitTwoFA = () => {
    setTwoFADisable(true);
    setTwoFALoading(true);

    const data = {
      phone_code_id: twoFAPhoneCodeId,
      phone: twoFAPhone,
    };

    const endPoint = BaseSetting.endpoint.updateProfile;
    getApiData(endPoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result.message, { type: "success" });
          setTwoFADisable(false);
          setTwoFALoading(false);
          setTwoFAModel(false);
          getUserData();
        } else {
          toast(result.message, { type: "error" });
          setTwoFADisable(false);
          setTwoFALoading(false);
        }
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
        setTwoFADisable(false);
        setTwoFALoading(false);
      });
  };

  const enableTwoFactor = () => {
    if (validateForm()) {
      setGenOTPLoading(true);
      setDisableGenOTP(true);
      setRejectedReasonTextError("");
      const data = {
        phone: phone,
        phone_code_id: phoneCodeId,
      };

      const endPoint = BaseSetting.endpoint.enableTwoFactor;
      getApiData(endPoint, "POST", data)
        .then((result) => {
          if (result?.status) {
            setIsVerifyOTP(true);
            setGenOTPLoading(false);
            setToken(result?.data?.token);
            toast(result.message + result?.data?.otp?.otp, { type: "success" });
          } else {
            toast(result.message, { type: "error" });
          }
        })
        .catch((error) => {
          toast(error.message, { type: "error" });
        });
    } else {
      setRejectedReasonTextError("Enter Number or Number Code Propely...");
    }
  };

  const verifyOTP = () => {
    setVerifyLoading(true);
    setVerifyDisable(true);

    const data = {
      phone: phone,
      otp: otp,
      token: token,
    };

    const endPoint = BaseSetting.endpoint.verifyOTP;
    getApiData(endPoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          setVerifyLoading(false);
          setVerifyDisable(false);
          setDisableGenOTP(false);
          setOtp("");
          setToken("");
          setGenerateAndVerifyOTPModel(false);
          toast(result.message, { type: "success" });
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Grid container>
      <MiniDrawer
        header={"Profile"}
        children={
          <>
            <Grid container>
              <Button
                variant="contained"
                onClick={() => navigate("/verify-users")}
              >
                <ArrowBackIcon /> Back
              </Button>
            </Grid>

            <div style={{ backgroundColor: BaseColor.whiteColor }}>
              <Grid
                container
                marginBottom={"30px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={11} lg={12}>
                  <Grid
                    item
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    mt={5}
                    gap={4}
                    wrap={md ? "wrap" : "nowrap"}
                  >
                    <Grid item xs={12} md={12}>
                      <Grid item container>
                        <Grid item container xs={12}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            mt={2}
                          >
                            <Grid item xs={10}>
                              <span className={classes.header}>
                                Update profile
                              </span>
                            </Grid>
                            <Grid item xs={2}>
                              <CButton
                                onClick={() =>
                                  _.isEmpty(phoneCodeId) && _.isEmpty(phone)
                                    ? setTwoFAModel(true)
                                    : setGenerateAndVerifyOTPModel(true)
                                }
                                fullWidth
                                variant="contained"
                              >
                                Enable 2FA
                              </CButton>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              backgroundColor: BaseColor.offWhite,
                              padding: "15px",
                              marginTop: "20px",
                            }}
                            gap={2}
                          >
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="First name"
                                readOnly={false}
                                value={firstName}
                                onChange={(val) => setFirstName(val)}
                                errorMsg={firstNameError}
                                startIcon={
                                  <BiUser className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Last name"
                                readOnly={false}
                                value={lastName}
                                errorMsg={lastNameError}
                                onChange={(val) => setLastName(val)}
                                startIcon={
                                  <BiUser className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Email"
                                readOnly={false}
                                errorMsg={emailError}
                                value={email}
                                onChange={(val) => setEmail(val)}
                                startIcon={<CIcon src={Files.svgIcons.email} />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Phone Code"
                                readOnly={false}
                                errorMsg={phoneCodeIdError}
                                value={phoneCodeId}
                                onChange={(val) => setPhoneCodeId(val)}
                                startIcon={
                                  <HiOutlineDevicePhoneMobile className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Phone"
                                readOnly={false}
                                errorMsg={phoneError}
                                value={phone}
                                onChange={(val) => setPhone(val)}
                                startIcon={
                                  <HiOutlineDevicePhoneMobile className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}></Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CButton
                                onClick={updateSubmit}
                                fullWidth
                                variant="contained"
                                disabled={disableSubmit}
                                loading={submitLoading}
                              >
                                Submit
                              </CButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        }
      />
      <CModal
        visible={twoFAModel}
        style={{ width: "30%" }}
        onClose={() => {
          setTwoFAModel(false);
        }}
        title="Enable Two Factor"
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              {_.isEmpty(phone) && _.isEmpty(phoneCodeId) ? (
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <CInput
                      placeholder="Enter phone code id"
                      onChange={(val) => setTwoFAPhoneCodeId(val)}
                      value={twoFAPhoneCodeId}
                      style={{ width: "100%" }}
                      errorMsg={twoFAPhoneCodeIdErrortext}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CInput
                      placeholder="Enter phone"
                      onChange={(val) => setTwoFAPhone(val)}
                      value={twoFAPhone}
                      style={{ width: "100%" }}
                      errorMsg={twoFAPhoneErrorText}
                    />
                  </Grid>
                </Grid>
              ) : (
                () => (setTwoFAModel(false), setGenerateAndVerifyOTPModel(true))
              )}
              <Grid item xs={12}>
                <CButton
                  onClick={validation}
                  fullWidth
                  variant="contained"
                  disabled={twoFADisable}
                  loading={twoFALoading}
                >
                  Save
                </CButton>
              </Grid>
            </Grid>
          </div>
        }
      ></CModal>
      <CModal
        visible={generateAndVerifyOTPModel}
        style={{ width: "30%" }}
        onClose={() => {
          setGenerateAndVerifyOTPModel(false);
        }}
        title="Generate And Verify OTP"
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter phone"
                  readOnly={true}
                  // onChange={(val) => setPhone(val)}
                  value={phone}
                  style={{ width: "100%" }}
                  errorMsg={rejectedReasonTextError}
                  disabled={isVerifyOTP ? true : false}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <CButton
                  onClick={enableTwoFactor}
                  fullWidth
                  variant="contained"
                  disabled={disableGenOTP}
                  loading={genOTPLoading}
                >
                  Generate OTP
                </CButton>
              </Grid>
              {isVerifyOTP ? (
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <CInput
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(val) => setOtp(val)}
                      style={{ width: "100%" }}
                      // errorMsg={rejectedReasonTextError}
                    />
                  </Grid>{" "}
                  <Grid item xs={12}>
                    <CButton
                      onClick={verifyOTP}
                      fullWidth
                      variant="contained"
                      disabled={verifyDisable}
                      loading={verifyLoading}
                    >
                      Verify OTP
                    </CButton>{" "}
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
        }
      ></CModal>
    </Grid>
  );
};

export default MyProfile;
