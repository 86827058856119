import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";
import backgroungImage from '../../Assets/Temp/adminbg.png'

const styles = makeStyles({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${backgroungImage})`,
    filter: "blur(3px)",
    position: 'relative'
  },
  backaground:{
    height: "100vh",
    width: "100vw",
    objectFit: 'cover',
  },
  mainBoxContainer: {
    position: 'absolute',
    zIndex: 10,
    top:'30%',
    background: BaseColor.offWhite,
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
    height: 400,
    width: 400,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${BaseColor.primary}`,
  },
  forgotText:{
    fontSize: 14,
    fontFamily: FontFamily.bold,
    color: BaseColor.primary,
    cursor: 'pointer'
  }
});

export default styles;
