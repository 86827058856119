"use strict";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, useMediaQuery } from "@mui/material";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import CInput from "../../Components/CInput";
import { FiSearch } from "react-icons/fi";
import styles from "./styles";
import Files from "../../Config/Files";
import CButton from "../../Components/CButton";
import ChatListCard from "../../Components/ChatListCard";
import theme, { FontFamily } from "../../Config/theme";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { getApiData, getAxiosApi } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import _, { isEmpty } from "lodash";
import CChatInput from "../../Components/CChatInput";
import { useDispatch, useSelector } from "react-redux";
import CChat from "../../Components/CChat";
import moment from "moment";
import Lottie from "lottie-react";
import { isMobile, isTablet } from "react-device-detect";
import socket from "../../Apis/socket";
import CPreviewModal from "../../Components/CPreviewModal";
import authActions from "../../Redux/Reducers/Auth/actions";

const Chat = () => {
  const { userData } = useSelector((state) => state.auth);
  const { setNotificationData } = authActions;

  const dispatch = useDispatch();

  const userId = userData?.id;
  const sData = localStorage.getItem("selectedConv");
  const selectedConv = !_.isUndefined(sData) ? JSON.parse(sData) : {};

  const classes = styles();
  const { state } = useLocation();

  const chatConvData = state?.convId;
  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [disable, setDisable] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);

  // Chat Listing
  const [pageNo, setPageNo] = useState(1);
  const [chatPageNo, setChatPageNo] = useState(1);
  const [allConversationList, setAllConversationList] = useState([]); // Conversation Listing
  const [msgList, setMsgList] = useState([]); // Chat List Formatted By Dates
  const [selectedChat, setSelectedChat] = useState({});
  const [isTypingData, setIsTypingData] = useState({});
  const [languageList, setLanguageList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  const [pagination, setPagination] = useState({});

  // Loader
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [sendBtnLoader, setSendBtnLoader] = useState(false);
  const [singleChatLoader, setSingleChatLoader] = useState(false);

  // emoji
  const timeoutRef = useRef();
  const [value, setValue] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openEmoji, setOpenEmoji] = useState(null);
  const pop = Boolean(openEmoji);

  //  use md & mediaquery for responsive
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    localStorage.setItem("selectedConv", JSON.stringify({}));
    GetLanguageListApi();
    userConnect("all", pageNo, "", true, true);
    setPageLoad(true);
  }, []);

  useEffect(() => {
    receiveTyping();
    receiveStopTyping();
    receiveMsg();
    receiveConv();
  }, []);

  const previewModalRef = useRef();
  const chatListRef = useRef();
  const messagesEndRef = useRef();

  const ScrollToBottom = (bool) => {
    if (messagesEndRef?.current && !_.isNull(messagesEndRef?.current)) {
      const lastChildElement = messagesEndRef.current?.lastElementChild;
      if (bool) {
        lastChildElement?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onReachBottom = () => {
    if (chatListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      const scroll = scrollTop + clientHeight + 10;

      if (scroll >= scrollHeight) {
        if (pagination?.isMore && !chatLoading) {
          const page = pageNo + 1;
          userConnect(
            selectedTab == 0 ? "all" : "unread",
            page,
            searchValue,
            false,
            true
          );
          setPageNo(page);
          setChatLoading(true);
        }
      }
    }
  };

  //language list api
  const GetLanguageListApi = () => {
    const endpoint = BaseSetting.endpoint.languageList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result?.status) {
          setLanguageList(result.data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // detect language api
  const detectLanguageApi = (text, selectedLang) => {
    const endpoint = `https://translation.googleapis.com/language/translate/v2/detect?key=${BaseSetting.GOOGLE_TRANSLATE_API_KEY}`;
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const data = {
      q: text,
    };
    return new Promise(function (resolve, reject) {
      getApiData(endpoint, "post", data, header, true)
        .then((result) => {
          if (!_.isEmpty(result?.data)) {
            const detections = result.data?.detections?.[0];
            const language = detections?.[0];
            if (language?.language != selectedLang) {
              TranslateApi(language?.language, text, selectedLang).then(
                (result) => {
                  resolve(result);
                }
              );
            } else {
              resolve(result);
            }
          } else {
            resolve(text);
            toast(result.message, { type: "error" });
          }
        })
        .catch((err) => {
          // resolve(err);
          toast(err.message, { type: "error" });
        });
    });
  };

  // translate api
  const TranslateApi = (targetLang, text, targetLanguage) => {
    const endpoint = `https://translation.googleapis.com/language/translate/v2?key=${BaseSetting.GOOGLE_TRANSLATE_API_KEY}`;
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const data = {
      q: text,
      source: targetLang,
      target: targetLanguage,
      format: "text",
    };

    return new Promise(function (resolve, reject) {
      getApiData(endpoint, "post", data, header, true)
        .then((result) => {
          if (!_.isEmpty(result.data)) {
            resolve(result);
          } else {
            const error = result?.error;
            resolve(result);
          }
        })
        .catch((err) => {
          resolve(err);
          toast(err.message, { type: "error" });
        });
    });
  };

  // Socket emits Start
  const userConnect = (
    type = "",
    page,
    search = "",
    bool,
    addNew = false,
    allRead = false
  ) => {
    const data = {
      user_id: userId,
      type: type,
      search: search,
      page: page,
    };
    socket.emit("userConnect", data, (callBackData) => {
      console.log("callBackData======>>>>>", callBackData);
      console.log("data======>>>>>", data);
      if (callBackData?.status) {
        const conversationData = callBackData?.data;
        const convList = callBackData?.data?.Data;
        if (allRead) {
          const replaceDifferingObjects = (arr1, arr2) => {
            return arr1.map((obj1) => {
              const obj2 = arr2.find((obj) => obj.id === obj1.id);
              return obj2 && obj1.count !== obj2.count ? obj2 : obj1;
            });
          };
          const updatedArray1 = replaceDifferingObjects(
            allConversationList,
            convList
          );
          setAllConversationList(updatedArray1);
        }
        setChatLoading(false);
        setIsSearching(false);
        const isUnread = convList?.filter((data) => data.count > 0);
        if (isEmpty(isUnread)) {
          dispatch(setNotificationData(false));
        }
        if (page !== 1 && addNew) {
          setAllConversationList([
            ...allConversationList,
            ...conversationData?.Data,
          ]);
        } else {
          setAllConversationList(conversationData?.Data);
        }
        setPagination(callBackData?.data?.pagination);
        setPageLoad(false);
        if (convList.length > 0) {
          const selectedTab = type == "all" ? 0 : 1;
          setSelectedTab(selectedTab); // Selected Header Tab
          if (!_.isEmpty(chatConvData) && bool) {
            getSingleChat(chatConvData, 1, true);
            const findConv = convList?.find(
              (v) => v.conv_id == chatConvData?.conv_id
            );

            localStorage.setItem("selectedConv", JSON.stringify(findConv));
            window.history.replaceState({}, document.title);
            if (isMobile) {
              setDisable(true);
            }
          }
        }
      } else {
        toast(callBackData?.message, { type: "error" });
        setPageLoad(false);
      }
    });
  };

  const getSingleChat = (item, page, bool) => {
    setSingleChatLoader(true);
    if (!_.isUndefined(item?.id)) {
      msgReadAll(item?.id); // Read All Message
    }
    const data = {
      conv_id: item?.id,
      user_id: userId,
      page: page,
    };
    socket.emit("singleConversation", data, (callBackData) => {
      if (callBackData?.status) {
        const data = callBackData.data;
        setSelectedChat(data);
        generateItems(data?.getMessage?.rows);
        setSingleChatLoader(false);
        setTimeout(() => {
          ScrollToBottom(bool);
        }, 200);
      } else {
        setSingleChatLoader(false);
        toast(callBackData.message, { type: "error" });
      }
    });
  };
  const msgReadAll = (convId) => {
    const data = {
      conv_id: convId,
      sender_id: userId,
    };

    socket.emit("messageReadAll", data, (callBackData) => {
      console.log("callBackData======>>>>>", callBackData);
      if (callBackData?.status) {
        const type = selectedTab == 0 ? "all" : "unread";
        const sData = localStorage.getItem("selectedConv");
        const selectedConv = JSON.parse(sData);
        if (selectedConv?.count > 0) {
          userConnect(type, pageNo, searchValue, true, false, true);
        }
      } else {
        toast(callBackData.message, { type: "error" });
      }
    });
  };
  const sendMsg = () => {
    const sData = localStorage.getItem("selectedConv");
    const selectedConv = JSON.parse(sData);
    setSendBtnLoader(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    // stopTyping();
    const data = {
      conv_id: selectedConv?.conv_id,
      text: value,
      sender_id: userId,
      receiver_id: selectedChat?.userData?.user_id,
      type: "text",
    };

    socket.emit("sendMessage", data, (callBackData) => {
      if (callBackData?.status) {
        setOpenEmojiPicker(false);
        setValue("");
        const type = selectedTab == 0 ? "all" : "unread";
        userConnect(type, 1, searchValue, true, false);
        setPageNo(1);
        getSingleChat(selectedConv, 1, true);
        stopTyping();
        // ScrollToBottom(true);
        setSendBtnLoader(false);
      } else {
        toast(callBackData.message, { type: "error" });
        setSendBtnLoader(false);
      }
    });
  };
  // ! Send Image Api Call
  const sendImageApi = async (image) => {
    const receiverId = selectedChat?.userData?.user_id;

    const splitImageType = image?.type ? image?.type?.split("/") : [];
    const fileType = splitImageType[0];
    const form = new FormData();
    form.append("conv_id", selectedConv?.conv_id);
    form.append("text", fileType == "video" ? "Video" : "image");
    form.append("receiver_id", receiverId);
    form.append("sender_id", userId);
    form.append("type", fileType == "video" ? "video" : "image");
    if (fileType == "video") {
      form.append("video", image);
    } else {
      form.append("image", image);
    }

    try {
      const endPoint = "chatImageUpload";
      getAxiosApi(endPoint, "post", form, true)
        .then((result) => {
          if (result.status) {
            setValue("");
            const type = selectedTab == 0 ? "all" : "unread";
            userConnect(type, pageNo, searchValue, true, false);
            getSingleChat(selectedConv, chatPageNo, true);
            setSendBtnLoader(false);
          } else {
            setSendBtnLoader(false);
            toast(result.message, { type: "error" });
          }
        })
        .catch((err) => {
          setSendBtnLoader(false);
          toast(err.message, { type: "error" });
        });
    } catch (error) {
      console.log(`💥️ ~ sendImageApi ~ error ->`, error);
    }
  };
  const isTyping = () => {
    const sData = localStorage.getItem("selectedConv");
    const selectedConv = JSON.parse(sData);
    const data = {
      conv_id: selectedConv?.conv_id,
      sender_id: userId,
      receiver_id: selectedChat?.userData?.user_id,
    };
    socket.emit("isTyping", data, (callBackData) => {
      if (callBackData.status) {
      }
    });
  };
  const stopTyping = () => {
    const sData = localStorage.getItem("selectedConv");
    const selectedConv = JSON.parse(sData);
    const data = {
      conv_id: selectedConv?.conv_id,
      sender_id: userId,
      receiver_id: selectedChat?.userData?.user_id,
    };
    socket.emit("stopTyping", data, (callBackData) => {
      console.log("data======>>>>>", data);
    });
  };
  const receiveTyping = () => {
    socket.on("receiveTyping", (req, res) => {
      if (!_.isEmpty(req) && _.isObject(req)) {
        ScrollToBottom(true);
        setIsTypingData(req);
      }
    });
  };
  const receiveStopTyping = () => {
    socket.on("receiveStopTyping", (req, res) => {
      if (!_.isEmpty(req) && _.isObject(req)) {
        ScrollToBottom(true);
        setIsTypingData([]);
      }
    });
  };
  const receiveMsg = () => {
    socket.on("receiveMessage", (req) => {
      const sData = localStorage.getItem("selectedConv");
      const selectedConv = JSON.parse(sData);
      const isSame = req?.conv_id === selectedConv?.conv_id;

      if (!_.isEmpty(selectedConv) && isSame) {
        getSingleChat(selectedConv, 1, true);
      } else {
        setPageNo(1);
        userConnect("all", 1, "", true, false);
      }
    });
  };
  const receiveConv = () => {
    socket.on("receiveConversation", (req, res) => {
      userConnect("all", pageNo, "", true, false);
    });
  };

  const ChatList = () => {
    const conversationList = allConversationList;

    return (
      <>
        {!_.isEmpty(conversationList) &&
        _.isArray(conversationList) &&
        conversationList.length > 0 ? (
          <div
            ref={chatListRef}
            className={`${classes.chatListContainer} ${classes.scrollBar}`}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
            onScroll={onReachBottom}
          >
            {conversationList?.map((item, index) => {
              const sData = localStorage.getItem("selectedConv");
              const selectedConv = JSON.parse(sData);
              const selectedChat = selectedConv?.conv_id === item.id;
              const sellerName = `${item?.first_name} ${item?.last_name}`;
              const isTypingTrue = !_.isEmpty(isTypingData)
                ? item.conv_id === isTypingData.conv_id
                : false;

              const isLast = conversationList?.length == index + 1;
              return (
                <ChatListCard
                  id={selectedChat ? "chatListId" : null}
                  style={{ cursor: "pointer", marginBottom: isLast ? 0 : 5 }}
                  userImage={item.photo}
                  isOnline={item?.online}
                  name={sellerName}
                  count={item.count}
                  time={moment(item.lastMessageTime).format("hh:mm A")}
                  isTyping={isTypingTrue}
                  backgroundColor={BaseColor.disablePrimary}
                  lastMsg={item?.lastMessage}
                  activeChat={selectedChat}
                  onClick={() => {
                    if (item.id != selectedConv.id) {
                      setValue("");
                    }
                    setDisable(true);
                    localStorage.setItem("selectedConv", JSON.stringify(item));
                    getSingleChat(item, 1, true);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
              background: BaseColor.whiteColor,
            }}
          >
            {pageLoad ? (
              <CircularProgress
                style={{ color: BaseColor.primary }}
                size={40}
              />
            ) : (
              <span
                style={{
                  fontSize: 16,
                  color: BaseColor.textColor,
                  textAlign: "center",
                  fontFamily: FontFamily.Medium,
                }}
              >
                {isSearching ? (
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={40}
                  />
                ) : (
                  "No Conversation"
                )}
              </span>
            )}
          </div>
        )}
        {chatLoading ? (
          <Grid
            mt={-8}
            sx={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
            backgroundColor={"white"}
            container
            justifyContent={"center"}
            alignItems={"center"}
            zIndex={9999}
            mr={"5px"}
          >
            <CircularProgress style={{ color: BaseColor.primary }} size={30} />
          </Grid>
        ) : null}
      </>
    );
  };

  function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.createdAt).format("YYYY-MM-DD");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  function generateItems(messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => moment(x, "YYYY-MM-DD").unix() - moment(y, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) => new Date(x.createdAt) - new Date(y.createdAt)
      );

      let addDate;
      const todaysDate = moment(new Date()).format("yyyy-MM-dd");
      const yesterDate = moment(new Date())
        .subtract(1, "days")
        .format("yyyy-MM-dd");
      const formateDate = moment(date).format("yyyy-MM-dd");

      if (formateDate === todaysDate) {
        addDate = "Today";
      } else if (formateDate == yesterDate) {
        addDate = "Yesterday";
      } else {
        addDate = date;
      }
      return acc.concat([
        {
          type: "day",
          date: addDate,
        },
        ...sortedMessages,
      ]);
    }, []);
    setMsgList(items);
    return items;
  }

  const ChatContainer = () => {
    const sData = localStorage.getItem("selectedConv");
    const selectedConv = JSON.parse(sData);

    const isMore = selectedChat?.pagination?.isMore
      ? selectedChat?.pagination?.isMore
      : false;
    const totalPages =
      selectedChat.pagination?.totalPage >= 0
        ? selectedChat?.pagination?.totalPage
        : -1;
    const currentPage =
      selectedChat?.pagination?.currentPage >= 0
        ? selectedChat?.pagination?.currentPage
        : -1;
    const nextPage = currentPage <= totalPages ? currentPage + 1 : currentPage;

    const receiverImage =
      !_.isEmpty(selectedChat?.userData?.photo) &&
      !_.isUndefined(selectedChat?.userData?.photo) &&
      !_.isNull(selectedChat?.userData?.photo)
        ? selectedChat?.userData?.photo
        : selectedChat?.userData?.url;

    const receiverName =
      selectedConv?.first_name + " " + selectedConv?.last_name;

    const isTypingTrue =
      !_.isEmpty(isTypingData) && !_.isEmpty(selectedChat)
        ? isTypingData.conv_id === selectedConv.conv_id &&
          isTypingData.receiver_id === userId
        : false;

    return (
      <>
        {!_.isEmpty(selectedChat) && !_.isEmpty(selectedConv) ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              backgroundColor: BaseColor.whiteColor,
              padding: sm ? "5px 6px" : "5px 15px",
              position: "relative",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                backgroundColor: BaseColor.whiteColor,
              }}
            >
              <Grid
                item
                style={{
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  minHeight: "59vh",
                  backgroundColor: BaseColor.whiteColor,
                }}
              >
                <Grid container>
                  <div
                    ref={messagesEndRef}
                    style={{
                      display: "flex",
                      height: "100%",
                      maxHeight: md ? "60vh" : "53vh",
                      marginTop: md ? 40 : 60,
                      width: "100%",
                      overflowY: "scroll",
                      flexDirection: "column",
                      scrollBehavior: "smooth",
                    }}
                    className={classes.scrollBar}
                  >
                    {isMore && (
                      <div
                        style={{
                          display: "flex",
                          alignSelf: "center",
                          width: "auto",
                          cursor: "pointer",
                          marginBottom: 15,
                          marginTop: 20,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setChatPageNo(nextPage);
                          getSingleChat(selectedConv, nextPage, false);
                        }}
                      >
                        {singleChatLoader ? (
                          <CircularProgress size={18} />
                        ) : (
                          <span
                            style={{
                              fontSize: 18,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.primary,
                            }}
                          >
                            Load More
                          </span>
                        )}
                      </div>
                    )}
                    {msgList?.map((item, index) => {
                      const senderName = selectedConv?.conv_name;
                      const senderImage = userData?.user_image;
                      const isSender = item.sender_id === userId;

                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: isMore ? 0 : 20,
                          }}
                        >
                          {item.type && item.type === "day" ? (
                            <span
                              style={{
                                fontSize: 14,
                                fontFamily: FontFamily.Regular,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {item?.date}
                            </span>
                          ) : (
                            <>
                              <CChat
                                isVideo={item?.type == "video"}
                                isTypeImage={item?.type == "image"}
                                fileUrl={item?.file_name}
                                chatSender={isSender}
                                senderImage={senderImage}
                                senderName={senderName}
                                senderMsgTime={moment(item.createdAt).format(
                                  "hh:mm A"
                                )}
                                onImageClick={() => {
                                  previewModalRef?.current.open(
                                    item?.file_name
                                  );
                                }}
                                senderMsg={item.text}
                              />
                              <CChat
                                isVideo={item?.type == "video"}
                                isTypeImage={item?.type == "image"}
                                fileUrl={item?.file_name}
                                chatReceiver={!isSender}
                                translatedText={item.translatedText}
                                languageList={languageList}
                                onTranslateClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                onSelectLanguage={(val) => {
                                  detectLanguageApi(
                                    item?.text,
                                    val.language
                                  ).then((result) => {
                                    const tempMsgList = msgList;
                                    if (result?.data?.translations) {
                                      const text =
                                        result.data?.translations?.[0]
                                          .translatedText;
                                      tempMsgList[index].translatedText = text;
                                      setMsgList([...tempMsgList]);
                                    } else {
                                      tempMsgList[index].translatedText =
                                        item?.text;
                                      setMsgList([...tempMsgList]);
                                    }
                                  });
                                }}
                                receiverImage={receiverImage}
                                receiverName={receiverName}
                                receiverMsgTime={moment(item.createdAt).format(
                                  "hh:mm A"
                                )}
                                onImageClick={(v) => {
                                  previewModalRef?.current.open(
                                    item?.file_name
                                  );
                                }}
                                receiverMsg={item.text}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                    {isTypingTrue ? (
                      <CChat
                        isTyping={isTypingTrue}
                        chatReceiver={isTypingTrue}
                        receiverImage={receiverImage}
                        receiverName={receiverName}
                      />
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <Grid
                style={{
                  marginTop: isMobile ? 70 : 50,
                  marginBottom: 10,
                  position: "relative",
                }}
              >
                <CChatInput
                  value={value}
                  onChange={(val) => {
                    setValue(val);
                    if (timeoutRef.current) clearTimeout(timeoutRef.current);
                    timeoutRef.current = setTimeout(stopTyping, 5000);
                    isTyping();
                  }}
                  sendBtnLoader={sendBtnLoader}
                  isEmojiPickerOpen={openEmojiPicker}
                  onClickEmoji={() => {
                    setOpenEmojiPicker(!openEmojiPicker);
                  }}
                  onSend={() => {
                    if (value.trim() === "") {
                    } else {
                      sendMsg();
                    }
                  }}
                  file={uploadedFiles}
                  onFileUpload={(val) => {
                    setUploadedFiles([...val]);
                    sendImageApi(val[0]);
                  }}
                />
              </Grid>
            </div>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              // backgroundColor: BaseColor.offWhite,
              // height: isDesktop ? "74vh" : "79vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={Files.lottie.emptyChatLoader} loop={true} />
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container>
        <MiniDrawer
          header="Chats"
          children={
            <>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
                <Grid
                  item
                  xs={12}
                  sm={11}
                  md={11}
                  lg={10}
                  xl={12}
                  style={{
                    background: BaseColor.offWhite,
                    borderRadius: 5,
                    padding: 15,
                    height: md ? "79vh" : null,
                    overflowY: "scroll",
                  }}
                  className={classes.scrollBar}
                >
                  {/* Chat Search and Selected Chat Header  Container  */}
                  <Grid container wrap="nowrap">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                      className={disable && classes.chatContainer}
                    >
                      <Grid container>
                        <CInput
                          placeholder="Search..."
                          value={searchValue}
                          onChange={(val) => {
                            userConnect("all", 1, val, true, false);
                            setPageNo(1);
                            setSearchValue(val);
                            setAllConversationList([]);
                            setIsSearching(true);
                          }}
                          endIcon={
                            <FiSearch size={20} color={BaseColor.primary} />
                          }
                        />
                      </Grid>
                      <Grid container>
                        <div style={{ margin: md ? "20px 0px" : "10px 0px" }}>
                          <span className={classes.messageText}>Messages</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={0} sm={0} md={0.1} lg={0.2} xl={0.2} />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      lg={9}
                      xl={9}
                      className={!disable && classes.chatContainer}
                    >
                      {!_.isEmpty(selectedChat) && !_.isEmpty(selectedConv) ? (
                        <Grid
                          container
                          style={
                            disable
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  marginBottom: 28,
                                }
                              : { marginBottom: 28 }
                          }
                        >
                          {md && (
                            <Grid item xs={12} sm={12} md={12}>
                              {/* Product image */}
                              <ArrowBackIcon
                                onClick={() => setDisable(false)}
                                style={{ display: !disable && "none" }}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={!disable ? 11 : 12}
                            sm={!disable ? 11 : 12}
                            md={!disable ? 11 : 12}
                            lg={12}
                            xl={12}
                            className={classes.garbageMainContainer}
                          >
                            <Grid
                              container
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // margin: "25px 0px 0px 10px",
                              }}
                            >
                              <Grid item xs={8} sm={8} md={9} lg={9} xl={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    backgroundColor: "#674188",
                                    borderRadius: 5,
                                    padding: "10px 0px 10px 5px",
                                    marginLeft: sm
                                      ? 0
                                      : md && isTablet
                                      ? 20
                                      : 8,
                                  }}
                                >
                                  {!_.isEmpty(selectedConv?.photo) &&
                                  !_.isUndefined(selectedConv?.photo) &&
                                  !_.isNull(selectedConv?.photo) ? (
                                    <img
                                      src={selectedConv?.photo}
                                      className={classes.garbageImg}
                                    />
                                  ) : (
                                    <div
                                      className={classes.garbageImg}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className={classes.imagePlaceholderText}
                                        style={{
                                          color: BaseColor.whiteColor,
                                        }}
                                      >
                                        {selectedConv?.conv_name
                                          ?.charAt(0)
                                          ?.toUpperCase()}
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      width: "100%",
                                      marginLeft: 10,
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <span
                                      className={classes.title}
                                      style={{
                                        lineHeight: 1.5,
                                        color: "white",
                                      }}
                                    >
                                      {selectedConv?.first_name +
                                        " " +
                                        selectedConv?.last_name}
                                    </span>

                                    <span
                                      style={{ color: "white" }}
                                      className={classes.name}
                                    >
                                      {selectedConv?.conv_name}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent={"space-between"}
                    wrap="nowrap"
                  >
                    {/* Chat Header Button and List Container */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                      className={disable && classes.chatContainer}
                    >
                      {/* Header Tab Button */}
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8} xl={5.8}>
                          <CButton
                            variant={
                              selectedTab === 0 ? "contained" : "outlined"
                            }
                            onClick={() => {
                              if (selectedTab === 0) {
                                return null;
                              } else {
                                userConnect("all", 1, searchValue, false);
                                setPageNo(1);
                                setSelectedTab(0);
                                setPageLoad(true);
                                setAllConversationList([]);
                              }
                            }}
                            style={{
                              border: "none",
                              width: "100%",
                              fontWeight: selectedTab !== 0 && 600,
                            }}
                          >
                            All Chats
                          </CButton>
                        </Grid>
                        <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8} xl={5.8}>
                          <CButton
                            variant={
                              selectedTab === 1 ? "contained" : "outlined"
                            }
                            onClick={() => {
                              if (selectedTab === 1) {
                                return null;
                              } else {
                                localStorage.setItem(
                                  "selectedConv",
                                  JSON.stringify({})
                                );
                                userConnect("unread", 1, searchValue, false);
                                setSelectedTab(1);
                                setPageNo(1);
                                setPageLoad(true);
                                setAllConversationList([]);
                              }
                            }}
                            style={{
                              border: "none",
                              fontWeight: selectedTab !== 1 && 600,
                            }}
                          >
                            Unread
                          </CButton>
                        </Grid>
                      </Grid>
                      {/* Chat Conversation List */}
                      <Grid container>{ChatList()}</Grid>
                    </Grid>
                    <CPreviewModal ref={previewModalRef} sm={sm} md={md} />

                    {/* Spacing Grid */}
                    <Grid item xs={0} sm={0} md={0.1} lg={0.2} xl={0.2} />
                    {/* Chat Container  */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7.6}
                      lg={8.6}
                      xl={8.8}
                      className={!disable && classes.chatContainer}
                    >
                      <Grid
                        container
                        style={{
                          display: "flex",
                        }}
                      >
                        <Grid container>{ChatContainer()}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        />
      </Grid>
      <div className={classes.card}>{/* Main Container */}</div>
    </>
  );
};
export default Chat;
