import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CSelect from "../../Components/CSelect";
import { Edit, Delete } from "@mui/icons-material";
import theme, { FontFamily } from "../../Config/theme";
import CModal from "../../Components/CModal";
import CButton from "../../Components/CButton";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import _, { isArray, isEmpty, isNull } from "lodash";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//Date Filter
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/";
import styles from "./style";
import CInput from "../../Components/CInput";

const postTypeData = [
  {
    id: "posted",
    name: "Posted",
  },
  {
    id: "in_review",
    name: "In Review",
  },
  {
    id: "rejected",
    name: "Rejected",
  },
  {
    id: "boosted",
    name: "Boosted",
  },
  {
    id: "sold",
    name: "Mark as sold",
  },
];

const createdPostTypeData = [
  {
    id: "created_by_user",
    name: "Created by users",
  },
  {
    id: "created_by_admin",
    name: "Created by admin",
  },
  {
    id: "created_by_all",
    name: "Created by all",
  },
];

export default function AllPosts() {
  const navigate = useNavigate();
  const classes = styles();
  const searchRef = useRef();

  // Data table and pagination
  const [draftData, setDraftData] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);

  //btn loaders
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [confirm, setConfirm] = useState(false);

  // Boost post
  const [openBoostModal, setOpenBoostModal] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [days, setDays] = useState("");
  const [daysValidationError, setDaysValidationError] = useState("");
  const [id, setId] = useState("");

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  //Read more
  const [showData, setShowData] = useState("");
  const [viewMoreModal, setViewMoreModal] = useState();

  // download data in various formats
  const [selectFileType, setSelectFileType] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  // search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [copiedStates, setCopiedStates] = useState({});
  const [rowChange, setRowChange] = useState(false);
  const [postType, setPostType] = useState(
    searchParams.get("status") === "in_review"
      ? {
          id: "in_review",
          name: "In Review",
        }
      : searchParams.get("status") === "boosted"
      ? {
          id: "boosted",
          name: "Boosted",
        }
      : searchParams.get("status") === "rejected"
      ? {
          id: "rejected",
          name: "Rejected",
        }
      : searchParams.get("status") === "sold"
      ? {
          id: "sold",
          name: "Mark as sold",
        }
      : {
          id: "posted",
          name: "Posted",
        }
  );

  const [createdPostType, setCreatedPostType] = useState(
    searchParams.get("created_by") === "created_by_admin"
      ? {
          id: "created_by_admin",
          name: "Created by admin",
        }
      : searchParams.get("created_by") === "created_by_user"
      ? {
          id: "created_by_user",
          name: "Created by user",
        }
      : {
          id: "created_by_all",
          name: "Created by all",
        }
  );

  // get values from URL params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";

  const postStatus = searchParams.has("status")
    ? searchParams.get("status")
    : postType?.id;
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const createdPost = searchParams.has("created_by")
    ? searchParams.get("created_by")
    : createdPostType.id;

  const downloadIdArr =
    searchParams.has("download_id") && !_.isEmpty(downloadId)
      ? searchParams
          .get("download_id")
          ?.split(",")
          .map((str) => parseInt(str, 10))
      : [];

  const [postIds, setPostIds] = useState(downloadIdArr);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      downloadId,
      createdPost
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    post_status = "",
    download_id = [],
    created_by = ""
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(post_status)) {
      params.status = post_status;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(created_by)) {
      params.created_by = created_by;
    }
    setSearchParams(createSearchParams(params));
  };

  const [visibleColumns, setVisibleColumns] = useState([
    "Id",
    "Post id",
    "Full name",
    "Email",
    "Phone",
    "User country",
    "Post country",
    "Post name",
    "Category name",
    "Subcategory name",
    "Condition",
    "Type",
    "Pricing term",
    "Language",
    "Business name",
    "Business website",
    "Landline number",
    "Pincode",
    "City",
    "Status",
    "Unit price",
    "Created At",
    "Post link",
  ]);

  useEffect(() => {
    setPageLoad(true);
    getDraftData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      createdPost
    );
  }, [postType]);

  //get all post data
  const getDraftData = (
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate,
    postStatus,
    createdPost
  ) => {
    setDataLoader(true);
    const data = {
      page: page,
      status: postType?.id,
    };

    if (postType?.id === "boosted") {
      data.boosted = true;
    }

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    if (!_.isEmpty(postStatus)) {
      data.status = postStatus;
    }

    if (
      createdPost === "created_by_admin" ||
      createdPost === "created_by_user"
    ) {
      data.filter_by = createdPost === "created_by_user" ? "users" : "is_admin";
    }
    const endpoint = `${BaseSetting.endpoint.allDraftData}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setDraftData(response?.posts);
          setPagination(response?.pagination);
          setTimeout(() => {
            setPageLoad(false);
            setDataLoader(false);
          }, 100);
        } else {
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  //delete post
  const deletePost = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deletePost}`;
    getApiData(endpoint, "post", { id: deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDeleteId("");
          getDraftData(
            pageNo,
            search,
            sortVal,
            sortByVal,
            startDateVal,
            endDateVal,
            postStatus,
            createdPost
          );
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  // delete multiple posts
  const deleteMultiplePosts = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deleteMultiplePost}`;
    getApiData(endpoint, "post", { id: postIds })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setConfirm(false);
          setYesBtnLoading(false);
          setPostIds([]);
          getDraftData(
            pageNo,
            search,
            sortVal,
            sortByVal,
            startDateVal,
            endDateVal,
            postStatus,
            createdPost
          );
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const handleCopyClick = (id, name) => {
    const post_name = name
      .replace(/[^\w-]/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-|-$/g, "") // Remove hyphens from start and end
      .toLowerCase();
    navigator.clipboard.writeText(
      `${BaseSetting.appUrl}/product-detail/${id}-${post_name}`
    );

    setCopiedStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    setTimeout(() => {
      setCopiedStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }, 3000);
  };

  // download data in various formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      format: "a4",
      orientation: "landscape",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save(`${postType.name}-${createdPost}-Data.pdf`);
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${postType.name}-${createdPost}-Data.csv`;
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${postType.name}-Data.xlsx`);
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data api call
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      status: postType.id,
      download_id: postIds,
    };

    if (!_.isEmpty(startDateVal)) {
      data.start_date = startDateVal;
    }

    if (!_.isEmpty(endDateVal)) {
      data.end_date = endDateVal;
    }
    if (
      createdPost === "created_by_admin" ||
      createdPost === "created_by_user"
    ) {
      data.filter_by = createdPost === "created_by_user" ? "users" : "is_admin";
    }
    getApiData(BaseSetting.endpoint.allDraftData, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              "Post id": val?.post_id,
              "Full name": val?.user_name,
              Email: val?.email,
              Phone: val?.phone,
              "User country": val?.user_country,
              "Post country": val?.country,
              "Post name": val?.name,
              "Category name": val?.category_name,
              "Subcategory name": val?.subcategory_name,
              Condition: val?.conditions,
              Type: val?.type,
              "Pricing term": val?.pricing_term,
              Language: val?.language,
              "Business name": val?.business_name,
              "Business website": val?.business_webside,
              "Landline number": val?.landline_number,
              Pincode: val?.zipcode,
              City: val?.city,
              Status: val?.status === "in_review" ? "In Review" : "Posted",
              "Unit price": val?.unit_price,
              "Created at": formattedLoginAt,
              "Post link": `${BaseSetting.appUrl}/product-detail/${
                val?.id
              }-${val?.name
                ?.replace(/[^\w-]/g, "-")
                .replace(/-+/g, "-")
                .replace(/^-|-$/g, "")
                .toLowerCase()}`,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  //Remove boost post
  function removeBoostPost(id) {
    const data = { post_id: id };
    getApiData(BaseSetting.endpoint.removeBoostPost, "post", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getDraftData(
            pageNo,
            search,
            sortVal,
            sortByVal,
            startDateVal,
            endDateVal,
            postStatus,
            createdPost
          );
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // Boost post api call
  const boostPostApi = () => {
    setDisableBtn(true);
    setSaveBtnLoading(true);
    if (days.trim().length === 0) {
      setDaysValidationError("Number of days are required");
      setDisableBtn(false);
      setSaveBtnLoading(false);
    } else {
      const parsedDays = parseInt(days);
      if (isNaN(parsedDays) || parsedDays <= 0 || parsedDays > 365) {
        setDaysValidationError(
          "Invalid number of days. Enter a number between 1 and 365."
        );
        setDisableBtn(false);
        setSaveBtnLoading(false);
      } else {
        const data = {
          days: days,
          post_id: id,
        };
        setDaysValidationError("");
        getApiData(BaseSetting.endpoint.boostPost, "POST", data)
          .then((result) => {
            if (result?.status) {
              toast(result?.message, { type: "success" });
              setDisableBtn(false);
              setSaveBtnLoading(false);
              setOpenBoostModal(false);
              setId("");
              setDays("");
            } else {
              toast(result?.message, { type: "error" });
              setDisableBtn(false);
              setSaveBtnLoading(false);
            }
          })
          .catch((err) => {
            toast(err?.message, { type: "error" });
            setDisableBtn(false);
            setSaveBtnLoading(false);
          });
      }
    }
  };

  const draftsColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        draftData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 230,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div>
              <Button
                style={{ marginLeft: postStatus === "boosted" && "10px" }}
                variant="contained"
                // onClick={() => openModal(params?.row?.id)}
                onClick={() =>
                  navigate(
                    `/post-details?id=${params?.row?.id}&page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
                  )
                }
              >
                View more
              </Button>
              {postStatus === "boosted" && (
                <Button
                  variant="contained"
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  onClick={() => {
                    removeBoostPost(params?.row?.id);
                  }}
                >
                  Remove boost
                </Button>
              )}

              {postStatus === "posted" && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenBoostModal(true);
                    setId(params?.row?.id);
                  }}
                  style={{ marginTop: "10px" }}
                >
                  Boost post
                </Button>
              )}
            </div>

            {params?.row?.status === "posted" && (
              <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item>
                  <Tooltip title="Edit" placement="top" arrow>
                    <Button
                      onClick={() => {
                        params?.row?.type === "Sell"
                          ? navigate(
                              `/sell-post?id=${params?.row?.id}&page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`,
                              {
                                state: {
                                  update: true,
                                  postId: params?.row?.id,
                                },
                              }
                            )
                          : navigate(
                              `/buy-post?id=${params?.row?.id}&page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`,
                              {
                                state: {
                                  update: true,
                                  postId: params?.row?.id,
                                },
                              }
                            );
                      }}
                    >
                      <Edit style={{ fontSize: 20 }} />
                    </Button>
                  </Tooltip>
                </Grid>
                {_.isEmpty(postIds) ? (
                  <Grid item>
                    <Tooltip title="Delete" placement="top" arrow>
                      <Button
                        onClick={() => {
                          setConfirm(true);
                          setDeleteId(params?.row?.id);
                        }}
                      >
                        <Delete
                          style={{ fontSize: 20, color: BaseColor.red }}
                        />
                      </Button>
                    </Tooltip>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            )}
            <Tooltip
              title={
                copiedStates[params?.row?.id] ? "Copied!" : "Copy to clipboard"
              }
              placement="bottom"
              arrow
            >
              <IconButton
                onClick={() =>
                  handleCopyClick(params?.row?.id, params?.row?.name)
                }
                color={copiedStates[params?.row?.id] ? "success" : "primary"} // Change color to success when copied
              >
                {copiedStates[params?.row?.id] ? (
                  <CheckCircleIcon />
                ) : (
                  <ContentCopyIcon />
                )}
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "post_id",
      headerName: "POST ID",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user_name",
      headerName: "FULL NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "POST NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: "PHONE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user_country",
      headerName: "USER COUNTRY",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "POST COUNTRY",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "category_name",
      headerName: "CATEGORY",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subcategory_name",
      headerName: "SUB CATEGORY",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "conditions",
      headerName: "CONDITION",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pricing_term",
      headerName: "PRICING TERMS",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "language",
      headerName: "LANGUAGE",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "business_name",
      headerName: "BUSINESS NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "business_webside",
      headerName: "BUSINESS WEBSITE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "landline_number",
      headerName: "LANDLINE NUMBER",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "zipcode",
      headerName: "PINCODE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "city",
      headerName: "CITY",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_title",
      headerName: "META TITLE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_description",
      headerName: "META DESCRIPTION",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {params?.row?.meta_description?.slice(0, 150)}
              <div />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() => {
                  setShowData(params?.row?.meta_description);
                  setViewMoreModal(true);
                }}
              >
                {params?.row?.meta_description?.length <= 150
                  ? ""
                  : !_.isUndefined(params?.row?.meta_description?.length) &&
                    "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "meta_keyword",
      headerName: "META KEYWORDS",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {params?.row?.meta_keyword?.slice(0, 150)}
              <div />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() => {
                  setShowData(params?.row?.meta_keyword);
                  setViewMoreModal(true);
                }}
              >
                {params?.row?.meta_keyword?.length <= 150
                  ? ""
                  : !_.isUndefined(params?.row?.meta_keyword?.length) &&
                    "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "image_name",
      headerName: "IMAGE TITLE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "STATUS",
      width: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.status === "in_review" ? (
          <span>In Review</span>
        ) : params?.row?.status === "rejected" ? (
          <span>Rejected</span>
        ) : params?.row?.status === "posted" ? (
          <span>Posted</span>
        ) : params?.row?.status === "sold" ? (
          <span>Sold</span>
        ) : null;
      },
    },
    {
      field: "unit_price",
      headerName: "UNIT PRICE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "boost_expired_date",
      headerName: "Boost expire date",
      width: 200,
      headerAlign: "center",
      align: "center",
      hidden: true,
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.boost_expired_date);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const filterColumns = draftsColumns.filter((data) => data.hidden !== true);

  // filter with date
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          // overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="All Posts"
        children={
          <>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={3}>
                <CSelect
                  fullObj
                  data={postTypeData}
                  placeholder="Select post type"
                  value={postTypeData?.find((data) => data?.id === postStatus)}
                  onSelect={(val) => {
                    setPostType(val);
                    setPostIds([]);
                    setHeaderParams(
                      1,
                      "",
                      "",
                      "",
                      "",
                      "",
                      val?.id,
                      [],
                      createdPost
                    );
                    setSearchVal("");
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CSelect
                  style={{ marginLeft: "15px" }}
                  fullObj
                  data={createdPostTypeData}
                  placeholder="Select created post type"
                  value={createdPostTypeData?.find(
                    (data) => data?.id === createdPost
                  )}
                  onSelect={(val) => {
                    setCreatedPostType(val);
                    setDeleteId([]);
                    setSearchVal("");
                    setHeaderParams(
                      1,
                      "",
                      "",
                      "",
                      "",
                      "",
                      postStatus,
                      [],
                      val?.id
                    );
                    // getAllUserData(val?.id, 1, "", "", "", "", "", []);
                    getDraftData(1, "", "", "", "", "", postStatus, val?.id);
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    flexWrap: "wrap",
                  }}
                >
                  {!_.isEmpty(search) ||
                  !_.isEmpty(sortVal) ||
                  !_.isEmpty(sortByVal) ||
                  !_.isEmpty(downloadId) ||
                  !_.isEmpty(postIds) ||
                  !_.isEmpty(startDateVal) ||
                  !_.isEmpty(endDateVal) ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setPostIds([]);
                        setStartDate("");
                        setEndDate("");
                        setSearchVal("");
                        setHeaderParams(
                          page,
                          "",
                          "",
                          "",
                          "",
                          "",
                          postStatus,
                          [],
                          createdPost
                        );
                        getDraftData(
                          page,
                          "",
                          "",
                          "",
                          "",
                          "",
                          postStatus,
                          createdPost
                        );
                        setDataLoader(true);
                        if (dataGridRef.current) {
                          dataGridRef.current.setFilterModel({
                            items: [],
                            quickFilter: "",
                          });
                        }
                      }}
                    >
                      Clear filters
                    </Button>
                  ) : null}

                  <Button
                    variant="contained"
                    onClick={() => setDateModel(true)}
                  >
                    Filter date
                  </Button>
                  {postStatus == "posted" && !_.isEmpty(postIds) ? (
                    <Button
                      variant="outlined"
                      style={{ color: "red", borderColor: "red" }}
                      onClick={() => {
                        setConfirm(true);
                      }}
                    >
                      Delete
                    </Button>
                  ) : (
                    ""
                  )}
                  <CSelect
                    placeholder="Choose file format for download"
                    value={selectFileType}
                    data={downloadOptions}
                    onSelect={(val) => {
                      setSelectFileType(val);
                      getDownloadData(val);
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        postStatus,
                        downloadId,
                        createdPost
                      );
                      getDraftData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        postStatus,
                        createdPost
                      );
                    } else {
                      setHeaderParams(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        postStatus,
                        downloadId,
                        createdPost
                      );
                      getDraftData(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        postStatus,
                        createdPost
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={draftData}
                  columns={
                    postStatus == "boosted" ? draftsColumns : filterColumns
                  }
                  disableColumnMenu
                  disableDensitySelector
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  loading={dataLoader}
                  hideFooter={isEmpty(postIds)}
                  hideFooterPagination
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  disableRowSelectionOnClick
                  checkboxSelection
                  rowSelectionModel={postIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setPostIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        postStatus,
                        downloadIdString,
                        createdPost
                      );
                    }
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          postStatus,
                          downloadId,
                          createdPost
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getDraftData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDateVal,
                            endDateVal,
                            postStatus,
                            createdPost
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(draftData) && isArray(draftData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      postStatus,
                      downloadId,
                      createdPost
                    );
                    getDraftData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      postStatus,
                      createdPost
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={confirm}
        title={"Delete post"}
        onClose={() => {
          setConfirm(false);
          setDeleteId("");
        }}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.SemiBold,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to delete this post ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                      setDeleteId("");
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() =>
                      !_.isEmpty(postIds) ? deleteMultiplePosts() : deletePost()
                    }
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />

      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                switchViewButton
                disableFuture
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    postStatus,
                    downloadId,
                    createdPost
                  );
                  getDraftData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    postStatus,
                    createdPost
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
      <CModal
        visible={openBoostModal}
        style={{ width: "30%" }}
        onClose={() => {
          setOpenBoostModal(false);
          setDaysValidationError("");
          setDays("");
          setId("");
          setDisableBtn(false);
          setSaveBtnLoading(false);
        }}
        title={"Boost post"}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                <CInput
                  placeholder="Days"
                  errorMsg={daysValidationError}
                  value={days}
                  onChange={(val) => {
                    setDays(val);
                    setDaysValidationError("");
                  }}
                />
              </Grid>
              <Grid container display={"flex"} justifyContent={"space-between"}>
                <Grid item xs={5.9} marginTop={2}>
                  <CButton
                    onClick={() => {
                      boostPostApi();
                    }}
                    fullWidth
                    variant="contained"
                    disabled={disableBtn}
                    loading={saveBtnLoading}
                  >
                    Save
                  </CButton>
                </Grid>
                <Grid item xs={5.9} marginTop={2}>
                  <CButton
                    onClick={() => {
                      setOpenBoostModal(false);
                      setId("");
                      setDays("");
                      setDaysValidationError("");
                    }}
                    fullWidth
                    variant="outlined"
                    disabled={disableBtn}
                  >
                    Cancel
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      />
      <CModal
        visible={viewMoreModal}
        onClose={() => {
          setViewMoreModal(false);
        }}
        title={"Read more"}
        style={{ maxWidth: "70%", maxHeight: "60%" }}
        children={<div style={{ padding: "20px" }}>{showData}</div>}
      />
    </Grid>
  );
}
