import React from "react";
import styles from "./styles";
import { IoClose } from "react-icons/io5";
import BaseColor from "../../Config/Color";
import _ from "lodash";

const TagsInput = (props) => {
  const {
    onKeyDown = () => {},
    value = "",
    onChange = () => {},
    tagList = [],
    removeTag = () => {},
    errorMsg = "",
  } = props;

  const classes = styles();

  return (
    <>
      <div className={classes.tagsInputContainer}>
        {tagList.map((data, index) => (
          <div className={classes.tagItem}>
            <span className={classes.text}>{data}</span>
            <span className={classes.close} onClick={() => removeTag(index)}>
              <IoClose size={18} color={BaseColor.whiteColor} />
            </span>
          </div>
        ))}

        <input
          type="text"
          placeholder="Add meta keywords"
          className={classes.tagInput}
          onKeyDown={onKeyDown}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        ></input>
      </div>
      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </>
  );
};

export default TagsInput;
