import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../../Config/theme"

const styles = makeStyles({
  title: {
    fontFamily: FontFamily.SemiBold,
    fontSize: "20px",
  },
});

export default styles;
