import { Grid } from "@mui/material";
import React from "react";
import MiniDrawer from "../../Components/Drawer";

export default function Seller() {
  return (
    <Grid container>
      <MiniDrawer children={<div>Seller</div>} />
    </Grid>
  );
}
