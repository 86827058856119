import { Grid, Typography, Autocomplete, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../../Components/Drawer";
import FormControl from "@mui/material/FormControl";
import BaseColor from "../../../Config/Color";
import { getApiData } from "../../../Apis/apiHelper";
import BaseSetting from "../../../Apis/setting";
import _, { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { FontFamily } from "../../../Config/theme";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import CInput from "../../../Components/CInput";
import { Editor } from "@tinymce/tinymce-react";
import CButton from "../../../Components/CButton";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import CSelect from "../../../Components/CSelect";
const SendEmails = () => {
  const [radioValue, setRadioValue] = useState("all");

  const [emailsList, setEmailsList] = useState();
  // const [inputValue, setInputValue] = useState();
  const [subject, setSubject] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [EmailSend, setEmailSend] = useState([]);

  //validation
  const [templateNameErrorText, setTemplateNameErrorText] = useState("");
  const [subjectErrorText, setSubjectErrorText] = useState("");
  const [contentErrorText, setContentErrorText] = useState("");

  const [groupList, setGroupList] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [groupErrorText, setGroupErrorText] = useState("");

  useEffect(() => {
    allGroupApiCall();
  }, []);

  // useEffect(() => {
  //   allErrorFalse();
  //   allTextClear();
  // }, [radioValue]);

  const editorRefApp = useRef(null);

  const handleInputChange = (_, newValue) => {
    const isValidEmail = /\S+@\S+\.\S+/.test(newValue[newValue.length - 1]);
    if (isValidEmail) {
      // setInputValue(newValue[newValue.length - 1]);
      setEmailSend(newValue);
      // console.log("isValidEmail");
    } else {
      console.log("Please enter a valid email");
      console.log(newValue.pop());
    }
  };

  const validation = () => {
    const content = editorRefApp.current.getContent();
    if (isEmpty(selectedGroupId)) {
      allErrorFalse();
      setGroupErrorText("Select group");
    } else if (subject.trim() === "") {
      allErrorFalse();
      setSubjectErrorText("Subject is required");
    } else if (content.trim() === "") {
      allErrorFalse();
      setContentErrorText("Content is required");
    } else if (radioValue === "specific" && _.isEmpty(EmailSend)) {
      allErrorFalse();
      setTemplateNameErrorText("Email  is required");
    } else {
      allErrorFalse();
      sendBulkMail();
    }
  };

  const sendBulkMail = () => {
    const content = editorRefApp.current.getContent();
    setBtnLoad(true);
    setDisableBtn(true);

    const endpoint = BaseSetting.endpoint.SendBulkEmails;

    let data = {
      title: subject,
      description: content,
      type: radioValue === "all" ? "all" : "specific",
      emails: EmailSend,
      group_id: selectedGroupId?.id,
      api_type: "news_letter",
    };

    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setDisableBtn(false);
          allTextClear();
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  const allErrorFalse = () => {
    setTemplateNameErrorText("");
    setGroupErrorText("");
    setSubjectErrorText("");
    setContentErrorText("");
  };
  const allTextClear = () => {
    setSubject("");
    setEmailSend([]);
    editorRefApp?.current?.setContent("");
    setSelectedGroupId("");
  };
  const handleChipDelete = (chipToDelete) => () => {
    setEmailSend((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  async function allGroupApiCall() {
    try {
      const data = {
        type: "web",
      };

      const response = await getApiData(
        BaseSetting.endpoint.groupList,
        "post",
        data
      );
      if (response?.status) {
        if (!_.isEmpty(response?.data)) {
          setGroupList(response?.data?.group);
        }
      } else {
        // toast(response?.message, { type: "error" });
      }
    } catch (error) {
      toast(error.toString(), { type: "error" });
    }
  }
  async function getEmailList(id) {
    try {
      const data = {
        group_id: id,
      };

      const response = await getApiData(
        BaseSetting.endpoint.getNewsLetterEmails,
        "post",
        data
      );
      if (response?.status) {
        if (!_.isEmpty(response?.data)) {
          const extractedEmails = response?.data?.emails?.map(
            (val) => val?.email
          );
          setEmailsList(extractedEmails);
        }
      } else {
        // toast(response?.message, { type: "error" });
      }
    } catch (error) {
      toast(error.toString(), { type: "error" });
    }
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Send Emails"
        children={
          <>
            <Grid container mt={"30px"}>
              <CSelect
                placeholder={"Select group"}
                fullObj
                style={{ width: "100%" }}
                data={groupList}
                value={selectedGroupId}
                onSelect={(val) => {
                  allErrorFalse();
                  setSelectedGroupId(val);
                  getEmailList(val?.id);
                }}
                errorMsg={groupErrorText}
              />
            </Grid>
            <Grid container mt={"30px"}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Email for
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={radioValue}
                  onChange={(e) => {
                    setRadioValue(e.target.value);
                    if (e.target.value === "all") {
                      setEmailSend([]);
                    }
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="specific"
                    control={<Radio />}
                    label="Specific"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container mt={"30px"} item xs={12} md={12} lg={12}>
              {radioValue === "specific" ? (
                <Grid mb={"30px"} item xs={12} md={12} lg={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={!isEmpty(emailsList) ? emailsList : []}
                    value={EmailSend}
                    freeSolo
                    onChange={handleInputChange}
                    renderTags={(value) =>
                      EmailSend.map((option, index) => (
                        <Grid>
                          <Chip
                            sx={{ mr: 1, backgroundColor: "#D9D9D9" }}
                            variant="outlined"
                            label={option}
                            onDelete={handleChipDelete(option)}
                            deleteIcon={<CloseIcon sx={{ color: "red" }} />}
                          />
                        </Grid>
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Enter Email"
                      />
                    )}
                  />
                  {radioValue === "specific" ? (
                    <p style={{ color: "red", margin: 0 }}>
                      {templateNameErrorText}
                    </p>
                  ) : null}
                </Grid>
              ) : null}

              <Grid item xs={12} md={12} lg={12}>
                <CInput
                  placeholder="Subject"
                  value={subject}
                  onChange={(val) => {
                    setSubject(val);
                    setSubjectErrorText("");
                  }}
                  errorMsg={subjectErrorText}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  style={{
                    margin: "30px 0px 5px 10px",
                    fontFamily: FontFamily.SemiBold,
                    fontSize: 15,
                  }}
                >
                  Content
                </Typography>
                <Editor
                  apiKey={BaseSetting?.tinymceEditorApiKey}
                  onInit={(evt, editor) => (editorRefApp.current = editor)}
                  plugins={"image code"}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount image code",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent image | " +
                      "removeformat | image | code",
                    images_upload_url: `${
                      BaseSetting?.serverUrl +
                      BaseSetting?.endpoint?.imageUpload
                    }`,
                  }}
                  onEditorChange={(content, editor) => {}}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                    marginTop: "10px",
                  }}
                >
                  {contentErrorText}
                </Typography>
              </Grid>
              <Grid
                container
                // justifyContent={"space-evenly"}
                gap={2}
                wrap="nowrap"
              >
                <Grid item xs={4}>
                  <CButton
                    onClick={() => validation()}
                    fullWidth
                    variant="contained"
                    disabled={disableBtn}
                    loading={btnLoad}
                  >
                    Send
                  </CButton>
                </Grid>
                {/* <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      // allErrorFalse();
                    }}
                    disabled={disableBtn}
                  >
                    Cancel
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </>
        }
      />
    </Grid>
  );
};

export default SendEmails;
