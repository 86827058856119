import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import { Delete, Edit } from "@mui/icons-material";
import CButton from "../../Components/CButton";
import { createSearchParams, useSearchParams } from "react-router-dom";
import _, { isArray, isEmpty } from "lodash";

const IceBreakingQuestions = () => {
  const searchRef = useRef();

  // loader and modal
  const [pageLoad, setPageLoad] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  // CRUD
  const [questionsData, setQuestionsData] = useState([]);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");

  // validation
  const [questionErrorText, setQuestionErrorText] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  // sort and search
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  // get values from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  // set data to URL params
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  // validation
  const questionValidation = () => {
    if (title.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setQuestionErrorText("Question is required");
    } else {
      allErrorFalse();
      createAndUpdateQuestion(edit);
    }
  };

  const allErrorFalse = () => {
    setQuestionErrorText("");
  };

  //close modal
  const handleClose = () => {
    setModalOpen(false);
    setEdit(false);
    setTitle("");
  };

  // open edit modal
  const openEditModal = (data) => {
    setModalOpen(true);
    setEditId(data?.id);
    setTitle(data?.title);
  };

  // get questions list
  async function allQuestionsData(page, search = "", sort = "", sortBy = "") {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    getApiData(BaseSetting.endpoint.allIceBreakingQuestionsData, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setQuestionsData(response?.questions);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  }

  // create and update question
  const createAndUpdateQuestion = (isUpdate) => {
    setBtnLoad(true);
    setDisableBtn(true);
    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateIceBreakingQuestion
      : BaseSetting.endpoint.createIceBreakingQuestion;
    let data = {
      title: title,
    };
    if (isUpdate) {
      data.id = editId;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setModalOpen(false);
          allQuestionsData(pageNo);
          setTitle("");
          setDisableBtn(false);
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  //delete question
  const removeQuestion = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deleteIceBreakingQuestion}`;
    getApiData(endpoint, "POST", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirmationModal(false);
          allQuestionsData(pageNo);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  // change status
  const changeStatusQuestions = (id) => {
    const endpoint = `${BaseSetting.endpoint.changeStatusIceBreakingQuestions}/${id}`;
    getApiData(endpoint, "GET", {})
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          allQuestionsData(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };
  const openModal = (id) => {
    setDeleteId(id);
    setConfirmationModal(true);
  };

  useEffect(() => {
    setPageLoad(true);
    allQuestionsData(page, search, sortVal, sortByVal);
  }, []);

  const questionsColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        questionsData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      minWidth: 150,
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    openEditModal(params?.row);
                    setEdit(true);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button onClick={() => openModal(params?.row?.id)}>
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      flex: 4,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 2,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === "1" ? true : false}
            onChange={() => changeStatusQuestions(params?.row?.id)}
          />
        );
      },
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="Ice Breaking Questions"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Button variant="contained" onClick={() => setModalOpen(true)}>
                Create question
              </Button>
              <div style={{ display: "flex" }}>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);
                      setSearchVal("");
                      allQuestionsData(page);
                      setDelIds([]);
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{
                      color: "red",
                      borderColor: "red",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      setConfirmationModal(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      allQuestionsData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      allQuestionsData(page, search);
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={questionsData}
                  columns={questionsColumns}
                  disableColumnMenu
                  hideFooter={isEmpty(delIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  disableDensitySelector
                  checkboxSelection
                  rowSelectionModel={delIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setDelIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                  }}
                  showCellVerticalBorder={true}
                  disableRowSelectionOnClick
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          allQuestionsData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(questionsData) && isArray(questionsData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setDelIds([]);
                    allQuestionsData(value);
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    allQuestionsData(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
            <CModal
              visible={confirmationModal}
              style={{ width: "30%" }}
              onClose={() => {
                setConfirmationModal(false);
                setDeleteId("");
              }}
              title={"Delete ice breaking question"}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: FontFamily.Bold,
                          fontSize: 20,
                          textAlign: "center",
                        }}
                      >
                        Are you sure, You want to delete this data ?
                      </Typography>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setConfirmationModal(false);
                          setDeleteId("");
                        }}
                        style={{ width: "100%" }}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <CButton
                        variant="contained"
                        onClick={() => removeQuestion()}
                        style={{ width: "100%" }}
                        loading={yesBtnLoading}
                      >
                        Yes
                      </CButton>
                    </Grid>
                  </Grid>
                </div>
              }
            />

            <CModal
              visible={modalOpen}
              onClose={() => {
                handleClose();
                allErrorFalse();
                setTitle("");
                setEdit(false);
                setEditId("");
              }}
              title={
                edit
                  ? "Update Ice Breaking Question"
                  : "Create Ice Breaking Question"
              }
              style={{ width: "40%" }}
              children={
                <Grid container item xs={8} padding={"30px"} gap={2}>
                  <Grid container gap={2} wrap="nowrap">
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Title"
                        value={title}
                        onChange={(val) => {
                          setTitle(val);
                          allErrorFalse();
                        }}
                        errorMsg={questionErrorText}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent={"space-evenly"}
                    gap={2}
                    wrap="nowrap"
                  >
                    <Grid item xs={6}>
                      <CButton
                        onClick={() => {
                          questionValidation();
                        }}
                        fullWidth
                        variant="contained"
                        disabled={disableBtn}
                        loading={btnLoad}
                      >
                        {!edit ? "Create" : "Update"}
                      </CButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          handleClose();
                          allErrorFalse();
                          setTitle("");
                          setEdit(false);
                          setEditId("");
                        }}
                        disabled={disableBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </>
        }
      />
    </Grid>
  );
};

export default IceBreakingQuestions;
