import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { FontFamily } from "../../Config/theme";
import TranslateIcon from "@mui/icons-material/Translate";
import _ from "lodash";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

const Translate = () => {
  const searchRef = useRef();

  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [translateTemplateData, settranslateTemplateData] = useState([]);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVal, setSearchVal] = useState("");

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal);
  }, []);

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    setPageLoad(true);
    getAlltranslateTemplateApiCall(page, search, sortVal, sortByVal);
  }, []);
  //all translate template data
  const getAlltranslateTemplateApiCall = (page, search, sort, sortBy) => {
    const data = {
      page: page,
      type: "admin",
    };
    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    getApiData(BaseSetting.endpoint.getAllLanguageData, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            settranslateTemplateData(response?.languagesData);
            setPagination(response?.pagination);
          }
          // toast(result?.message, { type: "success" });
          setTimeout(() => {
            setPageLoad(false);
            setDataLoader(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  // change status
  const changeStatus = (id) => {
    const endpoint = BaseSetting.endpoint.changeLanguageStatus;
    getApiData(endpoint, "post", { id: id })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getAlltranslateTemplateApiCall(pageNo, search, sortVal, sortByVal);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const translateTemplateColumn = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        translateTemplateData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent={"center"}>
            <Tooltip title="Translate" placement="top" arrow>
              <Button
                onClick={() =>
                  navigate(
                    `/edit-translation/${params?.row?.language_id}?lang_name=${params?.row?.name_ascii}`
                  )
                }
              >
                <TranslateIcon style={{ fontSize: 20, color: "green" }} />
              </Button>
            </Tooltip>
          </Grid>
        );
      },
    },
    {
      field: "name_ascii",
      headerName: "NAME",
      minWidth: 200,
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === 1 ? true : false}
            onChange={() => changeStatus(params?.row?.id)}
          />
        );
      },
    },
  ];
  return (
    <Grid container>
      <MiniDrawer
        header="List of languages"
        children={
          <>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "70vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  rows={translateTemplateData}
                  columns={translateTemplateColumn}
                  disableColumnMenu
                  disableDensitySelector
                  hideFooter
                  sortingMode="server"
                  disableColumnFilter
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  loading={dataLoader}
                  disableRowSelectionOnClick
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    if (!_.isEmpty(model)) {
                      setDataLoader(true);
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                      getAlltranslateTemplateApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "");
                      getAlltranslateTemplateApiCall(page, search);
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  disableColumnSelector
                  onFilterModelChange={() => {
                    getAlltranslateTemplateApiCall(pageNo, search);
                    setDataLoader(true);
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAlltranslateTemplateApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(translateTemplateData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  defaultPage={1}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageNo(value);
                    setHeaderParams(value, search, sortVal, sortByVal);
                    getAlltranslateTemplateApiCall(
                      value,
                      search,
                      sortVal,
                      sortByVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
    </Grid>
  );
};

export default Translate;
