import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import CSelect from "../../Components/CSelect";
import { downloadOptions } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _, { isArray, isEmpty } from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";

const BusinessType = () => {
  const searchRef = useRef();

  //business data
  const [businessType, setBusinessType] = useState("");
  const [businessTypeData, setBusinessTypeData] = useState([]);

  // CRUD business data
  const [isEditModal, setIsEditModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [createBusinessTypeModal, setCreateBusinessTypeModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");

  //loader and pagination
  const [pageLoad, setPageLoad] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [dataLoader, setDataLoader] = useState(false);

  //business type validation and btn loader
  const [businessTypeErrorText, setBusinessTypeErrorText] = useState("");
  const [createBtnLoading, setCreateBtnLoading] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  //download data
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Name",
    "Status",
  ]);

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  //get values from URL params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  const [delIds, setDelIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params in URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    setPageLoad(true);
    allBusinessTypeData(page, search, sortVal, sortByVal);
  }, []);

  // validation
  const validation = () => {
    if (businessType.trim() === "") {
      setBusinessTypeErrorText("Business type is required");
    } else if (!/^[a-zA-Z\s]+$/.test(businessType)) {
      window.scrollTo(0, 0);
      // allErrorFalse();
      setBusinessTypeErrorText("Only characters are allowed");
    } else {
      allErrorFalse();
      createBusinessTypeApi(isEditModal);
    }
  };

  const allErrorFalse = () => {
    setBusinessTypeErrorText("");
  };

  // get businessType data api call
  const allBusinessTypeData = (page, search, sort, sortBy) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    const endpoint = BaseSetting.endpoint.allBusinessData;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setBusinessTypeData(response?.businessTypes);
          setPagination(response?.pagination);
          setTimeout(() => {
            setPageLoad(false);
            setDataLoader(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  // set data for edit
  const openModal = (data) => {
    setCreateBusinessTypeModal(true);
    setIsEditModal(true);
    setBusinessType(data?.row?.name);
    setEditId(data?.row?.id);
  };

  // create business type api call
  const createBusinessTypeApi = (isUpdate) => {
    setCreateBtnLoading(true);
    setDisableBtn(true);
    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateBusinessType
      : BaseSetting.endpoint.createBusinessType;

    let data = {
      name: businessType,
    };
    if (isUpdate) {
      data.id = editId;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          allBusinessTypeData(pageNo);
          setCreateBtnLoading(false);
          setIsEditModal(false);
          setCreateBusinessTypeModal(false);
          setBusinessType("");
          setEditId("");
          setDisableBtn(false);
        } else {
          toast(result?.message, { type: "error" });
          setCreateBtnLoading(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setCreateBtnLoading(false);
        setDisableBtn(false);
      });
  };

  //delete business type api call
  const deleteBusinessType = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deleteBusinessType}`;
    getApiData(endpoint, "POST", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirmationModal(false);
          allBusinessTypeData(page, search, sortVal, sortByVal);
          setDelIds([]);
          setDeleteId("");
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
        setDeleteId("");
        setDelIds([]);
      });
  };

  // download data in different formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("BussinessType-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "BussinessType.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "BussinessType.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data api call
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: delIds,
    };
    getApiData(BaseSetting.endpoint.allBusinessData, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              Name: val.name,
              Status: val.status === "1" ? "Active" : "Inactive",
            };
            dataSort.push(newObj);
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // change status
  const changeStatus = (id) => {
    const endpoint = `${BaseSetting.endpoint.changeStatusBusinessType}/${id}`;
    getApiData(endpoint, "GET", {})
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          allBusinessTypeData(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const businessColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        businessTypeData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button onClick={() => openModal(params)}>
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirmationModal(true);
                      setDeleteId(params?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === "1" ? true : false}
            onChange={() => changeStatus(params?.row?.id)}
          />
        );
      },
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="Business Type"
        children={
          <>
            <Grid container mb={"30px"} justifyContent={"space-between"}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsEditModal(false);
                  setCreateBusinessTypeModal(true);
                }}
              >
                Create business type
              </Button>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);

                      allBusinessTypeData(page);
                      setDataLoader(true);
                      setDelIds([]);
                      setSearchVal("");
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirmationModal(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </Grid>
            </Grid>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      downloadId
                    );
                    allBusinessTypeData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort
                    );
                  } else {
                    setHeaderParams(isPage, search);
                    allBusinessTypeData(page, search);
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={businessTypeData}
                columns={businessColumns}
                disableColumnMenu
                sortingMode="server"
                apiRef={getDataGridApi}
                hideFooter={isEmpty(delIds)}
                hideFooterPagination
                loading={dataLoader}
                disableDensitySelector
                checkboxSelection
                rowSelectionModel={delIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  setDelIds(id);
                  const downloadIdString = id.join(",");
                  if (rowChange === true) {
                    setHeaderParams(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      downloadIdString
                    );
                  }
                }}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableRowSelectionOnClick
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall": {
                    display: "none",
                    backgroundColor: "red",
                  },
                  "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                    display: "none",
                  },
                  "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "#674188",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                    {
                      width: "1px",
                      height: "4px",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                    {
                      background: "#F7EFE5",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "rgba(103, 65, 136, 0.5)",
                      borderRadius: "10px",
                    },
                }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        downloadId
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        allBusinessTypeData(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal
                        );
                      }, 800);
                    },
                  },
                }}
              />
            )}
            {!isEmpty(businessTypeData) && isArray(businessTypeData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    allBusinessTypeData(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}

            <CModal
              visible={confirmationModal}
              style={{ width: "30%" }}
              title="Delete Business type"
              onClose={() => {
                setConfirmationModal(false);
                setDeleteId("");
              }}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: FontFamily.Medium,
                          fontSize: 20,
                          textAlign: "center",
                        }}
                      >
                        Are you sure, You want to delete this data ?
                      </Typography>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setConfirmationModal(false);
                          setDeleteId("");
                        }}
                        style={{ width: "100%" }}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <CButton
                        variant="contained"
                        onClick={() => deleteBusinessType()}
                        style={{ width: "100%" }}
                        loading={yesBtnLoading}
                      >
                        Yes
                      </CButton>
                    </Grid>
                  </Grid>
                </div>
              }
            />

            <CModal
              visible={createBusinessTypeModal}
              style={{ width: "30%" }}
              onClose={() => {
                setCreateBusinessTypeModal(false);
                setBusinessType("");
                setBusinessTypeErrorText("");
              }}
              title={
                isEditModal ? "Update business type" : "Create business type"
              }
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Enter Business Type here"
                        onChange={(val) => {
                          setBusinessType(val);
                          setBusinessTypeErrorText("");
                        }}
                        value={businessType}
                        style={{ width: "100%" }}
                        errorMsg={businessTypeErrorText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CButton
                        variant="contained"
                        sx={{ width: "100%" }}
                        onClick={() => validation()}
                        disabled={disableBtn}
                        loading={createBtnLoading}
                      >
                        {isEditModal ? "Update" : "Create"} business type
                      </CButton>
                    </Grid>
                    <Grid item xs={6} paddingLeft={"10px"}>
                      <Button
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onClick={() => {
                          setCreateBusinessTypeModal(false);
                          setBusinessType("");
                          setBusinessTypeErrorText("");
                        }}
                        disabled={disableBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </>
        }
      />
    </Grid>
  );
};

export default BusinessType;
