import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";
import _ from "lodash";
import CButton from "../../Components/CButton";
import CModal from "../../Components/CModal";
import { Edit } from "@mui/icons-material";
import CInput from "../../Components/CInput";
import TagsInput from "../../Components/TagsInput";

const SEODetails = () => {
  const searchRef = useRef();

  //page loading
  const [pageLoad, setPageLoad] = useState(false);

  //pagination
  const [pageNo, setPageNo] = useState(1);

  const [searchVal, setSearchVal] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  //update  modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [updateBtnLoading, setUpdateBtnLoading] = useState(false);
  const [editId, setEditId] = useState("");

  // update data states
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState([]);
  const [metaKeywords, setMetaKeywords] = useState("");

  //SEO Data
  const [seoList, setSeoList] = useState([]);

  useEffect(() => {
    setPageLoad(true);
    getSEOData();
  }, []);

  //get seo data
  function getSEOData() {
    getApiData(BaseSetting.endpoint.getSEOList, "get", {})
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!_.isEmpty(response)) {
            setSeoList(response);
          }
          setTimeout(() => {
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  const openModal = (data) => {
    setOpenEditModal(true);
    setEditId(data?.id);
    setTitle(data?.title);
    setDescription(data?.description);
    setTag(
      !_.isEmpty(data?.keyword) &&
        !_.isUndefined(data?.keyword) &&
        !_.isNull(data?.keyword)
        ? data?.keyword?.split(",")
        : []
    );
  };

  // update SEO data
  const updateSEOData = () => {
    setDisableBtn(true);
    setUpdateBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.updateSEOList}/${editId}`;
    let data = {
      title: title,
      description: description,
      keyword: tag,
    };
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getSEOData();
          setTitle("");
          setTag([]);
          setDescription("");
          setDescription("");
          setOpenEditModal(false);
          setDisableBtn(false);
          setUpdateBtnLoading(false);
        } else {
          toast(result?.message, { type: "error" });
          setDisableBtn(false);
          setUpdateBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDisableBtn(false);
        setUpdateBtnLoading(false);
      });
  };

  const tableColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * 16 + 1;
        seoList.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "page_name",
      headerName: "PAGE NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "META TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "META DESCRIPTION",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "keyword",
      headerName: "META KEYWORDS",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            <Button
              onClick={() => {
                openModal(params.row);
              }}
            >
              <Edit style={{ fontSize: 20 }} />
            </Button>
          </div>
        );
      },
    },
  ];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && metaKeywords.trim() !== "") {
      setTag([...tag, metaKeywords.trim()]);
      setMetaKeywords("");
    }
  };

  const removeTag = (index) => {
    setTag(tag.filter((el, i) => i !== index));
  };

  return (
    <Grid container>
      <MiniDrawer
        header="SEO Details"
        children={
          <>
            <Grid container>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  rows={seoList}
                  columns={tableColumns}
                  disableColumnMenu
                  hideFooter
                  loading={dataLoader}
                  disableDensitySelector
                  apiRef={getDataGridApi}
                  sortingMode="server"
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  disableColumnFilter
                  disableColumnSelector
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());

                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {}, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
          </>
        }
      />

      <CModal
        visible={openEditModal}
        title={"Update SEO Details"}
        onClose={() => {
          setOpenEditModal(false);
        }}
        style={{ width: "40%" }}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter title here"
                  onChange={(val) => {
                    setTitle(val);
                  }}
                  value={title}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter description here"
                  onChange={(val) => {
                    setDescription(val);
                  }}
                  multiline
                  rows={6}
                  maxRows={6}
                  value={description}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TagsInput
                  onKeyDown={handleInputKeyPress}
                  value={metaKeywords}
                  onChange={(val) => {
                    setMetaKeywords(val);
                  }}
                  tagList={tag}
                  removeTag={(index) => {
                    removeTag(index);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CButton
                  variant="contained"
                  onClick={() => {
                    updateSEOData();
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                  loading={updateBtnLoading}
                >
                  Update data
                </CButton>
              </Grid>
              <Grid item xs={6} paddingLeft={"10px"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenEditModal(false);
                    setTitle("");
                    setDescription("");
                    setMetaKeywords("");
                    setTag([]);
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />
    </Grid>
  );
};

export default SEODetails;
