const actions = {
  SET_ACCESS_TOKEN: "auth/SET_ACCESS_TOKEN",
  SET_USER_DATA: "auth/SET_USER_DATA",
  SET_UNREAD_NOTIFICATIONS: "auth/SET_UNREAD_NOTIFICATIONS",

  setAccessToken: (token) => (dispatch) =>
    dispatch({
      type: actions.SET_ACCESS_TOKEN,
      accessToken: token,
    }),
  setUserData: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userData: data,
    }),

  setNotificationData: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_UNREAD_NOTIFICATIONS,
      notificationData: data,
    }),
};
export default actions;
