import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import theme, { FontFamily } from "../../Config/theme";
import CModal from "../../Components/CModal";
import CButton from "../../Components/CButton";
import _, { isArray, isEmpty, isNull } from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//Date Filter
import CInput from "../../Components/CInput";
import CAutoComplete from "../../Components/CAutoComplete/CAutoComplete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import CSelect from "../../Components/CSelect";

export default function SendNotifications() {
  const searchRef = useRef();

  // notification data
  const [notificationData, setNotificationData] = useState([]);

  // pagination and loader
  const [pageLoad, setPageLoad] = useState(true);
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [dataLoader, setDataLoader] = useState(false);
  const [rowChange, setRowChange] = useState(false);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  //date filter
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateModel, setDateModel] = useState(false);

  // get common data state
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  // create notifictaion state
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categorySelect, setCategorySelect] = useState("");
  const [subCategorySelect, setSubCategorySelect] = useState("");
  const [conditionSelect, setConditionSelect] = useState("");
  const [countrySelect, setCountrySelect] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // btn loader
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);

  // validation state
  const [titleErrorText, setTitleErrorText] = useState("");
  const [descriptionErrorText, setDescriptionErrorText] = useState("");
  const [categorySelectErrorText, setCategorySelectErrorText] = useState("");
  const [subCategorySelectErrorText, setSubCategorySelectErrorText] =
    useState("");
  const [conditionSelectErrorText, setConditionSelectErrorText] = useState("");
  const [countrySelectErrorText, setCountrySelectErrorText] = useState("");
  const [selectedCategoriesErrorText, setSelectedCategoriesErrorText] =
    useState("");
  const [selectedSubCategoriesErrorText, setSelectedSubCategoriesErrorText] =
    useState("");
  const [selectedConditionErrorText, setSelectedConditionErrorText] =
    useState("");
  const [selectedCountryErrorText, setSelectedCountryErrorText] = useState("");

  //download sort and search
  const [selectFileType, setSelectFileType] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";

  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [selIds, setselIds] = useState(downloadIdArr);
  const [createNotificationModal, setCreateNotificationModal] = useState(false);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "User name",
    "Message",
    "Is read?",
    "Message type",
    "Notify",
    "Post id",
    "Post name",
    "Title",
    "Status",
    "Type",
  ]);

  const validation = () => {
    if (title.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setTitleErrorText("Title is required");
    } else if (description.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setDescriptionErrorText("Description is required");
    } else if (categorySelect.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategorySelectErrorText("Selection is required");
    } else if (categorySelect === "0" && isEmpty(selectedCategories)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSelectedCategoriesErrorText("Select the categories");
    } else if (subCategorySelect.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSubCategorySelectErrorText("Selection is required");
    } else if (subCategorySelect === "0" && isEmpty(selectedSubCategories)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSelectedSubCategoriesErrorText("Select the sub-categories");
    } else if (conditionSelect.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setConditionSelectErrorText("Selection is required");
    } else if (conditionSelect === "0" && isEmpty(selectedConditions)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSelectedConditionErrorText("Select the conditions");
    } else if (countrySelect.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCountrySelectErrorText("Selection is required");
    } else if (countrySelect === "0" && isEmpty(countrySelect)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setSelectedCountryErrorText("Select the countries");
    } else {
      allErrorFalse();
      createNotification();
    }
  };

  const allErrorFalse = () => {
    setTitleErrorText("");
    setDescriptionErrorText("");
    setCategorySelectErrorText("");
    setSubCategorySelectErrorText("");
    setConditionSelectErrorText("");
    setCountrySelectErrorText("");
    setSelectedCategoriesErrorText("");
    setSelectedSubCategoriesErrorText("");
    setSelectedConditionErrorText("");
    setSelectedCountryErrorText("");
  };

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    getNotificationData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal
    );
    GetCommonDataApi();
  }, []);

  // common data api
  const GetCommonDataApi = (list, bool = false) => {
    const endpoint = BaseSetting.endpoint.commonData;

    let data = {};

    if (!_.isEmpty(list) && _.isArray(list)) {
      let parentsIds = [];
      list.map((data) => {
        parentsIds.push(data?.id);
      });
      data.parent_ids = parentsIds;
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const tempSubCategoryList = result?.data?.subCategories;
          const categoryList = result?.data?.categories;
          const conditionList = result?.data?.conditions;
          const countryList = result?.data?.countries;

          if (bool === false) {
            setCategoryList(categoryList);
            setConditionList(conditionList);
            setCountryList(countryList);
          } else {
            if (!_.isEmpty(tempSubCategoryList)) {
              tempSubCategoryList.map((item) => {
                selectedSubCategories.map((item1) => {
                  if (item1.selected && item.id === item1.id) {
                    item.selected = true;
                  }
                });
              });
            }
            setSubCategoryList([...tempSubCategoryList]);
            const selectedSubCat = tempSubCategoryList.filter(
              (v) => v.selected === true
            );
            setSelectedSubCategories(selectedSubCat);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // get subcategory list
  const getSubCategoryList = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;

    getApiData(endpoint, "post", { type: "web" })
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (!_.isEmpty(data) && _.isArray(data)) {
            setSubCategoryList(data);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  //get notification data
  const getNotificationData = (
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date
  ) => {
    const data = {
      page: page,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }

    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }

    const endpoint = `${BaseSetting.endpoint.getNotificationData}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setNotificationData(response?.notifications);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  // date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  const notificationColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        notificationData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "first_name",
      headerName: "USERNAME",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.first_name} {params?.row?.last_name}
          </span>
        );
      },
    },
    {
      field: "message",
      headerName: "MESSAGE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_read",
      headerName: "IS READ ?",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "msg_type",
      headerName: "MESSAGE TYPE",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.msg_type === "promotion"
              ? "Promotion"
              : params?.row?.msg_type === "likePost"
              ? "Like post"
              : params?.row?.msg_type === "followUser"
              ? "Follow user"
              : params?.row?.msg_type === "verifyPage"
              ? "Verify page"
              : params?.row?.msg_type === "rejectUserVerify"
              ? "Reject verify user"
              : params?.row?.msg_type === "verifiedUser"
              ? "Verified user"
              : params?.row?.msg_type === "resetPassword "
              ? "Reset password"
              : params?.row?.msg_type === "accountCreate"
              ? "Account create"
              : params?.row?.msg_type === "postCreated"
              ? "Post created"
              : params?.row?.msg_type === "postApproved"
              ? "Post approved"
              : params?.row?.msg_type === "postReject"
              ? "Post reject"
              : params?.row?.msg_type === "expirePost"
              ? "Expire post"
              : params?.row?.msg_type === "alertMessage"
              ? "Alert message"
              : ""}
          </span>
        );
      },
    },
    {
      field: "notify",
      headerName: "NOTIFY",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.notify === "notSent"
              ? "Not sent"
              : params?.row?.notify === "Yes"
              ? "Yes"
              : params?.row?.notify === "No"
              ? "No"
              : ""}
          </span>
        );
      },
    },
    {
      field: "post_id",
      headerName: "POST ID",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "POST NAME",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "TITLE",
      width: 180,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "STATUS",
      width: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.status === "in_review" ? (
          <span>In Review</span>
        ) : params?.row?.status === "posted" ? (
          <span>Posted</span>
        ) : (
          ""
        );
      },
    },
  ];

  // create notifications
  function createNotification() {
    setSaveBtnLoading(true);
    setSaveBtnDisable(true);
    const endpoint = BaseSetting.endpoint.createNotification;

    let data = {
      description: description,
      name: title,
      category_type: categorySelect === "0" ? "specific" : "all",
      sub_category_type: subCategorySelect === "0" ? "specific" : "all",
      condition_type: conditionSelect === "0" ? "specific" : "all",
      country_type: countrySelect === "0" ? "specific" : "all",
    };

    if (categorySelect === "0") {
      data.category_data = !_.isEmpty(selectedCategories)
        ? selectedCategories?.map((v) => v?.id)
        : [];
    }
    if (subCategorySelect === "0") {
      data.sub_category_data = !_.isEmpty(selectedSubCategories)
        ? selectedSubCategories?.map((v) => v?.id)
        : [];
    }
    if (conditionSelect === "0") {
      data.condition_data = !_.isEmpty(selectedConditions)
        ? selectedConditions?.map((v) => v?.id)
        : [];
    }
    if (countrySelect === "0") {
      data.country_data = !_.isEmpty(selectedCountries)
        ? selectedCountries?.map((v) => v?.name)
        : [];
    }

    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setSaveBtnLoading(false);
          setSaveBtnDisable(false);
          setCreateNotificationModal(false);
          setCategorySelect("");
          setSubCategorySelect("");
          setConditionSelect("");
          setCountrySelect("");
          setSelectedCategories([]);
          setSelectedSubCategories([]);
          setSelectedConditions([]);
          setSelectedCountries([]);
          setTitle("");
          setDescription("");
          setSubCategoryList([]);
          getNotificationData();
        } else {
          toast(result?.message, { type: "error" });
          setSaveBtnLoading(false);
          setSaveBtnDisable(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setSaveBtnLoading(false);
        setSaveBtnDisable(false);
      });
  }

  // download data in different format
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      format: "a4",
      orientation: "landscape",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save(`Notification-Data.pdf`);
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Notification-Data.csv`;
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Notification-Data.xlsx`);
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    setDataLoader(true);
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: selIds,
    };

    if (!_.isEmpty(startDateVal)) {
      data.start_date = startDateVal;
    }

    if (!_.isEmpty(endDateVal)) {
      data.end_date = endDateVal;
    }
    getApiData(BaseSetting.endpoint.getNotificationData, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.notifications;
          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              "Full name": val?.first_name + " " + val?.last_name,
              Message: val?.message,
              "Is read?": val?.is_read,
              "Message type": val?.msg_type,
              Notify: val?.notify,
              "Post id": val?.post_id,
              "Post name": val?.name,
              Title: val?.title,
              Status:
                val?.status === "in_review"
                  ? "In review"
                  : val?.status === "posted"
                  ? "Posted"
                  : "",
              Type: val?.type,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
          setDataLoader(false);
        } else {
          toast(result?.message, { type: "error" });
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
      });
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Send Notification"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setCreateNotificationModal(true);
                  }}
                >
                  Create notification
                </Button>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setDateModel(true);
                  }}
                  style={{ marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "", []);
                      getNotificationData(page);
                      setselIds([]);
                      setSearchVal("");
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                  style={{ marginLeft: "15px" }}
                />
              </Grid>
            </Grid>

            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getNotificationData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal
                      );
                    } else {
                      setHeaderParams(
                        isPage,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getNotificationData(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={notificationData}
                  columns={notificationColumns}
                  disableColumnMenu
                  disableDensitySelector
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  disableRowSelectionOnClick
                  checkboxSelection
                  rowSelectionModel={selIds}
                  keepNonExistentRowsSelected
                  onRowSelectionModelChange={(id) => {
                    setselIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDate,
                        endDate,
                        downloadIdString
                      );
                    }
                  }}
                  hideFooter={isEmpty(selIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getNotificationData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDateVal,
                            endDateVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(notificationData) && isArray(notificationData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getNotificationData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={createNotificationModal}
        style={{ width: lg ? "50%" : sm ? "80%" : "35%" }}
        onClose={() => {
          setCreateNotificationModal(false);
          setTitle("");
          setDescription("");
          setCategorySelect("");
          setSubCategorySelect("");
          setConditionSelect("");
          setCountrySelect("");
          setSelectedCategories([]);
          setSelectedSubCategories([]);
          setSelectedConditions([]);
          setSelectedCountries([]);
          setSubCategoryList([]);
          allErrorFalse();
        }}
        title={"Create Notification"}
        children={
          <Grid container padding={"30px"} gap={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CInput
                placeholder="Title"
                value={title}
                onChange={(val) => {
                  setTitle(val);
                  setTitleErrorText("");
                }}
                errorMsg={titleErrorText}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CInput
                placeholder="Description"
                value={description}
                onChange={(val) => {
                  setDescription(val);
                  setDescriptionErrorText("");
                }}
                multiline
                rows={6}
                maxRows={6}
                errorMsg={descriptionErrorText}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.blackColor,
                  fontFamily: FontFamily.Medium,
                }}
              >
                Category
              </Typography>
              <RadioGroup
                row
                value={categorySelect}
                onChange={(e) => {
                  setCategorySelect(e.target.value);
                  if (e.target.value === "1") {
                    getSubCategoryList();
                  }
                  allErrorFalse();
                  if (categorySelect === "1") {
                    setSelectedCategories([]);
                  }
                }}
              >
                <FormControlLabel control={<Radio />} value={1} label="All" />
                <FormControlLabel
                  control={<Radio />}
                  value={0}
                  label="Specific"
                />
              </RadioGroup>
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.errorRed,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {categorySelectErrorText}
              </Typography>
            </Grid>
            {categorySelect === "0" && (
              <Grid item xs={12}>
                <CAutoComplete
                  multiple
                  options={categoryList}
                  label="Category"
                  selectedValue={selectedCategories}
                  onSelect={(val) => {
                    allErrorFalse();
                    setSelectedCategories(val);
                    GetCommonDataApi(val, true);
                  }}
                  errorMsg={selectedCategoriesErrorText}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.blackColor,
                  fontFamily: FontFamily.Medium,
                }}
              >
                Sub Category
              </Typography>
              <RadioGroup
                row
                value={subCategorySelect}
                onChange={(e) => {
                  setSubCategorySelect(e.target.value);
                  allErrorFalse();
                  if (subCategorySelect === "1") {
                    setSelectedSubCategories([]);
                  }
                }}
              >
                <FormControlLabel control={<Radio />} value={1} label="All" />
                <FormControlLabel
                  control={<Radio />}
                  value={0}
                  label="Specific"
                />
              </RadioGroup>
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.errorRed,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {subCategorySelectErrorText}
              </Typography>
            </Grid>
            {subCategorySelect === "0" && (
              <Grid item xs={12}>
                <CAutoComplete
                  multiple
                  subCategory
                  options={subCategoryList}
                  label="Sub-category"
                  selectedValue={selectedSubCategories}
                  onSelect={(val, bool = false) => {
                    allErrorFalse();
                    setSelectedSubCategories(val);
                  }}
                  errorMsg={selectedSubCategoriesErrorText}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.blackColor,
                  fontFamily: FontFamily.Medium,
                }}
              >
                Condition
              </Typography>
              <RadioGroup
                row
                value={conditionSelect}
                onChange={(e) => {
                  setConditionSelect(e.target.value);
                  allErrorFalse();
                  if (conditionSelect === "1") {
                    setSelectedConditions([]);
                  }
                }}
              >
                <FormControlLabel control={<Radio />} value={1} label="All" />
                <FormControlLabel
                  control={<Radio />}
                  value={0}
                  label="Specific"
                />
              </RadioGroup>
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.errorRed,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {conditionSelectErrorText}
              </Typography>
            </Grid>
            {conditionSelect === "0" && (
              <Grid item xs={12}>
                <CAutoComplete
                  multiple
                  options={conditionList}
                  label="Conditions"
                  selectedValue={selectedConditions}
                  onSelect={(val) => {
                    setSelectedConditions(val);
                    allErrorFalse();
                  }}
                  errorMsg={selectedConditionErrorText}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.blackColor,
                  fontFamily: FontFamily.Medium,
                }}
              >
                Country
              </Typography>
              <RadioGroup
                row
                value={countrySelect}
                onChange={(e) => {
                  setCountrySelect(e.target.value);
                  allErrorFalse();
                  if (countrySelect === "1") {
                    setSelectedCountries([]);
                  }
                }}
              >
                <FormControlLabel control={<Radio />} value={1} label="All" />
                <FormControlLabel
                  control={<Radio />}
                  value={0}
                  label="Specific"
                />
              </RadioGroup>
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.errorRed,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {countrySelectErrorText}
              </Typography>
            </Grid>
            {countrySelect === "0" && (
              <Grid item xs={12}>
                <CAutoComplete
                  options={countryList}
                  multiple
                  label="Countries"
                  selectedValue={selectedCountries}
                  onSelect={(val) => {
                    allErrorFalse();
                    setSelectedCountries(val);
                  }}
                  errorMsg={selectedCountryErrorText}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CButton
                variant="contained"
                onClick={() => {
                  validation();
                }}
                style={{ width: "100%" }}
                loading={saveBtnLoading}
                disabled={saveBtnDisable}
              >
                Save
              </CButton>
            </Grid>
          </Grid>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  getNotificationData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
}
