import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import CButton from "../../Components/CButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import styles from "./styles";
import CInput from "../../Components/CInput";
import { BiUser } from "react-icons/bi";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import _ from "lodash";

const ChangePassword = () => {
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const classes = styles();

  //form state
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");

  //Error messages
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const [reNewPasswordErrorText, setReNewPasswordErrorText] = useState("");

  //btn loader
  const [submitBtnLoader,setSubmitBtnLoader]=useState(false);
  const [disableBtn,setDisableBtn]=useState(false)

  return (
    <Grid container>
      <MiniDrawer
        header={"Change Password"}
        children={
          <>
            <Grid container>
              <Button
                variant="contained"
                onClick={() => navigate("/verify-users")}
              >
                <ArrowBackIcon /> Back
              </Button>
            </Grid>

            <div style={{ backgroundColor: BaseColor.whiteColor }}>
              <Grid
                container
                marginBottom={"30px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={11} lg={12}>
                  <Grid
                    item
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    mt={5}
                    gap={4}
                    wrap={md ? "wrap" : "nowrap"}
                  >
                    <Grid item xs={12} md={12}>
                      <Grid item container>
                        <Grid item container xs={12}>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            mt={2}
                          >
                            <Grid item xs={10}>
                              <span className={classes.header}>
                                Change password
                              </span>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              backgroundColor: BaseColor.offWhite,
                              padding: "15px",
                              marginTop: "20px",
                            }}
                            gap={2}
                          >
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Current password"
                                readOnly={false}
                                value={currentPassword}
                                onChange={(val) => setCurrentPassword(val)}
                                errorMsg={currentPasswordErrorText}
                                startIcon={
                                  <BiUser className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Enter new password"
                                readOnly={false}
                                value={newPassword}
                                errorMsg={newPasswordErrorText}
                                onChange={(val) => setNewPassword(val)}
                                startIcon={
                                  <BiUser className="CInputStartIcon" />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CInput
                                placeholder="Re enter new password"
                                readOnly={false}
                                errorMsg={reNewPasswordErrorText}
                                value={reNewPassword}
                                onChange={(val) => setReNewPassword(val)}
                                startIcon={<CIcon src={Files.svgIcons.email} />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.8}>
                              <CButton
                                onClick={()=>{}}
                                fullWidth
                                variant="contained"
                                disabled={disableBtn}
                                loading={submitBtnLoader}
                              >
                                Submit
                              </CButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        }
      />
    </Grid>
  );
};

export default ChangePassword;
