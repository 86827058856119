import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CModal from "../../Components/CModal";
import { Editor } from "@tinymce/tinymce-react";
import CButton from "../../Components/CButton";

const Cities = () => {
  const searchRef = useRef();
  const navigate = useNavigate();
  const editorRefApp = useRef();

  //page loading
  const [pageLoad, setPageLoad] = useState(false);

  //pagination
  const [pageNo, setPageNo] = useState(1);

  const [searchVal, setSearchVal] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const countryName = searchParams.has("country")
    ? searchParams.get("country")
    : "";
  const stateName = searchParams.has("state") ? searchParams.get("state") : "";

  useEffect(() => {
    setPageLoad(true);
    getCountryStateCityListByPincode(countryName, stateName);
  }, []);

  //city data
  const [cityList, setCityList] = useState([]);

  // description modal
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [description, setDescription] = useState("");
  const [createBtnLoading, setCreateBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  // get city list api
  function getCountryStateCityListByPincode(country, stateName) {
    setPageLoad(true);
    const endpoint = `${BaseSetting.endpoint.countryStateCityListByPincode}`;
    getApiData(endpoint, "post", { country: country })
      .then((result) => {
        if (result?.status) {
          const resultData = result?.data?.states;
          const cityName = resultData?.find((v) => v?.name === stateName);
          let cityData = [];
          cityName?.cities?.map((data) => {
            cityData?.push({
              id: data?.id,
              name: data?.name,
            });
          });
          setCityList(cityData);
          setPageLoad(false);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  const tableColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * 16 + 1;
        cityList.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },

    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "actions",
    //   headerName: "ACTIONS",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Button
    //           variant="contained"
    //           onClick={() => {
    //             setOpenDescriptionModal(true);
    //           }}
    //         >
    //           Add description
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  document.addEventListener("focusin", (e) => {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });

  return (
    <Grid container>
      <MiniDrawer
        header="Cities"
        children={
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/states?country=${countryName}`);
                  }}
                >
                  <ArrowBackIcon /> Back
                </Button>
                <div
                  style={{
                    border: `2px solid ${BaseColor.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px",
                    borderRadius: "10px",
                    marginLeft: "30px",
                  }}
                >
                  state name: {stateName}
                </div>
              </Grid>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  rows={cityList}
                  columns={tableColumns}
                  disableColumnMenu
                  disableDensitySelector
                  apiRef={getDataGridApi}
                  sortingMode="client"
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  disableColumnFilter
                  disableColumnSelector
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setSearchVal(e.target.value?.toLowerCase());

                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {}, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
          </>
        }
      />
      <CModal
        visible={openDescriptionModal}
        title={isEditModal ? "Update description" : "Create description"}
        onClose={() => {
          setOpenDescriptionModal(false);
        }}
        style={{ width: "50%" }}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              {/* <Grid item xs={12}>
                <span style={{ fontFamily: FontFamily.Regular }}>
                  Description
                </span>
              </Grid> */}
              <Grid item xs={12}>
                <Editor
                  apiKey={BaseSetting?.tinymceEditorApiKey}
                  onInit={(evt, editor) => (editorRefApp.current = editor)}
                  plugins={"image code"}
                  value={description}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount image code",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent image | " +
                      "removeformat | image | code",
                    images_upload_url: `${
                      BaseSetting?.serverUrl +
                      BaseSetting?.endpoint?.imageUpload
                    }`,
                  }}
                  onEditorChange={(content, editor) => {
                    setDescription(content);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CButton
                  variant="contained"
                  // onClick={() => name.length !== 0 && createAndUpdateCategoryApi()}
                  onClick={() => {
                    // createCategoryValidation(isEditModal);
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                  loading={createBtnLoading}
                >
                  {isEditModal ? "Update" : "Create"} description
                </CButton>
              </Grid>
              <Grid item xs={6} paddingLeft={"10px"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenDescriptionModal(false);
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />
    </Grid>
  );
};

export default Cities;
