import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import CModal from "../../Components/CModal";
import theme, { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import _ from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";

const SMSTemplate = () => {
  const searchRef = useRef();

  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // sms data
  const [smsTemplateData, setsmsTemplateData] = useState([]);

  //loader and pagination
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  //create and update sms template
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState();
  const [showData, setShowData] = useState("");
  const [disableNameInput, setDisableNameInput] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  //sort and search
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);

  //sms count
  const [smsCountData, setSmsCountData] = useState([]);

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  //set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  //create update state
  const [templateName, setTemplateName] = useState("");
  const [smsDesc, setSmsDesc] = useState("");
  const [whatsappDesc, setWhatsappDesc] = useState("");
  const [editData, setEditData] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  //validation
  const [templateNameErrorText, setTemplateNameErrorText] = useState("");
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setPageLoad(true);
    getAllSmsTemplateApiCall(page, search, sortVal, sortByVal);
  }, []);

  //all sms template data api
  const getAllSmsTemplateApiCall = (page, search, sort, sortBy) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    getApiData(BaseSetting.endpoint.smsTemplateList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setsmsTemplateData(response?.templates);
          setPagination(response?.pagination);
          setSmsCountData(response?.sms_count);

          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  // change status
  const changeStatus = (id) => {
    const endpoint = BaseSetting.endpoint.changeSmsTemplateStatus;
    getApiData(endpoint, "post", { id: id })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getAllSmsTemplateApiCall(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const allErrorFalse = () => {
    setTemplateNameErrorText("");
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsEdit(false);
    setDisableNameInput(false);
    setEditData("");
    allErrorFalse();
    setTemplateName("");
    setSmsDesc("");
    setWhatsappDesc("");
  };

  const validation = (type) => {
    if (templateName.trim() === "") {
      allErrorFalse();
      setTemplateNameErrorText("Template name is required");
    } else {
      allErrorFalse();
      createAndUpdateSmsTemplate(type === "update");
    }
  };

  // create and update api call
  const createAndUpdateSmsTemplate = (isUpdate) => {
    setBtnLoad(true);
    setDisableBtn(true);

    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateSmsTemplate
      : BaseSetting.endpoint.createSmsTemplate;
    let data = {
      name: templateName,
      sms_desc: smsDesc,
      whatsapp_desc: whatsappDesc,
    };

    if (isUpdate) {
      data.id = editData?.id;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setDisableBtn(false);
          handleClose();
          getAllSmsTemplateApiCall(pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  //delete template api
  const deleteTemplate = () => {
    setYesBtnLoading(true);
    const endpoint = BaseSetting.endpoint.deleteSmsTemplate;
    getApiData(endpoint, "post", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDelIds([]);
          setDeleteId("");
          getAllSmsTemplateApiCall(pageNo, true);
          const newIndex = smsTemplateData.filter((data) => data.id !== delIds);
          setsmsTemplateData(newIndex);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const openTheModal = (data) => {
    setIsEdit(true);
    setDisableNameInput(true);
    setEditData(data);
    setOpenModal(true);
    setSmsDesc(data?.sms_desc);
    setWhatsappDesc(data?.whatsapp_desc);
    setTemplateName(data?.name);
  };

  const smsTemplateColumn = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        smsTemplateData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    openTheModal(params?.row);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirm(true);
                      setDeleteId(params?.row?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "TEMPLATE NAME",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sms_desc",
      headerName: "SMS DESC.",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "whatsapp_desc",
      headerName: "WHATSAPP DESC.",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: params?.row?.whatsapp_desc?.slice(0, 150),
                }}
              />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                  // display:"inline-block"
                }}
                onClick={() => {
                  setShowData(params?.row?.whatsapp_desc);
                  setViewMoreModal(true);
                }}
              >
                {params?.row?.whatsapp_desc?.length <= 150
                  ? ""
                  : "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "slug",
      headerName: "SLUG",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === "active" ? true : false}
            onChange={() => changeStatus(params?.row?.id)}
          />
        );
      },
    },
  ];
  return (
    <Grid container>
      <MiniDrawer
        header="SMS Template"
        children={
          <>
            <Grid justifyContent={"space-between"} container>
              <Button variant="contained" onClick={() => setOpenModal(true)}>
                Create SMS template
              </Button>

              <div>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);
                      setSearchVal("");
                      getAllSmsTemplateApiCall(page);
                      setDelIds([]);
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid
              container
              marginTop={"20px"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                sm={5.9}
                md={5.9}
                lg={2.9}
                xl={2.9}
                style={{ paddingBottom: lg && "15px" }}
              >
                <div
                  style={{
                    border: `2px solid ${BaseColor.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px",
                    borderRadius: "10px",
                  }}
                >
                  Sent SMS by today:{smsCountData?.today}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5.9}
                lg={2.9}
                xl={2.9}
                style={{ paddingBottom: lg && "15px" }}
              >
                <div
                  style={{
                    border: `2px solid ${BaseColor.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px",
                    borderRadius: "10px",
                  }}
                >
                  Sent SMS by yesterday:{smsCountData?.yesterday}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5.9}
                md={5.9}
                lg={2.9}
                xl={2.9}
                style={{ paddingBottom: lg && "15px" }}
              >
                <div
                  style={{
                    border: `2px solid ${BaseColor.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px",
                    borderRadius: "10px",
                  }}
                >
                  Sent SMS by last week:{smsCountData?.last_week}
                </div>
              </Grid>
              <Grid item xs={12} sm={5.9} md={5.9} lg={2.9} xl={2.9}>
                <div
                  style={{
                    border: `2px solid ${BaseColor.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px",
                    borderRadius: "10px",
                  }}
                >
                  Sent SMS by last month:{smsCountData?.last_month}
                </div>
              </Grid>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      getAllSmsTemplateApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      getAllSmsTemplateApiCall(page, search);
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={smsTemplateData}
                  columns={smsTemplateColumn}
                  disableColumnMenu
                  disableDensitySelector
                  hideFooter={isEmpty(delIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  checkboxSelection
                  rowSelectionModel={delIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setDelIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                    getAllSmsTemplateApiCall(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      downloadIdString
                    );
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAllSmsTemplateApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(smsTemplateData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  defaultPage={1}
                  siblingCount={2}
                  onChange={(e, value) => {
                    getAllSmsTemplateApiCall(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    setPageLoad(true);
                    setPageNo(value);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={openModal}
        onClose={() => {
          handleClose();
          allErrorFalse();
        }}
        title={isEdit ? "Update SMS Template" : "Create SMS Template"}
        style={{ width: "50%" }}
        children={
          <>
            <Grid container item xs={8} padding={"30px"} gap={2}>
              <Grid container gap={2} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={12}>
                  <CInput
                    placeholder="Template name"
                    value={templateName}
                    readOnly={disableNameInput}
                    onChange={(val) => {
                      setTemplateName(val);
                      setTemplateNameErrorText("");
                    }}
                    errorMsg={templateNameErrorText}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CInput
                    placeholder=" SMS description"
                    multiline
                    rows={8}
                    value={smsDesc}
                    onChange={(val) => {
                      setSmsDesc(val);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CInput
                    placeholder="Whatsapp description"
                    multiline
                    rows={8}
                    value={whatsappDesc}
                    onChange={(val) => {
                      setWhatsappDesc(val);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent={"space-evenly"}
                  gap={2}
                  wrap="nowrap"
                >
                  <Grid item xs={6}>
                    <CButton
                      onClick={() =>
                        isEdit ? validation("update") : validation("create")
                      }
                      fullWidth
                      variant="contained"
                      disabled={disableBtn}
                      loading={btnLoad}
                    >
                      {!isEdit ? "Create" : "Update"}
                    </CButton>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        handleClose();
                        allErrorFalse();
                      }}
                      disabled={disableBtn}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setDeleteId("");
          setConfirm(false);
        }}
        title={"Delete SMS template"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to delete this template ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                      setDeleteId("");
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => deleteTemplate()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={viewMoreModal}
        onClose={() => {
          setViewMoreModal(false);
        }}
        title={"Read more"}
        style={{ width: "50%" }}
        children={
          <div style={{ padding: "20px" }}>
            <div dangerouslySetInnerHTML={{ __html: showData }} />
          </div>
        }
      />
    </Grid>
  );
};

export default SMSTemplate;
